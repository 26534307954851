import utils from '../utils'
import apiClient from './AxiosHttp'

const api = '/api/v1'

const getCategories = () => {
    return apiClient.get(`${api}/houses/categories/`)
}

const getCategory = (slug) => {
    return apiClient.get(`${api}/houses/category/${slug}/`)
}

const createCategory = (details) => {
    return apiClient.post(
        `${api}/houses/categories/`, 
        details, 
        {headers:utils.multipartHeader}
    )
}

const updateCategory = (slug, details) => {
    return apiClient.patch(
        `${api}/houses/category/${slug}/`, 
        details,
        {headers:utils.multipartHeader}
    )
}

const deleteCategory = (slug) => {
    return apiClient.delete(`${api}/houses/category/${slug}/`)
}

const getHouses = (params) => {
    return apiClient.get(`${api}/houses/${params}`)
}

const getHouse = (slug) => {
    return apiClient.get(`${api}/houses/house/${slug}/`)
}

const adminGetSpecificHouse = (slug) => {
    return apiClient.get(`${api}/houses/house/${slug}/?is_admin=true`)
}

const createHouse = (details) => {
    return apiClient.post(
        `${api}/houses/create/`,
        details,
        {headers:utils.multipartHeader}
    )
}

const updateHouse = (details, slug) => {
    return apiClient.patch(
        `${api}/houses/house/${slug}/`,
        details,
        {headers:utils.multipartHeader}
    )
}

const deleteHouse = (slug) => {
    return apiClient.delete(`${api}/houses/house/${slug}/`)
}

const getHouseMedia = (slug) => {
    return apiClient.get(`${api}/houses/media/${slug}/`)
}

const getComments = (slug) => {
    return apiClient.get(`${api}/houses/comments/${slug}`)
}

const getComment = () => {
    return apiClient.get(`${api}/houses/`)
}

const createComment = (details) => {
    return apiClient.post(`${api}/houses/comment/`, details)
}

const deleteComment = () => {
    return apiClient.delete()
}

const createHouseMedia = (details) => {
    return apiClient.post(
        `${api}/houses/create/media/`,
        details,
        {headers:utils.multipartHeader}
    )
}

const updateHouseMedia = (id, details) => {
    return apiClient.patch(
        `${api}/houses/update/media/${id}/`,
        details,
        {headers:utils.multipartHeader}
    )
}

const deleteHouseMedia = (id) => {
    return apiClient.delete(`${api}/houses/delete/media/${id}/`)
}

const adminGetHousesChartData = () => {
    return apiClient.get(`${api}/admin/charts/houses/`)
}

const houseServices = {
    getCategories,
    getCategory,
    getHouses,
    getHouse,
    getComments,
    getComment,
    createCategory,
    createHouse,
    createComment,
    updateCategory,
    deleteCategory,
    updateHouse,
    deleteHouse,
    deleteComment,
    getHouseMedia,
    createHouseMedia,
    updateHouseMedia,
    deleteHouseMedia,
    adminGetHousesChartData,
    adminGetSpecificHouse,
}

export default houseServices
import React, {useEffect, useRef, useState} from 'react'
import toast from 'react-hot-toast'
import Loading from '../feedback/Loading'
import FormError from '../feedback/FormError'
import authServices from '../../services/UsersService'

function UserCreateModal(props) {
    const [passwordType, setPasswordType] = useState("password")
    const [passMatch, setPassMatch] = useState(true)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const emailRef = useRef()
    const pass1Ref = useRef()
    const pass2Ref = useRef()

    useEffect(() => {}, [passwordType])
    useEffect(() => {}, [error])

    function handleSubmit(e){
        const closeBtn = document.getElementById("user-create-modal-close-btn")
        setLoading(true)
        setError()
        e.preventDefault()
        var pass1 = pass1Ref.current.value
        var pass2 = pass2Ref.current.value
        
        if(pass1 !== pass2){
            setPassMatch(false)
            return 
        } else{
            setPassMatch(true)
        }

        if (pass1.length < 8) {
            toast.error("Password length should be above 8 characters")
            return
        }

        const formData = new FormData()
        formData.append("email", emailRef.current.value)
        formData.append("password1", pass1)
        formData.append("password2", pass1)
        authServices.adminCreateUser(formData).then(
            res => {
                setLoading(false)
                closeBtn.click()
                toast.success("Added User successfully")
                props.getUsers()
            }, err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }
    function togglePassword(){
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
    }
    return (
        <div>
            <label htmlFor="user-create-modal" id='user-create-modal-open-btn' className="hide__element"></label>
            <input type="checkbox" id="user-create-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    <label htmlFor="user-create-modal" id='user-create-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3 text-gray-700  mb-5'>Create User</h2>
                    <form onSubmit={handleSubmit}>
                        {error && (
                            <FormError error={error}/>
                        )}
                        <div className="form-control mb-3">
                            <label className="label">
                                <span className="label-text">Email</span>
                            </label>
                            <input type="email" required ref={emailRef} name="email" className="input input-bordered w-full input-success " />
                        </div>

                        <div className="form-control mb-3">
                            <label className="label">
                                <span className="label-text">Password</span>
                            </label>
                            <input type={passwordType} required ref={pass1Ref} name="password" className="input input-bordered w-full input-success" />
                        </div>
                        <div className="form-control mb-3">
                            <label className="label">
                                <span className="label-text">Confirm Password</span>
                            </label>
                            <input type={passwordType} name="password2" required ref={pass2Ref} className="input input-bordered w-full input-success" />
                            { passMatch === false && (
                                <div className="label">
                                    <span className="label-text text-secondary">Passwords do not match</span>
                                </div>
                            )}
                        </div>
                        <div>
                            <label className="label">
                                    <span className="label-text">Show Password</span>
                            </label>
                            <input 
                                type="checkbox" 
                                className="checkbox"
                                onClick={togglePassword} />
                        </div>
                        <div className='flex justify-center mt-5'>
                            {loading ? (
                                <Loading/>
                            ):(
                                <button type="submit" className='btn btn-secondary'>Submit</button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UserCreateModal
import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast';
import FormError from '../feedback/FormError'
import Loading from '../feedback/Loading'
import {HiTrash} from 'react-icons/hi'
import paymentServices from '../../services/PaymentsService'

function PaymentModeUpdateModal(props) {
    const closeBtn = document.getElementById("payment-mode-update-modal-close-btn")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const paymentModeUpdate =  props.paymentModeUpdate 
    const nameRef = useRef()
    const [show, setShow] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)

    useEffect(() => {
        if (paymentModeUpdate) {
            nameRef.current.value = paymentModeUpdate?.name
        }
    }, [paymentModeUpdate])
    useEffect(() => {}, [show])
    useEffect(() => {}, [deleteLoading])

    function submitHandler(e){
        e.preventDefault()
        setLoading(true)
        setError()
        const formData = new FormData()
        formData.append("name", nameRef.current.value)
        paymentServices.adminUpdatePaymentMode(paymentModeUpdate?.id, formData).then(
            res => {
                setLoading(false)
                closeBtn.click()
                toast.success("Payment Mode updated successfully")
                props.resetOrders()
            },
            err => {
                setLoading(false)
                toast.error("Something went wrong")
            }
        )
    }
    function deletePaymentMode(mode){
        setDeleteLoading(true)
        paymentServices.adminDeletePaymentMode(mode?.id).then(
            res => {
                setDeleteLoading(false)
                closeBtn.click()
                toast.success("Payment Mode updated successfully")
                props.resetOrders()
            }, 
            err => {
                setDeleteLoading(false)
                toast.error("Something went wrong")
            }
        )
    }
    return (
        <div>
            <label htmlFor="payment-mode-update-modal" id='payment-mode-update-modal-open-btn' className="hide__element"></label>
            <input type="checkbox" id="payment-mode-update-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    <label htmlFor="payment-mode-update-modal" id='payment-mode-update-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3  mb-5'>Update / Delete : {paymentModeUpdate?.name}</h2>
                    <form onSubmit={submitHandler}>
                        {error && (
                            <FormError error={error}/>
                        )}
                        <div className="form-control mb-5">
                            <label className="label">
                                <span className="label-text">Name</span>
                            </label>
                            <input required ref={nameRef} name="name" className="input input-bordered w-full input-success text-gray-600" />
                        </div>
                        <div className="flex justify-center mb-5">
                            {loading ? (
                                <Loading/>
                            ):(
                                <button className='btn btn-secondary'>Submit</button>
                            )}
                        </div>
                    </form>
                    <div className="alert alert-warning rounded-md mb-5">
                        <div className="w-full">
                            <div className="flex space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                                <span>Warning: Deleting is irreversable!</span>
                            </div>
                            {show ? (
                                <div className="mt-4">
                                    {deleteLoading ? (
                                        <Loading/>
                                    ):(
                                        <div className="">
                                            <button 
                                                onClick={() => deletePaymentMode(paymentModeUpdate)}
                                                className='btn btn-error'><HiTrash/> Delete Parmanently</button>
                                        </div>
                                    )}
                                </div>
                            ):(
                                <div className="mt-4">
                                    <button 
                                        onClick={() => setShow(!show)}
                                        className='btn btn-neutral'><HiTrash/>  Delete {paymentModeUpdate?.name}</button>
                                </div>
                            )}
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentModeUpdateModal
import React, { useEffect } from 'react'
import moment from 'moment'
import utils from '../../utils'

function PaymentItem(props) {
    const payment = props.payment

    useEffect(() => {}, [payment])
    return (
        <div className='mb-2 bg-base-200 rounded-md p-3 hover:shadow-lg border hover:bg-base-300 text-sm'>
            <div className="flex flex-col md:flex-row md:justify-between gap-3">
                <div className="flex space-x-2">
                    <div>
                        <p>Amount: <span className='font-bold text-gray-700'>{utils.toCommas(payment?.amount)}  KSHS.</span></p>
                        <p>Payment Method: <span className='font-bold text-gray-700'>{payment?.base_rate?.payment_mode?.name}</span></p>
                        <p>Target: <span className='font-bold text-gray-700'>{payment?.payment_target}</span></p>
                        <p>Status: <span className='font-bold text-gray-700'>{payment?.payment_status}</span></p>
                        <p className=''>Created at: <span className='font-bold text-gray-700'>{moment(payment?.creation_time).format("MMMM Do YYYY")}</span></p>
                    </div>
                </div>
                {payment?.cart && (
                    <div className='justify-self-end'>
                        {payment?.payment_status.toLowerCase() === "paid" && (
                            <button
                                onClick={() => props.openPaymentItemsModal(payment)}
                                className='btn btn-neutral'>View Items</button>
                        )}
                    </div>
                )} 
                {payment?.payment_status !== "None" && (
                    <div className='justify-self-end'>
                        <p>Target: {payment?.payment_target}</p>
                        <p>Type: {payment?.base_rate?.subscription_type}</p>
                        <p>Expires:</p>
                        {payment?.base_rate?.subscription_type === "Premium" ? (
                            <div>
                                {moment(payment?.creation_time).add(1, 'y').format("MMMM Do YYYY")}
                            </div>
                        ): (
                            <div>
                                {moment(payment?.creation_time).add(1, 'M').format("MMMM Do YYYY")}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

export default PaymentItem
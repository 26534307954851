/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useState} from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import ProfileSideBar from '../../components/auth/ProfileSideBar'
import ProfileDetailsTab from '../../components/auth/ProfileDetailsTab'
import UpdateProfileTab from '../../components/auth/UpdateProfileTab'
import ChangePasswordTab from '../../components/auth/ChangePasswordTab'
import UserContext from '../../context/UserContext'
import authServices from '../../services/UsersService'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'

function ProfilePage(props) {
    const [activeTab, setActiveTab] = useState(0)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const userCtx = useContext(UserContext)
    const user = userCtx.user
    let tabs = [
        "Details",
        "Update",
        "Password"
    ]

    useEffect(() => {
        if (user === null) {
            setLoading(true)
            authServices.getUser().then(
                res => {
                    setLoading(false)
                    userCtx.setUser(res.data)
                }, 
                err => {
                    setError(err)
                    setLoading(false)
                }   
            )
            authServices.getUserSubscrptionStatus().then(
                res => {
                    const data = res.data
                    userCtx.setSubscrptionType(data?.subscription_status)
                },
                err => {
                    console.log(err);
                }
            )
        }
    }, [user, userCtx])
    useEffect(() => {}, [activeTab])
    useEffect(() => {}, [loading])
    

    function changeActiveTab(val){
        setActiveTab(val)
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Profile - {utils.siteName}</title>
            </Helmet>
            <div className='px-2 md:px-4 lg:px-12'>
                <div className="md:flex md:gap-4 lg:gap-6">
                    <ProfileSideBar active="profile"/>
                    <div className="w-100 md:w-8/12 lg:w-3/4 p-2">
                        <h1 className="text-center text-3xl mb-6">Profile</h1>
                        <div className="flex justify-center mb-6">
                            <div className="tabs">
                                {tabs.map((tab, idx) => (
                                    <a 
                                        key={idx}
                                        className={activeTab === idx ? 'tab tab-bordered tab-active' : 'tab tab-bordered'}
                                        onClick={() => changeActiveTab(idx)}
                                    >{tab}</a>
                                ))}
                            </div>
                        </div>
                        {loading ? (
                            <div className="py-9">
                                <Loading/>
                            </div>
                        ):(
                            <div>
                                {error ? (
                                    <div className="py-9">
                                        <FetchError error={error}/>
                                    </div>
                                ):(
                                    <>
                                        <div className={activeTab !== 0 ? 'hide__element' : ''}>
                                            <ProfileDetailsTab/>
                                        </div>
                                        <div className={activeTab !== 1 ? 'hide__element' : ''}>
                                            <UpdateProfileTab/>
                                        </div>
                                        <div className={activeTab !== 2 ? 'hide__element' : ''}>
                                            <ChangePasswordTab/>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </HelmetProvider>
    )
}

export default ProfilePage
import React from 'react'
import {BsBagCheckFill, BsFillCheckCircleFill} from 'react-icons/bs'

function PaymentComplete(props) {
    return (
        <div>
            <div className="mb-9 flex justify-center">
                <h2 className="text-2xl flex items-center"><BsBagCheckFill className='h-9 w-9 text-primary mr-3'/> Checkout Complete</h2>
            </div>
            <div className="flex justify-center">
                <div className="w-11/12 md:w-8/12 lg:w-7/12">
                    <div className="alert alert-success rounded-md flex flex-col">
                        <BsFillCheckCircleFill className='h-8 w-8'/>
                        <span className='text-center mb-1'>
                            Your purchase has been confirmed. Thank you for shopping with us!
                        </span>
                        <p className="text-center mb-1BsFillCheckCircleFill">
                            The items you have requested for are being compressed and will be available for download as a zip file. 
                            The extra items will also be available on your <a className='font-bold ml-3' href="/auth/payments">Payments Page</a>.
                        </p>
                        <p className="text-center mb-2">
                            For any enquieries contact as at: 
                            <a href="mailto:info@jahtechconstructs.com" className='font-bold ml-3'>info@jahtechconstructs.com</a>
                        </p>
                        <p className="text-center mb-6 font-bold"><a href="/houses">Shop Some More</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentComplete
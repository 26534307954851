import React from 'react'
import utils from '../../utils'
import { Link } from 'react-router-dom'

function CategoryItem(props) {
    const category = props.category
    return (
        <div className='card-compact rounded-md border card hover:shadow-lg bg-base-200 hover:bg-base-300'>
            <Link to={`/houses?category__slug=${category?.slug}`}>
                <img className='rounded-t-md h-36 w-full' src={utils.baseUrl + category?.image} alt={category?.name} />
            </Link>
            <div className="card-body">
                <Link to={`/houses?category__slug=${category?.slug}`}>
                    <p className="text-secondary-focused font-bold">{category?.name}</p>
                </Link>
            </div>
        </div>
    )
}

export default CategoryItem
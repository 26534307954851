import React from 'react'
import {FaXmark, FaCheck} from 'react-icons/fa6'
import moment from 'moment'
import utils from '../../utils'

function UsersTableRow(props) {
    const user = props.user
    return (
        <tr>
            <td>
                <div className="avatar placeholder">
                    <div className="rounded-md">
                        {utils.hasProfileImage(user) ? (
                            <div className="h-10 rounded-md avatar placeholder">
                                <img src={user?.image} alt='profile'/>
                            </div>
                        ):(
                            <div className="avatar placeholder">
                                <div className="h-10 bg-neutral-focus text-neutral-content">
                                    <span className="text-2xl">{utils.makeProfileLetter(user)}</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </td>
            <td className='font-bold'>{user?.email}</td>
            <td>{user?.is_admin ? (
                <div>
                    <FaCheck/>
                </div>
            ):(
                <div>
                    <FaXmark/>
                </div>
            )}</td>
            <td>{user?.is_provider ? (
                <div>
                    <FaCheck/>
                </div>
            ):(
                <div>
                    <FaXmark/>
                </div>
            )}</td>
            <td>{moment(user?.last_updated_time).format("MMMM Do YYYY")}</td>
            <td className='font-bold'>{moment(user?.creation_time).format("MMMM Do YYYY")}</td>
            <td>
                <button 
                    onClick={() => props.modifySelectedUser(user)}
                    className='btn btn-secondary btn-xs'>Modify</button>
            </td>
        </tr>
    )
}

export default UsersTableRow
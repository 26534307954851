import React, { useEffect } from 'react'
import utils from '../../utils'
import moment from 'moment'

function OrdersTableRow(props) {
    const order = props.order

    useEffect(() => {}, [order])
    return (
        <tr>
            <td className='font-bold'>{order?.owner?.email}</td>
            <td>{order?.payment_status}</td>
            <td className='font-bold'>{utils.toCommas(order?.amount)}</td>
            <td>{order?.payment_target}</td>
            <td className='font-bold'>{order?.base_rate?.payment_mode?.name}</td>
            <td className='font-bold'>{order?.base_rate?.subscription_type}</td>
            <td>{moment(order?.creation_time).format("MMMM Do YYYY")}</td>
            {/* <td>
                <button className='btn btn-neutral btn-sm'>View</button>
            </td> */}
        </tr>
    )
}

export default OrdersTableRow
import React, { useEffect, useState } from 'react'
import {FaXmark, FaCheck} from 'react-icons/fa6'
import {BsFillInfoCircleFill} from 'react-icons/bs'
import moment from 'moment'
import utils from '../../utils'
import Loading from '../feedback/Loading'
import {HiTrash} from 'react-icons/hi'
import authServices from '../../services/UsersService'
import toast from 'react-hot-toast'

function UserUpdateModal(props) {
    const modifyUser = props.modifyUser
    const [show, setShow] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [deleteError, setDeleteError] = useState()
    const [updateLoading, setUpdateLoading] = useState(false)

    useEffect(() => {
        setUpdateLoading(false)
        setDeleteError()
    }, [modifyUser])
    useEffect(() => {}, [show])
    useEffect(() => {}, [deleteLoading])
    useEffect(() => {}, [deleteError])

    function deleteUserHandler(id){
        setDeleteLoading(true)
        const closeBtn = document.getElementById("user-update-modal-close-btn")
        authServices.adminDeleteUser(id).then(
            res => {
                setDeleteLoading(false)
                closeBtn.click()
                toast.success("User deleted successfully")
                props.updateUsers()
            }, 
            err => {
                setDeleteError(err)
                setDeleteLoading(false)
                toast.error("Something went wrong")
            }
        )
    }
    function updateUser(){
        setUpdateLoading(true)
        const closeBtn = document.getElementById("user-update-modal-close-btn")
        const data = {
            "is_provider": !modifyUser?.is_provider
        }
        authServices.adminUpdateUser(modifyUser?.id, data).then(
            res => {
                setUpdateLoading(false)
                closeBtn.click()
                toast.success("User updated successfully")
                props.updateUsers()
            }, err => {
                setUpdateLoading(false)
                toast.error("Something went wrong")
            }
        )
    }
    return (
        <div>
            <label htmlFor="user-update-modal" id='user-update-modal-open-btn' className="hide__element"></label>
            <input type="checkbox" id="user-update-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    <label htmlFor="user-update-modal" id='user-update-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3 text-gray-700  mb-5'>Update / Delete: {modifyUser?.email}</h2>
                    <div className='flex justify-center'>
                        <div className="">
                            {utils.hasProfileImage(modifyUser) ? (
                                <div className="h-16 rounded-md avatar placeholder">
                                    <img src={modifyUser?.image} alt='profile'/>
                                </div>
                            ):(
                                <div className="avatar placeholder">
                                    <div className="h-16 rounded-md bg-neutral-focus text-neutral-content">
                                        <span className="text-2xl">{utils.makeProfileLetter(modifyUser)}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <p>Email: <span className='font-gray-700'>{modifyUser?.email}</span></p>
                    <p className='flex items-center space-x-2'>Is Admin: 
                        <span className='font-gray-700 '>
                            {modifyUser?.is_admin ? (
                                <FaCheck className='font-2xl'/>
                            ):(
                                <FaXmark className='font-2xl'/>
                            )} 
                        </span>
                    </p>
                    <p className='flex items-center space-x-2'>Is Provider: 
                        <span className='font-gray-700'>
                            {modifyUser?.is_provider ? (
                                <FaCheck className='font-2xl'/>
                            ):(
                                <FaXmark className='font-2xl'/>
                            )} 
                        </span>
                    </p>
                    <p>Joined: 
                        <span className='font-gray-700'>
                            {moment(modifyUser?.creation_time).format("MMMM Do YYYY")}
                        </span>
                    </p>
                    <div className="mt-4 mb-4">
                        <div className="alert alert-info rounded-md">
                            <div className="w-full">
                                <div className="flex space-x-2 items-center">
                                    <BsFillInfoCircleFill className='text-1xl'/>
                                    <span>Modify User, Change provider status!</span>
                                </div>
                                {updateLoading ? (<Loading/>):(
                                    <div className="flex mt-3">
                                        <div 
                                            onClick={() => updateUser()}
                                            className="btn btn-neutral">{modifyUser?.is_provider ? 'Remove':'Make'} Provider</div>
                                    </div>
                                )}                                
                            </div>
                        </div>
                    </div>
                    <div className="alert alert-warning rounded-md mb-5">
                        <div className="w-full">
                            <div className="flex space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                                <span>Warning: Deleting is irreversable!</span>
                            </div>
                            {show ? (
                                <div className="mt-4">
                                    {deleteLoading ? (
                                        <Loading/>
                                    ):(
                                        <div className="">
                                            <button 
                                                onClick={() => deleteUserHandler(modifyUser?.id)}
                                                className='btn btn-error'><HiTrash/> Delete Parmanently</button>
                                        </div>
                                    )}
                                </div>
                            ):(
                                <div className="mt-4">
                                    <button 
                                        onClick={() => setShow(!show)}
                                        className='btn btn-neutral'><HiTrash/>  Delete {modifyUser?.email}</button>
                                </div>
                            )}                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserUpdateModal
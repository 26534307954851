import { useContext } from "react"
import UserContext from "./context/UserContext"
import { Navigate, useLocation } from "react-router-dom"


const ProtectedRoutes = ({children}) => {
    const userCtx = useContext(UserContext)
    const isAuth = userCtx.isAuth
    const location = useLocation()

    if (!isAuth) {
        return (
            <Navigate to='/auth/login' state={{from: location}}/>
        )
    }
    return children
}

export default ProtectedRoutes
import React, { useEffect, useState } from 'react'
import Loading from '../feedback/Loading'
import FetchError from '../feedback/FetchError'
import paymentServices from '../../services/PaymentsService'
import PaymentCartItem from './PaymentCartItem'

function PaymentCartItemsModal(props) {
    const [cartItems, setCartItems] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const selectedPayment = props.selectedPayment

    useEffect(() => {
        if (selectedPayment?.id) {
            paymentServices.getPaymentCartItems(selectedPayment?.id).then(
                res => {
                    setCartItems(res.data)
                    setLoading(false)
                },
                err => {
                    setError(err)
                    setLoading(false)
                }
            )
        }
    }, [selectedPayment])
    useEffect(() => {}, [cartItems])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    return (
        <div>
            <label htmlFor="payment-items-modal" id='payment-items-modal-open-btn' className="hide__element">Payment Cart Items</label>
            <input type="checkbox" id="payment-items-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    <label htmlFor="payment-items-modal" id='payment-items-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3 text-gray-700  mb-5'>Payment Cart Items</h2>
                    {loading ? (
                        <Loading/>
                    ):(
                        <div>
                            {error && (
                                <FetchError error={error}/>
                            )}
                            {cartItems.map((item, idx) => (
                                <PaymentCartItem
                                    key={idx}
                                    idx={idx}
                                    item={item}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PaymentCartItemsModal
import React, { useEffect, useState } from 'react'
import authServices from '../../services/UsersService'
import Loading from '../feedback/Loading'
import FetchError from '../feedback/FetchError'
import QuotesTable from './QuotesTable'
import QuotesSummary from './QuotesSummary'

function AdminUserQuotesTab(props) {
    const [quotes, setQuotes] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const activeTab = props.activeTab

    const getUserQuotes = (params) => {
        setLoading(true)
        authServices.adminGetUserQuotes(params ? `?${params}` : '').then(
            res => {
                setQuotes(res.data)
                setLoading(false)
            },
            err => {
                setError(err)
                setLoading(false)
            }
        )
    }
    useEffect(() => {}, [quotes])
    useEffect(() => {
        if (activeTab === 3) {
            getUserQuotes('')
        }
    }, [activeTab])
    return (
        <div>
            {loading ? (
                <Loading/>
            ):(
                <div>
                    {error ? (<FetchError error={error}/>) : (
                        <div>
                            <QuotesSummary
                                quotes={quotes}
                            />
                            <QuotesTable
                                quotes={quotes}
                                getUserQuotes={getUserQuotes}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default AdminUserQuotesTab
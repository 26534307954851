import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../utils'
import UnderConstruction from '../components/feedback/UnderConstruction'
import Contacts from '../components/home/Contacts'

function AboutPage() {
    return (
        <HelmetProvider>
            <Helmet>
                <title>About Us - {utils.siteName}</title>
            </Helmet>
            <div>
                <h1 className="text-3xl text-center mb-7">About Us</h1>
                <div className="mb-12">
                    <Contacts/>
                </div>
                <div className="container mx-auto">
                    <UnderConstruction/>
                </div>
            </div>
        </HelmetProvider>
    )
}

export default AboutPage
import React, { useEffect } from 'react'
import moment from 'moment'
import utils from '../../utils';

function CategoryTableRow(props) {
    const category = props.category
    useEffect(() => {}, [category])
    return (
        <tr>
            <td>
                <div className="avatar placeholder">
                    <div className="h-10 mask rounded">
                        <img src={utils.baseUrl + category?.image} alt={category?.name} />
                    </div>
                </div>
            </td>
            <td className='font-bold'>{category?.name}</td>
            <td>{moment(category?.last_updated_time).format("MMMM Do YYYY")}</td>
            <td className='font-bold'>{moment(category?.creation_time).format("MMMM Do YYYY")}</td>
            <td>
                <button
                onClick={() => props.openModal(category)}
                    className='btn btn-xs btn-secondary'
                >Modify</button>
            </td>
        </tr>   
    )
}

export default CategoryTableRow
import React, { useEffect, useState } from 'react'
import {BsFillPeopleFill} from 'react-icons/bs'
import UsersTableRow from './UsersTableRow'
import UserUpdateModal from './UserUpdateModal'
import Pagination from '../nav/Pagination'

function UsersTables(props) {
    const users = props.users
    const [modifyUser, setModifyUser] = useState()

    useEffect(() => {}, [modifyUser])

    function modifySelectedUser(user){
        setModifyUser(user)
        const openBtn = document.getElementById("user-update-modal-open-btn")
        openBtn.click()
    }
    function changePageParam(page){
        props.filterPage(page);
    }
    function updateUsers(){
        props.getUsers('')
    }
    return (
        <div className='p-3 border rounded-md mt-5 bg-base-300'>
            <UserUpdateModal
                modifyUser={modifyUser}
                updateUsers={updateUsers}
            />
            <div className="flex space-x-3 items-center font-bold">
                <BsFillPeopleFill className='h-5 w-5'/>
                <span>Users Table</span>
            </div>
            <div className="mt-2">
                <div className="overflow-x-auto">
                    <table className="table table-sm table-zebra w-full">
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Email</th>
                                <th>Admin</th>
                                <th>Provider</th>
                                <th>Updated</th>
                                <th>Joined</th>
                                <th>Update</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users?.results?.map((user, idx) => (
                                <UsersTableRow
                                    key={idx}
                                    user={user}
                                    modifySelectedUser={modifySelectedUser}
                                />
                            ))}
                        </tbody>
                    </table>
                    {users && (
                        <div>
                            {((users?.next !== null) || (users?.previous !== null)) && (
                                <Pagination
                                    next={users?.next}
                                    previous={users?.previous}
                                    changePageParam={changePageParam}
                                    isAdmin={true}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default UsersTables
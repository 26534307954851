import React, {useRef, useState} from 'react'
import Loading from '../feedback/Loading'
import FormError from '../feedback/FormError'
import { Link } from 'react-router-dom'


function LoginForm(props) {
    const [passwordType, setPasswordType] = useState("password")
    const emailRef = useRef()
    const passwordRef = useRef()
    const loading = props.loading
    const error = props.error

    function handleSubmit(e){
        e.preventDefault()
        props.setError()
        const formData = new FormData()
        formData.append("email", emailRef.current.value)
        formData.append("password", passwordRef.current.value)
        props.submitHandler(formData)
    }
    function togglePassword(){
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
    }
    return (
        <div className='flex justify-center mt-12'>
            <div className='w-3/4 md:w-2/4 lg:w-1/3'>
                <form onSubmit={handleSubmit} className='rounded-md border p-5 md:p-9 hover:shadow-lg bg-base-300'>
                    <div className="h1 text-center text-3xl mb-9">Login</div>
                    {error && (
                        <FormError error={error}/>
                    )}
                    <div className="form-control mb-3">
                        <label className="label">
                            <span className="label-text">Email:*</span>
                        </label>
                        <input type="email" required ref={emailRef} name="email" className="input input-bordered w-full input-success" />
                    </div>

                    <div className="form-control mb-3">
                        <label className="label">
                            <span className="label-text">Password:*</span>
                        </label>
                        <input type={passwordType} required ref={passwordRef} name="password" className="input input-bordered w-full input-success " />
                    </div>

                    <div>
                        <label className="label">
                                <span className="label-text">Show Password</span>
                        </label>
                        <input 
                            type="checkbox" 
                            className="checkbox"
                            onClick={togglePassword} />
                    </div>
                    <div className='flex justify-center mt-5'>
                        {loading ? (
                            <Loading/>
                        ):(
                            <button type="submit" className='btn btn-secondary'>Submit</button>
                        )}
                        
                    </div>
                    <div className="mt-7 flex justify-between">
                        <Link to="/auth/register">Register</Link>
                        <Link to="/auth/forgot/password">Forgot password?</Link>
                    </div> 
                </form>
            </div>
        </div>
    )
}

export default LoginForm
import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import ResetPasswordForm from '../../components/auth/ResetPasswordForm'

function ForgotPasswordPage(props) {
    return (
        <HelmetProvider>
            <Helmet>
                <title>Forgot Password - {utils.siteName}</title>
            </Helmet>
            <ResetPasswordForm/>
        </HelmetProvider>
    )
}

export default ForgotPasswordPage
import React, { useEffect } from 'react'
import moment from 'moment'

function QuoteTableRow(props) {
    const quote = props.quote

    useEffect(() => {}, [quote])
    return (
        <tr>
            <td className='font-bold'>{quote?.name}</td>
            <td>{quote?.email}</td>
            <td className='font-bold'>{moment(quote?.creation_time).format("MMMM Do YYYY")}</td>
            <td>
                <button 
                    onClick={() => props.openModal(quote)}
                    className='btn btn-secondary btn-xs'>View</button>
            </td>
            <td>{quote?.description}</td>
        </tr>
    )
}

export default QuoteTableRow
import React from 'react'
import { Link } from 'react-router-dom'

function AuthBreadCrumbs(props) {
    const currentPage = props.currentPage
    const action = props.action
    return (
        <div className='mb-3'>
            <div className="text-sm breadcrumbs">
                <ul>
                    <li><Link to='/'>Home</Link></li> 
                    <li><Link to='/auth/profile'>Profile</Link></li> 
                    {currentPage === 0 && (
                        <li>Admin</li>
                    )}
                    {currentPage === 1 && (
                        <li>Payments</li>
                    )}
                    {currentPage === 2 && (
                        <>
                            <li><Link to='/auth/admin'>Admin</Link></li>
                            <li>{action === 1 ? 'Add' : 'Update'} House</li>
                        </>
                    )}   
                    {currentPage === 3 && (
                        <>
                            <li><Link to='/auth/admin'>Admin</Link></li>
                            <li>{action === 1 ? 'Add' : 'Update'} Blog Post</li>
                        </>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default AuthBreadCrumbs
import React, { useEffect, useState } from 'react'
import {FaMoneyBillTransfer} from 'react-icons/fa6'
import BaseRatesTableRow from './BaseRatesTableRow'
import BaseRateUpdateModal from './BaseRateUpdateModal'

function BaseRatesTable(props) {
    const [baseRateUpdate, setBaseRateUpdate] = useState()
    const baseRates = props.baseRates

    useEffect(() => {
        setBaseRateUpdate()
    }, [baseRates])
    useEffect(() => {}, [baseRateUpdate])

    function modifyBaseRate(baseRate){
        setBaseRateUpdate(baseRate)
        const openBtn = document.getElementById("base-rate-update-modal-open-btn")
        openBtn.click()
    }
    return (
        <div className='mt-5'>
            <div className="collapse collapse-arrow border rounded-md bg-base-300">
                <input type="checkbox" />   
                <div className="flex items-center space-x-3 collapse-title text-1xl font-medium">
                    <FaMoneyBillTransfer className='h-5 w-5'/>
                    <span>Base Rates Table</span>
                </div>
                <div className="collapse-content">
                    <BaseRateUpdateModal
                        baseRateUpdate={baseRateUpdate}
                        resetOrders={props.resetOrders}
                    />
                    <div className="mt-2">
                        <div className="overflow-x-auto">
                            <table className="table table-zebra table-sm">
                                <thead>
                                    <tr>
                                        <th>Payment Mode</th>
                                        <th>Amount (KSHS.)</th>
                                        <th>Subcription Type</th>
                                        <th>Updated</th>
                                        <th>Created</th>
                                        <th>Update</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {baseRates.map((baseRate, idx) => (
                                        <BaseRatesTableRow
                                            key={idx}
                                            baseRate={baseRate}
                                            modifyBaseRate={modifyBaseRate}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BaseRatesTable
import React, { useContext, useEffect } from 'react'
import {BiSolidUserPlus} from 'react-icons/bi'
import SessionContext from '../../context/SessionContex'
import UserCreateModal from './UserCreateModal'

function UsersSummary(props) {
    const users = props.users
    const sessionCtx = useContext(SessionContext)
    const usersThisMonth = sessionCtx.usersThisMonth

    useEffect(() => {}, [users])
    useEffect(() => {}, [usersThisMonth])

    function openModal(){
        const openBtn = document.getElementById("user-create-modal-open-btn")
        openBtn.click()
    }
    return (
        <div className='mb-4'>
            <UserCreateModal
                getUsers={props.getUsers}
            />
            <h2 className="text-1xl mb-2">Users Summary</h2>
            <div className="flex flex-col md:flex-row gap-2">
                <div className="stats rounded-md bg-primary text-primary-content">
                    <div className="stat">
                        <div className="stat-title text-black">Total Users</div>
                        <div className="stat-value">{users.count}</div>
                        <div className="stat-actions">
                            <button 
                                onClick={() => openModal()}
                                className="btn btn-sm">Add User</button>
                        </div>
                    </div>
                </div>
                <div className="stats rounded-md bg-secondary text-primary-content">
                    <div className="stat">
                        <div className="stat-title text-black">New Users This Month</div>
                        <div className="stat-value">{usersThisMonth}</div>
                        <div className="stat-actions">
                            <BiSolidUserPlus className='h-9 w-9'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsersSummary
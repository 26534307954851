import React, { useRef, useState } from 'react'
import authServices from '../../services/UsersService'
import Loading from '../feedback/Loading'
import toast from 'react-hot-toast';
import utils from '../../utils';

function QuoteForm(props) {
    const [loading, setLoading] = useState(false)
    const nameRef = useRef()
    const emailRef = useRef()
    const textRef = useRef()

    function submitHandler(e){
        const closeBtn = document.getElementById("contact-modal-close-btn")
        setLoading(true)
        e.preventDefault()
        const formdata = new FormData()
        formdata.append("email", emailRef.current.value)
        formdata.append("name", nameRef.current.value)
        formdata.append("description", textRef.current.value)
        authServices.createQuote(formdata).then(
            res => {
                emailRef.current.value = null
                nameRef.current.value = null
                textRef.current.value = null
                toast.success("Quote created, please check your email soon for response.")
                closeBtn.click()
                setLoading(false)
            },
            err => {
                toast.error(utils.toasterError)
                setLoading(false)
            }
        )
    }
    return (
        <div>
            <div className="">
                <label htmlFor="contact-modal" id='contact-modal-open-btn' className="hide__element"></label>
                <input type="checkbox" id="contact-modal" className="modal-toggle" />
                <div className="modal">
                    <div className="modal-box relative">
                        <label htmlFor="contact-modal" id='contact-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                        <h2 className='text-2xl text-center mt-3 mb-5'>Get Quote</h2>
                        <form onSubmit={submitHandler}>
                            <div className="form-control mb-3">
                                <label className="label">
                                    <span className="label-text">Full Name</span>
                                </label>
                                <input type="text" required ref={nameRef} name="email" className="input input-bordered w-full input-success" />
                            </div>
                            <div className="form-control mb-3">
                                <label className="label">
                                    <span className="label-text">Email</span>
                                </label>
                                <input type="email" required ref={emailRef} name="email" className="input input-bordered w-full input-success" />
                            </div>
                            <div  className="form-control mb-5">
                                <label className="label">
                                    <span className="label-text">Description</span>
                                </label>
                                <textarea ref={textRef} className="input input-bordered w-full h-20 input-success" required cols={4} placeholder="Text" />
                            </div>
                            <div className='flex justify-center'>
                                {loading ? (
                                    <Loading/>
                                ):(
                                    <button className='btn btn-primary' type='submit'>Submit</button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuoteForm
import React, { useContext } from 'react'
import {BsFacebook, BsTelephoneFill, BsTwitter} from 'react-icons/bs'
import {IoIosMailOpen} from 'react-icons/io'
import {ImAddressBook} from 'react-icons/im'
import { FaInstagramSquare } from "react-icons/fa";
import utils from '../../utils'
import UserContext from '../../context/UserContext'
import { Link } from 'react-router-dom'

function Footer(props) {
    const userCtx = useContext(UserContext)
    const isAuth = userCtx.isAuth
    return (
        <div className='mt-28'>
            <div className="bg-primary py-5"></div>
            <div className="py-3 bg-neutral">
                <div className="container mx-auto pb-5">
                    <div className='grid md:grid-cols-3 gap-4'>
                        <div>
                            <p className='text-success text-lg md:text-2xl text-center hover:text-info uppercase'>Contact Info</p>
                            <div className='mt-3 md:ml-0 text-center'>
                                <p className="text-base-200 text-lg font-bold mb-2">Telephone</p>
                                <span className='flex text-base-100 hover:text-primary space-x-3 justify-center items-center'>
                                    <div>
                                        <BsTelephoneFill className='h-5 w-5 text-primary'/>
                                    </div>
                                    <div>
                                        <p>+254719405143</p>
                                        <p>+254113129195</p>
                                    </div>
                                </span>
                                <p className="text-base-200 text-lg font-bold mb-2 mt-4">Email</p>
                                <span className='flex text-base-100 hover:text-primary space-x-3 justify-center items-center mb-4'>
                                    <div>
                                        <IoIosMailOpen className='h-6 w-6 text-primary'/>
                                    </div>
                                    <div>
                                        <p>info@jahtechconstruction.co.ke</p>
                                        <p>plans@jahtechconstruction.co.ke</p>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div>
                            <p className='text-success text-lg md:text-2xl text-center hover:text-info uppercase'>Important Links</p>
                            <div className='mt-3 space-y-1 md:ml-0 text-center'>
                                <p><Link to="/about" className="text-base-100 hover:text-primary">About Us</Link></p>
                                <p><Link to="/help" className="text-base-100 hover:text-primary">Help</Link></p>
                                <p><Link to="/cart" className="text-base-100 hover:text-primary">Cart</Link></p>
                                <p><Link to="/houses" className="text-base-100 hover:text-primary">Houses</Link></p>
                                {isAuth ? 
                                    (
                                        <>
                                            <p><Link to="/auth/profile" className="text-base-100 hover:text-primary">Profile</Link></p>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <p><Link href="/auth/login" className="text-base-100 hover:text-primary">Login</Link></p>
                                            <p><Link href="/auth/register" className="text-base-100 hover:text-primary">Register</Link></p>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                        <div>
                            <p className='text-success text-lg md:text-2xl text-center hover:text-info uppercase'>Get Social</p>
                            <div className="space-y-2 mt-3 md:ml-0 text-center">
                                <span className='flex text-base-100 hover:text-primary space-x-3 justify-center'>
                                    <div>
                                        <BsFacebook className='h-5 w-5 text-primary'/>
                                    </div>
                                    <p>{utils.siteName}</p>
                                </span>
                                <span className='flex text-base-100 hover:text-primary space-x-3 justify-center'>
                                    <div>
                                        <BsTwitter className='h-5 w-5 text-primary'/>
                                    </div>
                                    <p>Jahtech_consult</p>
                                </span>
                                <span className='flex text-base-100 hover:text-primary space-x-3 justify-center'>
                                    <div>
                                        <FaInstagramSquare className='h-5 w-5 text-primary'/>
                                    </div>
                                    <p>Jahtech_construction</p>
                                </span>
                                <div className="">
                                    <p className="text-base-200 text-lg font-bold mb-2 mt-4">Address</p>
                                    <span className='flex text-base-100 hover:text-primary space-x-3 justify-center items-center'>
                                        <div>
                                            <ImAddressBook className='h-5 w-5 text-primary'/>
                                        </div>
                                        <div>
                                            <p>56415-00200</p>
                                            <p>Nairobi, KENYA</p>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
import React from 'react'

function Contacts() {
  return (
    <div className="rounded-md text-white border bg-secondary-focus p-3 py-8 hover:shadow-xl mx-1 md:mx-4 lg:mx-7">
        <h2 className="text-center font-bold text-2xl mb-4 ">Get Us On:</h2>
        <p className="text-center">Mobile: 0719405143 / 0113129195</p>
        <p className="text-center">Email: info@jahtechconstruction.co.ke / plans@jahtechconstruction.co.ke</p>
    </div>
  )
}

export default Contacts
import React, { useContext, useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import LoginForm from '../../components/auth/LoginForm'
import UserContext from '../../context/UserContext'
import authServices from '../../services/UsersService'
import { useNavigate } from 'react-router-dom'
import CartContext from '../../context/CartContext'
import cartServices from '../../services/CartService'

function LoginPage(props) {
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const userCtx = useContext(UserContext)
    const cartCtx = useContext(CartContext)
    const navigate = useNavigate()

    useEffect(() => {}, [error])
    useEffect(() => {}, [loading])

    function submitHandler(data){
        console.log(data);
        setLoading(true)
        authServices.login(data).then(
            res => {
                const data = res.data
                userCtx.setAuthToken(data?.key)
                cartServices.checkCart().then(
                    res => {
                        const data = res.data
                        cartCtx.setCart(data?.cart)
                        cartCtx.setItemSlugs(data?.slugs)
                        cartCtx.setNumItems(data?.num_items)
                    },
                    err => {}
                )
                setLoading(false)
                navigate('/auth/profile')
            }, 
            err => {
                setError(err)
                console.log(err)
                setLoading(false)
            }
        )
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Login - {utils.siteName}</title>
            </Helmet>
            <div>
                <LoginForm 
                    setError={setError}
                    loading={loading}
                    submitHandler={submitHandler}
                    error={error}/>
            </div>
        </HelmetProvider>
    )
}

export default LoginPage
import React, { useEffect } from 'react'
import HouseItem from './HouseItem'
import EmptyList from '../feedback/EmptyList'

function HouseList(props) {
    const houses = props.houses?.results
    useEffect(() => {}, [houses])
    return (
        <div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                {houses.map((house, idx) => (
                    <HouseItem
                        key={idx}
                        house={house}
                    />
                ))}
            </div>
            {houses && (
                <div>
                    {houses.length < 1 && (
                        <EmptyList item={"House List"}/>
                    )}
                </div>
            )}
        </div>
    )
}

export default HouseList
import React, { useContext, useEffect, useRef, useState } from 'react'
import UserContext from '../../context/UserContext'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import utils from '../../utils';
import Loading from '../feedback/Loading';
import blogServices from '../../services/BlogService';
import toast from 'react-hot-toast';
import FormError from '../feedback/FormError';

function BlogForm(props) {
    const [loading, setLoading] = useState(false)
    const userCtx = useContext(UserContext)
    const action = props.action
    const user = userCtx.user
    const updateBlog = props.updateBlog
    const titleRef = useRef()
    const [value, setValue] = useState('')
    const [isPublished, setIsPublised] = useState(false)
    const imageRef = useRef()
    const [file, setFile] = useState()
    const [error, setError] = useState()

    useEffect(() => {}, [user])
    useEffect(() => {
        if (action === 2) {
            if (updateBlog) {
                titleRef.current.value = updateBlog?.name
                setValue(updateBlog?.text)
            }
        }
    }, [action, updateBlog])

    function handlePublishedChange(){
        setIsPublised(!isPublished)
    }
    function imageChanged(event){
        setFile(event.target.files[0])
    }
    function handleSubmit(e){
        e.preventDefault()
        setLoading(true)
        setError()
        const formData = new FormData()
        formData.append("name", titleRef.current.value)
        formData.append("text", value)
        formData.append("is_published", isPublished)
        if (file) {
            formData.append("image", file)
        }
        if (action === 1) {
            formData.append("owner", user?.id)
            blogServices.createBlog(formData).then(
                res => {
                    console.log(res.data);
                    setFile()
                    titleRef.current.value = null
                    setValue('')
                    toast.success("Blog added successfully") 
                    setLoading(false) 
                },
                err => {
                    console.log(err);
                    setError(err)
                    setLoading(false)
                    toast.error(utils.toasterError)
                }
            )
        } else{
            blogServices.updateBlog(formData, updateBlog?.slug).then(
                res => {
                    console.log(res.data);
                    toast.success("Blog updated successfully") 
                    setLoading(false)
                },
                err => {
                    console.log(err);
                    setError(err)
                    setLoading(false)
                    toast.error(utils.toasterError)
                }
            )
        }
    }
    return (
        <div>
            <div className="flex justify-center">
                <div className='w-10/12 md:w-10/12 lg:w-9/12'>
                    <form onSubmit={handleSubmit}  className='rounded-md border p-5 md:p-9 hover:shadow-lg mb-5'>
                        <h2 className="text-2xl text-center mb-5">Blog Post</h2>
                        {error && (<FormError error={error}/>)}
                        {action === 2 && (
                            <div className='flex justify-center'>
                                <img className='rounded-md h-44' src={utils.baseUrl + updateBlog?.image} alt={updateBlog?.name} />
                            </div>
                        )}
                        <div className="form-control mb-3">
                            <label className="label">
                                <span className="label-text">Title:</span>
                            </label>
                            <input type="text" required ref={titleRef} name="title" className="input input-bordered w-full input-success" />
                        </div>
                        <div className="mb-3 flex gap-3 items-center">
                            <label className="label">
                                <span className="label-text">Is Published:</span>
                            </label>
                            <input 
                                defaultChecked={action === 1 ? false : updateBlog?.is_published }
                                onChange={(e) => handlePublishedChange(e)}
                                type="checkbox" 
                                className="checkbox"/>
                        </div>
                        {action === 2 && (
                            <div className='mb-3 flex gap-3 text-sm'>
                                <p>Views:</p>
                                <p>{updateBlog?.views}</p>
                            </div>
                        )}
                        <div className="form-control mb-3">
                            <label className="label">
                                <span className="label-text">Image:</span>
                            </label>
                            {action === 1 ? (
                                <input 
                                type="file" 
                                required
                                ref={imageRef} 
                                id='file-input' 
                                onChange={imageChanged}
                                className="file-input text-gray-600 file-input-bordered file-input-success w-full max-w-xs" />
                            ):(
                                <input 
                                type="file" 
                                ref={imageRef} 
                                id='file-input' 
                                onChange={imageChanged}
                                className="file-input text-gray-600 file-input-bordered file-input-success w-full max-w-xs" />
                            )}
                        </div>
                        <div className="form-control mb-3">
                            <label className="label">
                                <span className="label-text">Text:</span>
                            </label>
                            <ReactQuill 
                                modules={utils.inputModules}
                                theme="snow" 
                                value={value} 
                                onChange={setValue} />
                        </div>
                        <div className='flex justify-center mt-5'>
                            {loading ? (<Loading/>):(
                                <button type="submit" className='btn btn-primary'>Submit</button>
                            )}                            
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default BlogForm
import React, { useEffect } from 'react'
import { BiError } from 'react-icons/bi'

function FormError(props) {
    const error = props.error
    useEffect(() => {}, [error])
    return (
        <div className='alert rounded-md alert-error mb-6'>
            <div className="flex space-x-3 items-center">
                <div className="">
                    <BiError className='h-6 w-6'/>
                </div>
                <div className="">
                    { error?.response && (
                        <>
                            {error.response?.data && (
                                <div>
                                    {error.response.data?.non_field_errors && (
                                        <div>
                                            {error.response.data.non_field_errors.map(
                                                (err, idx) => (
                                                    <span key={idx}>{err}</span>
                                                )
                                            )}
                                        </div>
                                    )}
                                    {error.response.data?.email && (
                                        <div>
                                            {error.response.data?.email.map(
                                                (err, idx) => (
                                                    <span key={idx}>{err}</span>
                                                )
                                            )}
                                        </div>
                                    )}
                                    {error.response.data?.message?.payment_mode && (
                                        <div>
                                            {error.response.data?.message?.payment_mode.map(
                                                (err, idx) => (
                                                    <span key={idx}>{err}</span>
                                                )
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            
        </div>
    )
}

export default FormError
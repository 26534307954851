import React, { useEffect, useState } from 'react'
import { Pie } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { BiPieChart } from 'react-icons/bi'
import utils from '../../utils';

function PaymentModeChart(props) {
    Chart.register(...registerables);
    const paymentsData = props.chartData
    const [chartData, setChartData] = useState()

    useEffect(() => {
        if (paymentsData?.payment_mode_name_aggregate) {
            setChartData({
                labels: paymentsData?.payment_mode_name_aggregate.map((item) => (item?.base_rate__payment_mode__name)),
                datasets: [{
                    data: paymentsData?.payment_mode_name_aggregate.map((item) => (item.count)),               
                }],
            })
        }
    }, [paymentsData])
    useEffect(() => {}, [chartData])
    return (
        <div className='bg-base-100 rounded-md p-3'>
            <div className="flex mb-3">
                <BiPieChart className='font-bold text-2xl mr-3'/>
                <p className="font-bold">Payment Mode Chart</p>
            </div>
            <div className="chart">
                <div style={{"height": "45vh"}}>
                    {chartData && (
                        <Pie
                            data={chartData}
                            options={utils.chartOptions}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default PaymentModeChart
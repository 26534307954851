import React from 'react'
import ChangePasswordForm from './ChangePasswordForm'

function ChangePasswordTab(props) {
    return (
        <div>
            <ChangePasswordForm/>
        </div>
    )
}

export default ChangePasswordTab
import React, { useEffect, useRef, useState } from 'react'
import Loading from '../feedback/Loading'
import FormError from '../feedback/FormError'

function CreateCategoryModal(props) {
    const nameRef = useRef()
    const descriptionRef = useRef()
    const imageRef = useRef()
    const [file, setFile] = useState()
    const error = props.error
    const loading = props.loading
    const success = props.success

    useEffect(() => {}, [file])
    useEffect(() => {
        if (success) {
            descriptionRef.current.value = null
            nameRef.current.value = null
            setFile()
            imageRef.current.value = null
        }
    }, [success])

    function imageChanged(event){
        setFile(event.target.files[0])
    }
    function submitHandler(e){
        e.preventDefault()
        const formData = new FormData()
        formData.append("name", nameRef.current.value)
        formData.append("description", descriptionRef.current.value)
        formData.append("image", file)
        props.submitHandler(formData)
    }
    return (
        <div className="">
            <label htmlFor="category-modal" id='category-modal-open-btn' className="btn btn-sm">Add Category</label>
            <input type="checkbox" id="category-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    <label htmlFor="category-modal" id='category-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3 text-gray-700  mb-5'>Add Category</h2>
                    <form onSubmit={submitHandler}>
                        {error && (
                            <FormError error={error}/>
                        )}
                        <div className="form-control mb-3">
                            <label className="label">
                                <span className="label-text">Name</span>
                            </label>
                            <input required ref={nameRef} name="name" className="input input-bordered w-full input-success text-gray-600" />
                        </div>
                        <div className="form-control mb-3">
                            <label className="label">
                                <span className="label-text">Image</span>
                            </label>
                            <input type="file" ref={imageRef} onChange={imageChanged} required className="file-input text-gray-600 file-input-bordered file-input-success w-full max-w-xs" />
                        </div>
                        <div className="form-control mb-5">
                            <label className="label">
                                <span className="label-text">Description</span>
                            </label>
                            <textarea cols={4} required ref={descriptionRef} name="name" className="input text-gray-600 input-bordered w-full h-20 input-success" />
                        </div>
                        <div className="flex justify-center mb-3">
                            {loading ? (
                                <Loading/>
                            ):(
                                <button className='btn btn-secondary'>Submit</button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CreateCategoryModal
import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast';
import Loading from '../feedback/Loading'
import FormError from '../feedback/FormError'
import {HiTrash} from 'react-icons/hi'
import paymentServices from '../../services/PaymentsService'
import utils from '../../utils';

function BaseRateUpdateModal(props) {
    const closeBtn = document.getElementById("base-rate-update-modal-close-btn")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const baseRateUpdate = props.baseRateUpdate
    const amountRef = useRef()
    const [show, setShow] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)

    useEffect(() => {
        if (baseRateUpdate) {
            setDeleteLoading(false)
            amountRef.current.value = baseRateUpdate?.amount
        }
    }, [baseRateUpdate])

    function submitHandler(e){
        e.preventDefault()
        setLoading(true)
        setError()
        const formData = new FormData()
        formData.append("amount", amountRef.current.value)
        paymentServices.adminUpdateBaseRate(baseRateUpdate?.id, formData).then(
            res => {
                setLoading(false)
                closeBtn.click()
                toast.success("Base Rate updated successfully")
                props.resetOrders()
            },
            err => {
                setError(err)
                setLoading(false)
                toast.error(utils.toasterError)
            }
        )
    }
    function deleteBaseRate(baseRate){
        setDeleteLoading(true)
        paymentServices.adminDeleteBaseRate(baseRate?.id).then(
            res => {
                setDeleteLoading(false)
                closeBtn.click()
                toast.success("Base Rate deleted successfully")
                props.resetOrders()
            },
            err => {
                setDeleteLoading(false)
            }
        )
    }
    return (
        <div>
            <label htmlFor="base-rate-update-modal" id='base-rate-update-modal-open-btn' className="hide__element"></label>
            <input type="checkbox" id="base-rate-update-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    <label htmlFor="base-rate-update-modal" id='base-rate-update-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3  mb-5'>Update / Delete : {baseRateUpdate?.payment_mode?.name} - {baseRateUpdate?.subscription_type}</h2>
                    <p>Payment Mode: <span className='font-bold'>{baseRateUpdate?.payment_mode?.name}</span></p>
                    <p>Subscription Type: <span className='font-bold'>{baseRateUpdate?.subscription_type}</span></p>
                    <form onSubmit={submitHandler}>
                        {error && (
                            <FormError error={error}/>
                        )}
                        <div className="form-control mb-3 text-gray-700">
                            <label className="label">
                                <span className="label-text">Amount</span>
                            </label>
                            <input type="number" ref={amountRef} name="amount" className="input input-bordered w-full input-success" />
                        </div>
                        <div className="flex justify-center mb-5">
                            {loading ? (
                                <Loading/>
                            ):(
                                <button className='btn btn-secondary'>Submit</button>
                            )}
                        </div>
                    </form>
                    <div className="alert alert-warning rounded-md mb-5">
                        <div className="w-full">
                            <div className="flex space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                                <span>Warning: Deleting is irreversable!</span>
                            </div>
                            {show ? (
                                <div className="mt-4">
                                    {deleteLoading ? (
                                        <Loading/>
                                    ):(
                                        <div className="">
                                            <button 
                                                onClick={() => deleteBaseRate(baseRateUpdate)}
                                                className='btn btn-error'><HiTrash/> Delete Parmanently</button>
                                        </div>
                                    )}
                                </div>
                            ):(
                                <div className="mt-4">
                                    <button 
                                        onClick={() => setShow(!show)}
                                        className='btn btn-neutral'><HiTrash/>  Delete {baseRateUpdate?.payment_mode?.name} - {baseRateUpdate?.subscription_type}</button>
                                </div>
                            )}
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BaseRateUpdateModal
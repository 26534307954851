import React, { useEffect } from 'react'
import utils from '../../utils'
// import {ImBin} from 'react-icons/im'

function CartItem(props) {
    const house = props.house
    const itemId = props.itemId

    useEffect(() => {}, [house])
    useEffect(() => {}, [itemId])

    function deleteItem(){
        props.deleteCartItem(itemId)
    }
    return (
        <div className='mb-3'>
            <div className="grid grid-cols-3 md:grid-cols-4 rounded-md p-3 border bg-base-200 hover:shadow-lg gap-2 hover:bg-base-300">
                <div className="place-self-center flex gap-6 items-center">
                    <span className=''>
                        {props.idx + 1} .
                    </span>
                    <div className="avatar">
                        <div className="">
                            <a href={'/house/' + house?.slug}>
                                <div className="avatar">
                                    <div className="mask  rounded-md w-12 h-12 lg:w-14 lg:h-16">
                                        <img src={utils.baseUrl + house?.image} alt={house?.name}/>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="place-self-center text-gray-500 font-bold text-sm md:text-base">
                    {house?.name}
                </div>
                <div className="font-bold place-self-center text-sm md:text-base">
                    KSHS: {utils.toCommas(house?.price)}
                </div>
                <div className="place-self-center">
                    <button 
                        onClick={() => deleteItem()}
                        className='btn btn-error btn-sm'
                        >Remove</button>
                </div>
            </div>
        </div>
    )
}

export default CartItem
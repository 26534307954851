import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import AuthBreadCrumbs from '../../components/nav/AuthBreadCrumbs'
import HouseDetailsTab from '../../components/houses/HouseDetailsTab'
import HouseMediaTabs from '../../components/houses/HouseMediaTabs'
import houseServices from '../../services/HousesService'
import { useParams } from 'react-router-dom'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'

function HousesFormPage(props) {
    const houseParam = useParams()
    const slug = houseParam?.slug
    const action = props.action
    const [activeTab, setActiveTab] = useState(0)
    const [loading, setLoading] = useState(false)
    const [houseError, setHouseError] = useState()
    const [house, setHouse] = useState()
    const [updateHouse, setUpdateHouse] = useState()
    const [houseLoading, setHouseLoading] = useState(false)
    const [loadingError, setLoadingError] = useState()
    const tabs = [
        "House Details",
        "Media Files"
    ]

    useEffect(() => {}, [activeTab])
    useEffect(() => {}, [house])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [houseError])
    useEffect(() => {}, [updateHouse])
    useEffect(() => {}, [loadingError])
    useEffect(() => {
        if (action === 2) {
            setHouseLoading(true)
            setLoadingError()
            houseServices.getHouse(slug).then(
                res => {
                    setUpdateHouse(res.data)
                    setHouseLoading(false)
                },
                err => {
                    console.log(err);
                    setLoadingError(err)
                    setHouseLoading(false)
                }
            )
        }
    }, [action, slug])

    function submitHandler(details){
        setLoading(true)
        setHouseError()
        if (action === 1) {
            houseServices.createHouse(details).then(
                res => {
                    setHouse(res.data)
                    setLoading(false)
                    setActiveTab(1)
                },
                err => {
                    console.log(err);
                    setLoading(false)
                    setHouseError(err)
                }
            )
        } else{
            houseServices.updateHouse(details, slug).then(
                res => {
                    setLoading(false)
                    setActiveTab(1)
                },
                err => {
                    console.log(err);
                    setLoading(false)
                    setHouseError(err)
                }
            )
        }
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>{action === 1 ? 'Add' : 'Update'} House - {utils.siteName}</title>
            </Helmet>
            <div className='px-1 md:px-4 lg:px-12'>
                <AuthBreadCrumbs currentPage={2} action={action}/>
                <div className="p-2">
                    <h1 className="text-center text-3xl mb-6">{action === 1 ? 'Add' : 'Update'} House</h1>
                    <div className="flex justify-center mb-6">
                        <div className="tabs">
                            {tabs.map((tab, idx) => (
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a 
                                    key={idx}
                                    className={activeTab === idx ? 'tab tab-bordered tab-active' : 'tab tab-bordered'}
                                    onClick={() => setActiveTab(idx)}
                                >{tab}</a>
                            ))}
                        </div>
                    </div>
                    {houseLoading ? (<Loading/>):(
                        <div>
                            {loadingError ? (<FetchError error={loadingError}/>):(
                                <div>
                                    <div className={activeTab !== 0 ? 'hide__element' : ''}>
                                        <HouseDetailsTab
                                            submitHandler={submitHandler}
                                            loading={loading}
                                            error={houseError}
                                            updateHouse={updateHouse}
                                            action={action}
                                        />
                                    </div>
                                    <div className={activeTab !== 1 ? 'hide__element' : ''}>
                                        <HouseMediaTabs
                                            action={action}
                                            updateHouse={updateHouse}
                                            house={house}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </HelmetProvider>
    )
}

export default HousesFormPage
import React, { useEffect, useState } from 'react'
import QuoteTableRow from './QuoteTableRow';
import {BsFillChatRightQuoteFill} from 'react-icons/bs'
import QuoteViewModal from './QuoteViewModal';

function QuotesTable(props) {
    const [viewRequest, setViewRequest] = useState()
    const quotes = props.quotes

    useEffect(() => {}, [quotes])

    useEffect(() => {}, [viewRequest])

    function openModal(quote){
        setViewRequest(quote)
        const openBtn = document.getElementById("quote-request-modal-open-btn")
        openBtn.click()
    }
    return (
        <div className='p-3 bg-base-300 border rounded-md'>
            <div className="flex space-x-3 items-center font-bold">
                <BsFillChatRightQuoteFill className='h-5 w-5'/>
                <span>Request Quotes Table</span>
            </div>
            <QuoteViewModal
                viewRequest={viewRequest}
                getUserQuotes={props.getUserQuotes}
            />
            <div className="mt-2">
                <div className="overflow-x-auto">
                    <table className="table table-zebra table-sm">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Created</th>
                                <th>View</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {quotes.map((quote, idx) => (
                                <QuoteTableRow
                                    key={idx}
                                    quote={quote}
                                    openModal={openModal}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default QuotesTable
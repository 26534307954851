import { createContext, useState } from "react";

const CartContext = createContext({
    itemSlugs: [],
    numCartItems: 0,
    cartIsSet: false,
    setItemSlugs: (slugs) => {},
    setNumItems: (num) => {},
    setCart: (cart) => {},
    inCart: (slug) => {},
    getCartId: () => {},
    resetCart: () => {},
    purchasedItems: [],
    checkedPurchases: false,
    setPurchasedItems: (data) => {},
    inPurchases: (slug) => {},
})

export function CartContextProvider(props){
    const [itemSlugs, setItemSlugs] = useState(() => {
        return JSON.parse(sessionStorage.getItem('itemSlugs')) || []
    })

    function setItemSlugsHandler(slugs){
        return (
            sessionStorage.setItem('itemSlugs', JSON.stringify(slugs)),
            setItemSlugs(slugs)
        )
    }

    const [numCartItems, setNumCartItems] = useState(() => {
        const numItems = sessionStorage.getItem('numItems')
        if(numItems === null){
            return 0
        } else{
            return numItems
        }
    })

    function setNumItemsHandler(num){
        return (
            sessionStorage.setItem('numItems', num),
            setNumCartItems(num)
        )
    }

    const [cartIsSet, setCartIsSet] = useState(() => {
        const cartId = sessionStorage.getItem('cartId')
        if (cartId === null){
            return false
        }
        return true
    })

    function setCartHandler(cart){
        return (
            sessionStorage.setItem('cartId', cart?.id),
            setCartIsSet(true)
        )
    }

    function inCartHandler(slug){
        const items = JSON.parse(sessionStorage.getItem('itemSlugs')) || []
        if (items === null){
            return false
        } else {
            return items.some(item => item === slug)
        }
    }

    function getCartIdHandler(){
        const cartId = sessionStorage.getItem('cartId')
        if (cartId === null){
            return null
        }
        return cartId
    }

    function resetCartHandler(){
        sessionStorage.setItem("numItems", 0)
        sessionStorage.setItem("cartId", null)
        sessionStorage.setItem("itemSlugs", null)
        return (
            setNumCartItems(0),
            setCartIsSet(false),
            setItemSlugs([])
        )
    }

    const [purchasedItems, setPurchasedItems] = useState(() => {
        return JSON.parse(sessionStorage.getItem('purchases')) || []
    })

    const [checkedPurchases, setCheckedPurchases] = useState(() => {
        const purchases = sessionStorage.getItem('purchases')
        if (purchases === null){
            return false
        }
        return true
    })

    function setPurchasedItemsHandler(data){
        return (
            sessionStorage.setItem('purchases', JSON.stringify(data)),
            setPurchasedItems(data),
            setCheckedPurchases(true)
        )
    }

    function inPurchasesHandler(slug){
        const items = JSON.parse(sessionStorage.getItem('purchases')) || []
        if (items === null) {
            return false
        } else{
            return items.some((item) => item?.slug === slug)
        }
    }

    const context = {
        itemSlugs: itemSlugs,
        numCartItems: numCartItems,
        cartIsSet: cartIsSet,
        setItemSlugs: setItemSlugsHandler,
        setNumItems: setNumItemsHandler,
        setCart: setCartHandler,
        inCart: inCartHandler,
        getCartId: getCartIdHandler,
        resetCart: resetCartHandler,
        purchasedItems: purchasedItems,
        checkedPurchases: checkedPurchases,
        setPurchasedItems: setPurchasedItemsHandler,
        inPurchases: inPurchasesHandler
    }

    return <CartContext.Provider value={context}>
        {props.children}
    </CartContext.Provider>
}

export default CartContext
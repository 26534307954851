import apiClient from './AxiosHttp'

const api = '/api/v1'

const getPaymentModes = () => {
    return apiClient.get(`${api}/payments/modes/`)
}

const getBaseRates = () => {
    return apiClient.get(`${api}/payments/base/rates/`)
}

const getPayment = (id) => {
    return apiClient.get(`${api}/payments/payment/${id}/`)
}

const getUserPayments = (params) => {
    return apiClient.get(`${api}/payments/${params}`)
}

const makeMpesaSubscriptionPayment = (details) => {
    return apiClient.post(`${api}/payments/subscriptions/make/mpesa/`, details)
}

const makePayPalSubscriptionPayment = (details) => {
    return apiClient.post(`${api}/payments/subscriptions/make/mpesa/`, details)   
}

const getPaymentCartItems = (id) => {
    return apiClient.get(`${api}/payments/cart/items/${id}/`)
}

const getUserPurchasedItemSlugs = () => {
    return apiClient.get(`${api}/payments/paid/items/slugs/`)
}

const downloadItemMedia = (id, action) => {
    return apiClient.get(
        `${api}/payments/download/cart/item/${id}/${action}/`, 
        { responseType: 'blob' }
    )
}

const makeMpesaPayment = (details) => {
    return apiClient.post(`${api}/payments/mpesa/make/`, details)
}

const makeCreditCardPayment = (details) => {
    return apiClient.post(`${api}/payments/credit/card/make/`, details)
}

const adminGetPayments = (params) => {
    return apiClient.get(`${api}/admin/payments/${params}`)
}

const adminCreateBaseRate = (details) => {
    return apiClient.post(`${api}/admin/base/rate/create/`, details)
}

const adminCreatePaymentModes = (details) => {
    return apiClient.post(`${api}/admin/payment/mode/create/`, details)
}

const adminUpdateBaseRate = (id, details) => {
    return apiClient.patch(`${api}/admin/base/rate/update/${id}/`, details)
}

const adminDeleteBaseRate = (id) => {
    return apiClient.delete(`${api}/admin/base/rate/delete/${id}/`)
}

const adminUpdatePaymentMode = (id, details) => {
    return apiClient.patch(`${api}/admin/payment/mode/update/${id}/`, details)
}

const adminDeletePaymentMode = (id) => {
    return apiClient.delete(`${api}/admin/payment/mode/delete/${id}/`)
}

const adminGetPaymentsChartData = () => {
    return apiClient.get(`${api}/admin/charts/payments/`)
}

const paymentServices = {
    getUserPayments,
    getPaymentModes,
    getBaseRates,
    getPayment,
    makeMpesaSubscriptionPayment,
    makePayPalSubscriptionPayment,
    adminGetPayments,
    adminCreateBaseRate,
    adminCreatePaymentModes,
    getUserPurchasedItemSlugs,
    makeMpesaPayment,
    makeCreditCardPayment,
    getPaymentCartItems,
    downloadItemMedia,
    adminUpdateBaseRate,
    adminDeleteBaseRate,
    adminUpdatePaymentMode,
    adminDeletePaymentMode,
    adminGetPaymentsChartData
}

export default paymentServices
import React, { useContext, useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import RegisterForm from '../../components/auth/RegisterForm'
import authServices from '../../services/UsersService'
import { useNavigate } from 'react-router-dom'
import UserContext from '../../context/UserContext'

function RegisterPage(props) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const navigate = useNavigate()
    const userCtx = useContext(UserContext)

    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])

    function submitHandler(details){
        setError()
        setLoading(true)
        authServices.register(details).then(
            res => {
                userCtx.setAuthToken(res.data?.key)
                setLoading(false)
                navigate('/auth/profile')
            }, 
            err => {
                setLoading(false)
                setError(err)
            }
        )
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>Register - {utils.siteName}</title>
            </Helmet>
            <div>
                <RegisterForm
                    submitHandler={submitHandler}
                    loading={loading}
                    error={error}
                />
            </div>
        </HelmetProvider>
    )
}

export default RegisterPage
import React, { useContext, useEffect } from 'react'
import {FaQuoteLeft} from 'react-icons/fa'
import {BsFillChatRightQuoteFill} from 'react-icons/bs'
import SessionContext from '../../context/SessionContex'

function QuotesSummary(props) {
    const quotes = props.quotes
    const sessionCtx = useContext(SessionContext)
    const quotesThisMonth = sessionCtx.quotesThisMonth

    useEffect(() => {}, [quotes])
    useEffect(() => {}, [quotesThisMonth])
    return (
        <div className='mb-4'>
            <h2 className="text-1xl mb-2">Quote Request Summary</h2>
            <div className="flex flex-col md:flex-row gap-2">
                <div className="stats rounded-md bg-secondary text-primary-content">
                    <div className="stat">
                        <div className="stat-title text-black">Total Quote Requests</div>
                        <div className="stat-value">{quotes.length}</div>
                        <div className="stat-actions">
                            <BsFillChatRightQuoteFill className='h-9 w-9'/>
                        </div>
                    </div>
                </div>
                <div className="stats rounded-md bg-secondary text-primary-content">
                    <div className="stat">
                        <div className="stat-title text-black">New Quote Requests This Month</div>
                        <div className="stat-value">{quotesThisMonth}</div>
                        <div className="stat-actions">
                            <FaQuoteLeft className='h-9 w-9'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuotesSummary
import React, { useEffect, useState } from 'react'
import {AiOutlineLineChart} from 'react-icons/ai'
import { Line } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js';
import moment from 'moment'
import utils from '../../utils';

function PaymentCreationChart(props) {
    Chart.register(...registerables);
    const [chartData, setChartData] = useState()
    const paymentData = props.chartData

    useEffect(() => {
        if (paymentData?.payment_month_aggregate) {
            setChartData({
                labels: paymentData?.payment_month_aggregate.map((item) => (moment(item?.month, 'Y M').format('MMM YYYY'))),
                datasets: [{
                    label: 'Payments Creation Chart',
                    data: paymentData?.payment_month_aggregate.map((item) => (item.count)),               
                }],
            })
        }
    }, [paymentData])
    useEffect(() => {}, [chartData])
    return (
        <div className='bg-base-100 rounded-md p-3'>
            <div className="flex">
                <AiOutlineLineChart className='font-bold text-2xl mr-3'/>
                <p className="font-bold">Payments Creation Chart</p>
            </div>
            <div className='w-100'>
                <div style={{"height": "45vh"}}>
                    {chartData && (
                        <Line 
                            data={chartData} 
                            options={utils.chartOptions}/>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PaymentCreationChart
import React, { useContext, useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import SideBar from '../../components/houses/SideBar'
import HouseList from '../../components/houses/HouseList'
import houseServices from '../../services/HousesService'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'
import { useSearchParams } from 'react-router-dom'
import FilterContext from '../../context/FilterContext'
import MobileSideBar from '../../components/houses/MobileSideBar'
import Pagination from '../../components/nav/Pagination'

function HousesListPage(props) {
    const [searchParam, setSearchParams] = useSearchParams()
    const [hasFiltered, setHasFiltered] = useState(false)
    const [houses, setHouses] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const categoryParam = searchParam.get("category__slug")
    const roomsParam = searchParam.get("bedrooms")
    const storiesParam = searchParam.get("stories")
    const bathroomsParam = searchParam.get("bathrooms")
    const pageParam = searchParam.get("page")
    const filterCtx = useContext(FilterContext)

    useEffect(() => {
        if (!hasFiltered) {
            filterCtx.setHouseCategory(categoryParam)
            filterCtx.setHouseRooms(parseInt(roomsParam))
            filterCtx.setHouseStories(parseInt(storiesParam))
            filterCtx.setHouseBathrooms(bathroomsParam)
        }
    }, [
        hasFiltered, categoryParam, roomsParam, 
        filterCtx, storiesParam, bathroomsParam,
        pageParam
    ])
    useEffect(() => {
        const params = searchParam.toString()
        setLoading(true)
        houseServices.getHouses(params ? `?${params}` : '').then(
            res => {
                setHouses(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }, [searchParam])
    useEffect(() => {}, [houses])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])

    function changeCategoryParam(value){
        utils.changeUrlPageParam(
            value, "category__slug", hasFiltered,
            setHasFiltered, filterCtx, setSearchParams
        )
    }
    function changeRoomsParam(value){
        utils.changeUrlPageParam(
            value, "bedrooms", hasFiltered,
            setHasFiltered, filterCtx, setSearchParams
        )
    }
    function changeStoriesParam(value){
        utils.changeUrlPageParam(
            value, "stories", hasFiltered,
            setHasFiltered, filterCtx, setSearchParams
        )
    }
    function changeBathroomsParam(value){
        utils.changeUrlPageParam(
            value, "bathrooms", hasFiltered,
            setHasFiltered, filterCtx, setSearchParams
        )
    }
    function changePageParam(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            setSearchParams((params) => {
                params.delete("page")
                return params
            })
        }
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Houses - {utils.siteName}</title>
            </Helmet>
            <div className='px-1 md:px-4 lg:px-12'>
                <div className="md:flex md:gap-4 lg:gap-6">
                    <SideBar
                        changeCategoryParam={changeCategoryParam}
                        changeRoomsParam={changeRoomsParam}
                        changeStoriesParam={changeStoriesParam}
                        changeBathroomsParam={changeBathroomsParam}
                        storiesParam={storiesParam}
                        bathroomsParam={bathroomsParam}
                    />
                    <div className="w-100 md:w-8/12 lg:w-3/4 p-2">
                        <h1 className="text-center text-3xl mb-6">Houses</h1>
                        <hr  className='mb-6'/>
                        {loading ? (
                            <Loading/>
                        ):(
                            <div>
                                {error ? (
                                    <FetchError error={error}/>
                                ) :(
                                    <div className="">
                                        <HouseList
                                            houses={houses}
                                        />
                                        {((houses?.next !== null) || (houses?.previous !== null)) && (
                                            <Pagination
                                                next={houses?.next}
                                                previous={houses?.previous}
                                                changePageParam={changePageParam}
                                                isAdmin={false}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    
                </div>
                <div>
                    <MobileSideBar
                        changeCategoryParam={changeCategoryParam}
                        changeRoomsParam={changeRoomsParam}
                        changeStoriesParam={changeStoriesParam}
                        changeBathroomsParam={changeBathroomsParam}
                        storiesParam={storiesParam}
                        bathroomsParam={bathroomsParam}
                    />
                </div>
            </div>
        </HelmetProvider>
    )
}

export default HousesListPage

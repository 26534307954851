import React, { useEffect, useState } from 'react'
import EmptyList from '../feedback/EmptyList'
import PaymentItem from './PaymentItem'
import PaymentCartItemsModal from './PaymentCartItemsModal'
import Pagination from '../nav/Pagination'

function PaymentList(props) {
    const [selectedPayment, setSelectedPayment] = useState()
    const payments = props.payments

    useEffect(() => {}, [payments])
    useEffect(() => {}, [selectedPayment])

    function openPaymentItemsModal(payment){
        setSelectedPayment(payment)
        const openBtn = document.getElementById("payment-items-modal-open-btn")
        openBtn.click()
    }
    function changePageParam(page){
        props.filterPage(page);
    }
    return (
        <div>
            <PaymentCartItemsModal
                selectedPayment={selectedPayment}
            />
            <div className="">
                {payments?.results?.map((payment, idx) => (
                    <PaymentItem
                        key={idx}
                        idx={idx}
                        payment={payment}
                        openPaymentItemsModal={openPaymentItemsModal}
                    />
                ))}
            </div>
            {payments && (
                <div className="">
                    {payments?.count < 1 && (
                        <EmptyList item={"Payment List"}/>
                    )}
                    {((payments?.next !== null) || (payments?.previous !== null)) && (
                        <Pagination
                            next={payments?.next}
                            previous={payments?.previous}
                            changePageParam={changePageParam}
                            isAdmin={true}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default PaymentList
import React, { useEffect, useState } from 'react'
import {BsFillGrid3X3GapFill} from 'react-icons/bs'
import PaymentModeTableRow from './PaymentModeTableRow'
import PaymentModeUpdateModal from './PaymentModeUpdateModal'

function PaymentModeTable(props) {
    const paymentModes = props.paymentModes
    const [paymentModeUpdate, setPaymentModeUpdate] = useState()

    useEffect(() => {
        setPaymentModeUpdate()
    }, [paymentModes])
    useEffect(() => {}, [paymentModeUpdate])

    function modifyPaymentMode(paymentMode){
        setPaymentModeUpdate(paymentMode)
        const openBtn = document.getElementById("payment-mode-update-modal-open-btn")
        openBtn.click()
    }
    return (
        <div className='mt-5'>
            <div className="collapse collapse-arrow border rounded-md bg-base-300">
                <input type="checkbox" />   
                <div className="flex items-center space-x-3 collapse-title text-1xl font-medium">
                    <BsFillGrid3X3GapFill className='h-5 w-5'/>
                    <span>Payment Modes Table</span>
                </div>
                <div className="collapse-content">
                    <PaymentModeUpdateModal
                        paymentModeUpdate={paymentModeUpdate}
                        resetOrders={props.resetOrders}
                    />
                    <div className="mt-2">
                        <div className="overflow-x-auto">
                            <table className="table table-zebra table-sm">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Updated</th>
                                        <th>Created</th>
                                        <th>Update</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paymentModes && paymentModes?.map && (
                                        <>
                                            {paymentModes?.map((paymentMode, idx) => (
                                                <PaymentModeTableRow
                                                    key={idx}
                                                    paymentMode={paymentMode}
                                                    modifyPaymentMode={modifyPaymentMode}
                                                />
                                            ))}
                                        </>
                                    )}                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentModeTable
import apiClient from './AxiosHttp'

const api = '/api/v1'

const checkCart = () => {
    return apiClient.get(`${api}/cart/check/`)
}

const getCart = () => {
    return apiClient.get(`${api}/cart/`)
}

const getCartItems = () => {
    return apiClient.get(`${api}/cart/items/`)
}

const addCartItem = (details) => {
    return apiClient.post(`${api}/cart/item/`, details)
}

const deleteCartItem = (details) => {
    return apiClient.post(`${api}/cart/item/delete/`, details)
}

const cartServices = {
    checkCart,
    getCart,
    getCartItems,
    addCartItem,
    deleteCartItem
}

export default cartServices
import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import ProfileSideBar from '../../components/auth/ProfileSideBar'
import paymentServices from '../../services/PaymentsService'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'
import PaymentList from '../../components/payments/PaymentList'
import { useSearchParams } from 'react-router-dom'

function PaymentsPage(props) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [payments, setPayments] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()

    useEffect(() => {
        const params = searchParams.toString()
        getUserPayment(params)
    }, [searchParams])
    useEffect(() => {}, [payments])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])

    function getUserPayment(params){
        setLoading(true)
        paymentServices.getUserPayments(params ? `?${params}` : '').then(
            res => {
                setLoading(false)
                setPayments(res.data)
            },
            err => {
                setLoading(false)
                setError(err)
            }
        )
    }
    function filterPage(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            if (searchParams.has("page")) {
                setSearchParams(
                    searchParams.delete("page")
                )
            }
        }
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Payments - {utils.siteName}</title>
            </Helmet>
            <div className='px-1 md:px-4 lg:px-12'>
                <div className="md:flex md:gap-4 lg:gap-6">
                    <ProfileSideBar active="payments"/>
                    <div className="w-100 md:w-8/12 lg:w-3/4 p-2">
                        <h1 className="text-center text-3xl mb-6">Payments</h1>
                        <p className='mb-4'>Count: <span>{payments?.count}</span></p>
                        <hr  className='mb-5'/>
                        {loading ? (
                            <Loading/>
                        ):(
                            <div className="">
                                {error ? (
                                    <FetchError error={error}/>
                                ):(
                                    <PaymentList 
                                        filterPage={filterPage}
                                        payments={payments}/>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </HelmetProvider>
    )
}

export default PaymentsPage
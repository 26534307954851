import React, { useEffect, useState } from 'react'
import {BsFillEyeFill} from 'react-icons/bs'
import {FaComments} from 'react-icons/fa'
import CreateCategoryModal from './CreateCategoryModal'
import houseServices from '../../services/HousesService'
import { Link } from 'react-router-dom'

function HousesSummary(props) {
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState()
    const houses = props.houses
    const categories = props.categories 
    const statistics = props.statistics

    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    useEffect(() => {}, [success])

    function submitHandler(details){
        setLoading(true)
        setError()
        setSuccess(false)
        houseServices.createCategory(details).then(
            res => {
                setSuccess(true)
                setLoading(false)
                const closeBtn = document.getElementById("category-modal-close-btn")
                closeBtn.click()
                props.getCategories()
            }, 
            err => {
                console.log(err);
                setLoading(false)
                setError(err)
            }
        )
    }
    return (
        <div className='mb-4'>
            <h2 className="text-1xl mb-2">Houses Summary</h2> 
            <div className="flex flex-col md:flex-row gap-2">
                <div className="stats rounded-md bg-primary text-primary-content">
                    <div className="stat">
                        <div className="stat-title text-black">Total Houses</div>
                        <div className="stat-value">{houses?.count}</div>
                        <div className="stat-actions">
                            <Link to='/houses/add' className="btn btn-sm">Add House</Link>
                        </div>
                    </div>
                </div>
                <div className="stats rounded-md bg-primary text-primary-content">
                    <div className="stat">
                        <div className="stat-title text-black">Categories</div>
                        <div className="stat-value">{categories?.length}</div>
                        <div className="stat-actions">
                            <CreateCategoryModal
                                submitHandler={submitHandler}
                                loading={loading}
                                error={error}
                                success={success}
                            />
                        </div>
                    </div>
                </div>
                <div className="stats rounded-md bg-secondary text-primary-content">
                    <div className="stat">
                        <div className="stat-title text-black">Total Views</div>
                        <div className="stat-value">{statistics?.house_views}</div>
                        <div className="stat-actions">
                            <BsFillEyeFill className='h-9 w-9'/>
                        </div>
                    </div>
                </div>
                <div className="stats rounded-md bg-secondary text-primary-content">
                    <div className="stat">
                        <div className="stat-title text-black">Total Comments</div>
                        <div className="stat-value">{statistics?.comment_count}</div>
                        <div className="stat-actions">
                            <FaComments className='h-9 w-9'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HousesSummary
import utils from '../utils'
import apiClient from './AxiosHttp'
import axios from "axios"

const api = '/api/v1'

const createQuote = (details) => {
    return apiClient.post(`${api}/users/quote/`, details) 
}

const register = (details) => {
    return apiClient.post(`${api}/auth/registration/`, details)
}

const login = (details) => {
    return apiClient.post(`${api}/auth/login/`, details)
}

const getUser = () => {
    return apiClient.get(`${api}/auth/user/`) 
}

const getProfile = () => {

}

const getUserSubscrptionStatus = () => {
    return apiClient.get(`${api}/payments/user/subscription/status/`)
}

const changePassword = (passwords) => {
    return apiClient.post(`${api}/auth/password/change/`, passwords)
}

const resetPassword = (details) => {
    return apiClient.post(`${api}/auth/password/reset/`, details)
}

const resetPasswordConfirm = (details) => {
    return apiClient.post(`${api}/auth/password/reset/confirm/`, details)
}


const updateUser = (details) => {
    return apiClient.patch(
        `${api}/auth/user/`, 
        details,
        {headers:utils.multipartHeader}
    )
}

const adminAllUsers = (param) => {
    return apiClient.get(`${api}/admin/users/${param}`)
}

const adminCreateUser = (details) => {
    return axios.post(`${utils.baseUrl + api}/auth/registration/`, details)
}

const adminGetHouseStatistics = () => {
    return apiClient.get(`${api}/admin/houses/statistics/`)
}

const adminDeleteUser = (id) => {
    return apiClient.delete(`${api}/admin/user/modify/delete/${id}/`)
}

const adminUpdateUser = (id, details) => {
    return apiClient.patch(`${api}/admin/user/modify/update/${id}/`, details)
}

const adminGetBaseStatistics = () => {
    return apiClient.get(`${api}/admin/count/statistics/`)
}

const adminGetUsersChartData = () => {
    return apiClient.get(`${api}/admin/charts/users/`)
}

const adminGetUserQuotes = (param) => {
    return apiClient.get(`${api}/admin/user/quotes/`)
}

const adminDeleteQuoteRequest = (id) => {
    return apiClient.delete(`${api}/admin/user/quote/delete/${id}/`)
}


const authServices = {
    createQuote,
    register,
    login,
    getUser,
    changePassword,
    getProfile,
    adminAllUsers,
    updateUser,
    getUserSubscrptionStatus,
    adminCreateUser,
    adminDeleteUser,
    adminUpdateUser,
    adminGetHouseStatistics,
    adminGetBaseStatistics,
    adminGetUsersChartData,
    adminGetUserQuotes,
    adminDeleteQuoteRequest,
    resetPassword,
    resetPasswordConfirm,
}

export default authServices
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import AuthBreadCrumbs from '../../components/nav/AuthBreadCrumbs'
import BlogForm from '../../components/blog/BlogForm'
import blogServices from '../../services/BlogService'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'

function BlogFormPage(props) {
    const blogParam = useParams()
    const slug = blogParam?.slug
    const action = props.action
    const [loading, setLoading] = useState(false)
    const [updateBlog, setUpdateBlog] = useState()
    const [loadingError, setLoadingError] = useState()

    useEffect(() => {
        if (action === 2) {
            setLoading(true)
            setLoadingError()
            blogServices.adminGetSpecifBlog(slug).then(
                res => {
                    setUpdateBlog(res.data)
                    setLoading(false)
                }, 
                err => {
                    setLoadingError(err)
                    setLoading(false)
                }
            )
        }
    }, [slug, action])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [updateBlog])
    useEffect(() => {}, [loadingError])
    return (
        <HelmetProvider>
            <Helmet>
                <title>{action === 1 ? 'Add' : 'Update'} Blog Post - {utils.siteName}</title>
            </Helmet>
            <div className='px-1 md:px-4 lg:px-12'>
                <AuthBreadCrumbs currentPage={3} action={action}/>
                {loading ? (<Loading/>):(
                    <div>
                        {loadingError ? (<FetchError error={loadingError}/>):(
                            <div>
                                <BlogForm
                                    updateBlog={updateBlog} 
                                    action={action}/>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </HelmetProvider>
    )
}

export default BlogFormPage
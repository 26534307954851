import React, { useEffect, useState } from 'react'
import utils from '../../utils'
import {FaXmark, FaCheck} from 'react-icons/fa6'
import toast from 'react-hot-toast';
import {BsFiletypeDoc} from 'react-icons/bs'
import Loading from '../feedback/Loading'
import houseServices from '../../services/HousesService'

function HouseMediaItem(props) {
    const [loading, setLoading] = useState(false)
    const media = props.media

    useEffect(() => {}, [media])
    useEffect(() => {}, [loading])
    
    function deleteMedia(media){
        setLoading(true)
        houseServices.deleteHouseMedia(media?.id).then(
            res => {
                setLoading(false)
                props.deleteHouseMedia(media)
                toast.success("House Media updated successfully")
            },
            err => {
                setLoading(false)
                toast.error(utils.toasterError)
            }
        )
    }

    function openPdfFile(url){
        window.open(url)
    }
    return (
        <div className='mb-3 p-3 gap-2 rounded-md border bg-base-200 hover:shadow-lg hover:bg-base-300'>
            <div className="grid grid-cols-3 md:grid-cols-4 items-center">
                <div className='place-self-center'>
                    {utils.isImage(media?.file) ? (
                        <img className="rounded-md h-16" src={utils.baseUrl + media?.file} alt={media?.house?.name} />
                    ):(
                        <div
                            onClick={() => openPdfFile(utils.baseUrl + media?.file)}
                        >
                            <div className="avatar placeholder">
                                    <div className="h-14 lg:h-16 bg-neutral-focus text-neutral-content rounded-md">
                                        <span className="text-3xl">
                                            <BsFiletypeDoc/>
                                        </span>
                                    </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className='place-self-center'>
                    <p>Type: 
                        <span className='ml-2'>{utils.isImage(media?.file) ? 'Image':'Document'}</span>
                    </p>
                </div>
                <div className='place-self-center'>
                    <p>Show only Paid: 
                        <span className='ml-2'>
                            {media?.show_premium ? (
                                <FaCheck/>
                            ):(
                                <FaXmark/>
                            )}
                        </span>
                    </p>
                </div>
                <div className='place-self-center'>
                    {loading ? (
                        <Loading/>
                    ):(
                        <button 
                            onClick={() => deleteMedia(media)}
                            className='btn btn-error btn-sm'>Delete</button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default HouseMediaItem
import React, { useEffect, useState } from 'react'
import blogServices from '../../services/BlogService'
import Loading from '../feedback/Loading'
import FetchError from '../feedback/FetchError'
import BlogList from '../blog/BlogList'
import { Link } from 'react-router-dom'

function BlogListHome() {
    const [posts, setPosts] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()

    useEffect(() => {
        setError()
        blogServices.getBlogs('').then(
            res => {
                setPosts(res.data)
                setLoading(false)
            },
            err => {
                setError(err)
                setLoading(false)
            }
        )
    }, [])
    useEffect(() => {}, [posts])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    return (
        <div className='mx-4'>
            <h2 className="text-center font-bold text-2xl mb-4">Our Blog</h2>
            {loading ? (<Loading/>):(
                <div>
                    {error ? (<FetchError error={error}/>):(
                        <div>
                            <BlogList blogs={posts}/>
                            <div className="flex justify-center">
                                <Link to={'/blog'} className='btn btn-wide mt-5 btn-secondary'>View More Posts</Link>
                            </div>                           
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default BlogListHome
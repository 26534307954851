import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast';
import FormError from '../feedback/FormError'
import Loading from '../feedback/Loading'
import paymentServices from '../../services/PaymentsService'

function CreatePaymentModeModal(props) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const nameRef = useRef()

    useEffect(() => {}, [error])

    function submitHandler(e){
        const closeBtn = document.getElementById("payment-mode-modal-close-btn")
        e.preventDefault()
        setLoading(true)
        const formData = new FormData()
        formData.append("name", nameRef.current.value)
        paymentServices.adminCreatePaymentModes(formData).then(
            res => {
                closeBtn.click()
                toast.success("Payment Mode created successfully")
                props.updatePaymentModes(res.data)
                nameRef.current.value = null
                setLoading(false)
            },
            err => {
                setError(err)
                setLoading(false)
                toast.error("Something went wrong")
            }
        )
    }
    return (
        <div>
            <label htmlFor="payment-mode-modal" id='payment-mode-modal-open-btn' className="btn btn-sm">Add Payment Mode</label>
            <input type="checkbox" id="payment-mode-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    <label htmlFor="payment-mode-modal" id='payment-mode-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3 text-gray-700  mb-5'>Add Payment Mode</h2>
                    <form onSubmit={submitHandler}>
                        {error && (
                            <FormError error={error}/>
                        )}
                        <div className="form-control mb-5">
                            <label className="label">
                                <span className="label-text">Name</span>
                            </label>
                            <input required ref={nameRef} name="name" className="input input-bordered w-full input-success text-gray-600" />
                        </div>
                        <div className="flex justify-center mb-3">
                            {loading ? (
                                <Loading/>
                            ):(
                                <button className='btn btn-secondary'>Submit</button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CreatePaymentModeModal
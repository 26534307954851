import React, { useContext, useEffect, useRef, useState } from 'react'
import UserContext from '../../context/UserContext'
import utils from '../../utils'
import FormError from '../feedback/FormError'
import Loading from '../feedback/Loading'

function UpdateProfileForm(props) {
    const userCtx = useContext(UserContext)
    const [file, setFile] =useState()
    const user = userCtx.user
    const emailRef = useRef()
    const imageRef = useRef()
    const loading = props.loading
    const error = props.error

    useEffect(() => {
        if (user) {
            emailRef.current.value = user?.email
        }
    }, [user])

    function imageChanged(event){
        setFile(event.target.files[0])
    }
    function submitHandler(e){
        e.preventDefault()
        const formData = new FormData()
        if (file){
            formData.append("image", file)
        }
        props.submitHandler(formData)
    }
    return (
        <div className='flex justify-center mt-8'>
            <div className='w-full md:w-10/12 lg:w-6/12'>
                <form onSubmit={submitHandler} className='border rounded-md hover:shadow-lg p-4 md:p-7 lg:p-12 bg-base-300'>
                    <p className="text-center text-2xl mb-7">Update Profile</p>
                    <div className="flex justify-center mb-5">
                        {utils.hasProfileImage(user) ? (
                            <div className="h-20 lg:h-24 rounded-md avatar placeholder">
                                <img src={user?.image} alt={user?.email}/>
                            </div>
                        ):(
                            <div className="avatar placeholder">
                                <div className="h-20 lg:h-24 bg-neutral-focus text-neutral-content mask rounded">
                                    <span className="text-3xl">
                                        {utils.makeProfileLetter(user)}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                    {error && (
                        <FormError error={error}/>
                    )}
                    <div className="form-control mb-3">
                        <label className="label">
                            <span className="label-text">Email:*</span>
                        </label>
                        <input type="email" required ref={emailRef} name="email" className="input input-bordered w-full input-success " />
                    </div>
                    <div className="form-control mb-6">
                        <label className="label">
                            <span className="label-text">Image:</span>
                        </label>
                        <input 
                            type="file" 
                            ref={imageRef} 
                            id='file-input' 
                            onChange={imageChanged}
                            className="file-input text-gray-600 file-input-bordered file-input-success w-full max-w-xs" />
                    </div>
                    <div className="flex justify-center mb-6">
                        {loading ? (
                            <Loading/>
                        ):(
                            <button type="submit" className='btn btn-secondary'>Submit</button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}

export default UpdateProfileForm
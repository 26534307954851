import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import blogServices from '../../services/BlogService'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'
import utils from '../../utils'

function BlogViewPage() {
    const [post, setPost] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const postParam = useParams()

    useEffect(() => {
        setError()
        blogServices.getSpecificBlog(postParam?.slug).then(
            res => {
                setPost(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }, [postParam?.slug])
    useEffect(() => {}, [error])
    useEffect(() => {}, [post])
    return (
        <HelmetProvider>
            <div className='px-1 md:px-4 lg:px-12'>
                {loading ? (<Loading/>):(
                    <div>
                        {error ? (<FetchError error={error}/>):(
                            <div>
                                <Helmet>
                                    <title>{post?.name} - {utils.siteName}</title>
                                </Helmet>
                                
                                <div className='flex md:justify-center md:gap-4 lg:gap-7 md:px-2 lg:px-16'>
                                    <div className="mx-auto flex justify-center mb-9 ">
                                        <img className='w-100 rounded-md' alt={post?.name} src={utils.baseUrl + post?.image}/>
                                    </div>
                                </div>
                                <h1 className="text-4xl text-center mb-12">{post?.name}</h1>
                                <div className='container mx-auto'>
                                    <article className="flex justify-center">
                                        <div className='prose'  
                                            dangerouslySetInnerHTML={{ __html: post?.text}}
                                        />
                                    </article>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </HelmetProvider>
    )
}

export default BlogViewPage
import React, { useEffect } from 'react'
import {RiMoneyDollarBoxFill} from 'react-icons/ri'
import EmptyList from '../feedback/EmptyList'
import OrdersTableRow from './OrdersTableRow'
import Pagination from '../nav/Pagination'

function OrdersTable(props) {
    const payments = props.payments

    useEffect(() => {}, [payments])

    function changePageParam(page){
        props.filterPage(page);
    }
    return (
        <div className='p-3 bg-base-300 border rounded-md mt-5'>
            <div className="flex space-x-3 items-center">
                <RiMoneyDollarBoxFill className='h-5 w-5'/>
                <span>Orders Table</span>
            </div>
            <div className="mt-2">
                <div className="">
                    <table className="table table-zebra table-sm overflow-x-scroll">
                        <thead>
                            <tr>
                                <th>Owner</th>
                                <th>Status</th>
                                <th>Amount</th>
                                <th>Payment Target</th>
                                <th>Mode</th>
                                <th>Subscription</th>
                                <th>Created</th>
                            </tr>
                        </thead>
                        <tbody>
                            {payments?.results.map((order, idx) => (
                                <OrdersTableRow
                                    key={idx}
                                    order={order}
                                />
                            ))}
                        </tbody>
                    </table>
                    {payments && (
                        <div>
                            {((payments?.next !== null) || (payments?.previous !== null)) && (
                                <Pagination
                                    next={payments?.next}
                                    previous={payments?.previous}
                                    changePageParam={changePageParam}
                                    isAdmin={true}
                                />
                            )}
                        </div>
                    )}
                </div>
                {payments && (
                    <span>
                        {payments?.count < 1 && (
                            <EmptyList item={"Payments List"}/>
                        )}
                    </span>
                )}
            </div>
        </div>
    )
}

export default OrdersTable
import React, { useEffect } from 'react'
import EmptyList from '../feedback/EmptyList'
import BlogItem from './BlogItem';

function BlogList(props) {
    const blogs = props.blogs
    useEffect(() => {}, [blogs])
    return (
        <div>
            {blogs && (
                <div>
                    <div className='grid grid-cols-1 gap-2 md:gap-3'>
                        {blogs?.results?.map((post, idx) => (
                            <BlogItem
                                key={idx}
                                post={post}
                            />
                        ))}
                    </div>
                    {blogs.count < 1 && (
                        <EmptyList item={"Blog Post"}/>
                    )}
                </div>
            )}
        </div>
    )
}

export default BlogList
import React, { useContext, useEffect } from 'react'
import {MdPointOfSale} from 'react-icons/md'
import {IoCalendarNumberSharp} from 'react-icons/io5'
import {FaMoneyBillTransfer} from 'react-icons/fa6'
import utils from '../../utils'
import CreatePaymentModeModal from './CreatePaymentModeModal'
import CreateBaseRateModal from './CreateBaseRateModal'
import SessionContext from '../../context/SessionContex'

function OrdersSummary(props) {
    const sessionCtx = useContext(SessionContext)
    const paymentsThisMonth = sessionCtx.paymentsThisMonth
    const salesThisMonth = sessionCtx.salesThisMonth
    const baseRates = props.baseRates
    const paymentModes = props.paymentModes
    const payments = props.payments

    useEffect(() => {}, [baseRates])
    useEffect(() => {}, [paymentModes])
    useEffect(() => {}, [payments])
    useEffect(() => {}, [paymentsThisMonth])
    useEffect(() => {}, [salesThisMonth])

    function updatePaymentModes(mode){
        props.setPaymentModes([mode, ...paymentModes])
    }
    function updateBaseRate(rate){
        props.setBaseRates([rate, ...baseRates])
    }
    return (
        <div className='mb-4'>
            <h2 className="text-1xl mb-2">Orders Summary</h2>
            <div className="flex flex-col md:flex-row gap-2 mb-3">
                <div className="stats rounded-md bg-primary text-primary-content">
                    <div className="stat">
                        <div className="stat-title text-black">Payment Modes</div>
                        <div className="stat-value">{utils.toCommas(paymentModes.length)}</div>
                        <div className="stat-actions">
                            <CreatePaymentModeModal
                                updatePaymentModes={updatePaymentModes}
                            />
                        </div>
                    </div>
                </div>
                <div className="stats rounded-md bg-primary text-primary-content">
                    <div className="stat">
                        <div className="stat-title text-black">Base Rates</div>
                        <div className="stat-value">{utils.toCommas(baseRates?.length)}</div>
                        <div className="stat-actions">
                            <CreateBaseRateModal
                                updateBaseRate={updateBaseRate}
                                paymentModes={paymentModes}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col md:flex-row mt-2 gap-2 mb-3">
                <div className="stats rounded-md bg-secondary text-primary-content">
                    <div className="stat">
                        <div className="stat-title text-black">Total Payments</div>
                        <div className="stat-value">
                            {payments && (
                                <span>
                                    {utils.toCommas(payments?.count)}
                                </span>
                            )}
                        </div>
                        <div className="stat-actions">
                            <MdPointOfSale className='h-9 w-9'/>
                        </div>
                    </div>
                </div>
                <div className="stats rounded-md bg-secondary text-primary-content">
                    <div className="stat">
                        <div className="stat-title text-black">Payments This Month</div>
                        <div className="stat-value">{utils.toCommas(paymentsThisMonth)}</div>
                        <div className="stat-actions">
                            <IoCalendarNumberSharp className='h-9 w-9'/>
                        </div>
                    </div>
                </div>
                <div className="stats rounded-md bg-secondary text-primary-content">
                    <div className="stat">
                        <div className="stat-title text-black">Sales This Month (KSHS)</div>
                        <div className="stat-value">{utils.toCommas(salesThisMonth)}</div>
                        <div className="stat-actions">
                            <FaMoneyBillTransfer className='h-9 w-9'/>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    )
}

export default OrdersSummary
import React, { useEffect } from 'react'
import {BsFiletypeDoc} from 'react-icons/bs'
import utils from '../../utils'

function HouseMediaItem(props) {
    const media = props.media

    useEffect(() => {}, [media])

    function openPdf(media){
        window.open(media)
    }
    return (
        <div>
            {utils.isImage(media?.file) && (
                <img 
                    onClick={() => props.setActiveImage(utils.baseUrl + media?.file)}
                    className="h-auto max-w-full rounded-md hover:cursor-pointer" 
                    src={utils.baseUrl + media?.file} alt={media?.house?.name}/>
            )}
            {!utils.isImage(media?.file) && (
                <div className="h-auto max-w-full rounded-md hover:cursor-pointer">
                    <div 
                        onClick={() => openPdf(utils.baseUrl + media?.file)}
                        className="avatar placeholder">
                            <div className="h-14 bg-neutral-focus text-neutral-content rounded-md">
                                <span className="text-3xl">
                                    <BsFiletypeDoc/>
                                </span>
                            </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default HouseMediaItem
import React from 'react'
import {MdSettingsSuggest} from 'react-icons/md'
import {BsBuildingFillGear} from 'react-icons/bs'
import {GiReceiveMoney} from 'react-icons/gi'
import {FaCartShopping} from 'react-icons/fa6'
import { Link } from 'react-router-dom'

function About() {
    return (
        <div className='mx-4 md:mx-5 lg:mx-7'>
            <h2 className="text-center font-bold text-2xl mb-4">About Us</h2>
            <p className="text-center">We provide our customers with top-notch services for your construction needs such as:</p>
            <div className="mt-9 grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="card rounded-md border bg-secondary-focus p-3 hover:shadow-xl ">
                    <div className="flex justify-center">
                        <MdSettingsSuggest className='text-white h-9 w-9'/>
                    </div>
                    <div className="card-body">
                        <p className="text-center font-bold text-white">Construction Project Management</p>
                        <p className='text-center text-white'>We will manage your construction project(s), from start to finish ensuring top-quality results at the best cost possible.</p>
                    </div>
                </div>
                <div className="card rounded-md border bg-secondary-focus p-3 hover:shadow-xl ">
                    <div className="flex justify-center">
                        <BsBuildingFillGear className='text-white h-9 w-9'/>
                    </div>
                    <div className="card-body">
                        <p className="text-center font-bold text-white">Architectural Plans & Designs</p>
                        <p className='text-center text-white'>Choose from our wide variety of building plans or have us design a custom one for you!</p>
                    </div>
                </div>
                <div className="card rounded-md border bg-secondary-focus p-3 hover:shadow-xl ">
                    <div className="flex justify-center">
                        <GiReceiveMoney className='text-white h-9 w-9'/>
                    </div>
                    <div className="card-body">
                        <p className="text-center font-bold text-white">Building Costs Estimation</p>
                        <p className='text-center text-white'>Let us come up with an accurate Bill of Quantities for your project(s) to help manage your costs.</p>
                    </div>
                </div>
            </div>
            <div className="md:flex md:justify-center mt-4">
                <div className="p-3 card rounded-md border bg-secondary md:w-2/5 hover:shadow-xl">
                    <div className="flex justify-center">
                        <FaCartShopping className='text-white h-9 w-9'/>
                    </div>
                    <div className="card-body">
                        <p className="text-center font-bold text-white">Buy Ready Building Plans</p>
                        <p className='text-center text-white'>
                            Search, choose and compare from our wide variety of cost effective building designs. 
                            Once you purchase a plan from us, you'll have full access to the building plan media files.
                        </p>
                        <div className="card-actions justify-center mt-3">
                            <Link to="/houses" className='btn btn-primary'>See Available Plans</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
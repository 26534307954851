import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast';
import utils from '../../utils'
import FormError from '../feedback/FormError'
import Loading from '../feedback/Loading'
import houseServices from '../../services/HousesService'
import {HiTrash} from 'react-icons/hi'

function CategoryModal(props) {
    const closeBtn = document.getElementById("category-update-modal-close-btn")
    const updateCategory = props.updateCategory
    const nameRef = useRef()
    const descriptionRef = useRef()
    const imageRef = useRef()
    const [file, setFile] = useState()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [show, setShow] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [deleteError, setDeleteError] = useState()

    useEffect(() => {
        if (updateCategory) {
            setShow(false)
            nameRef.current.value = updateCategory?.name
            descriptionRef.current.value = updateCategory?.description
        }
    }, [updateCategory])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    useEffect(() => {}, [show])
    useEffect(() => {}, [deleteLoading])
    useEffect(() => {}, [deleteError])

    function imageChanged(event){
        setFile(event.target.files[0])
    }

    function submitHandler(e){
        e.preventDefault()
        const formData = new FormData()
        formData.append("name", nameRef.current.value)
        formData.append("description", descriptionRef.current.value)
        if (file) {
            formData.append("image", file)
        }
        setError()
        setLoading(true)
        houseServices.updateCategory(updateCategory?.slug, formData).then(
            res => {
                setLoading(false)
                closeBtn.click()
                toast.success("Category updated successfully")
                props.clearUpdate()
            },
            err => {
                setError(err)
                console.log("shit");
                setLoading(false)
                toast.error("Something went wrong")
            }
        )
    }
    function deleteCategory(category){
        setDeleteLoading(true)
        setDeleteError()
        houseServices.deleteCategory(category?.slug).then(
            res => {
                setDeleteLoading(false)
                closeBtn.click()
                toast.success("Category deleted successfully")
                props.clearUpdate()
            },
            err => {
                setDeleteError(err)
                setDeleteLoading(false)
                toast.error("Something went wrong")
            }
        )
    }
    return (
        <div>
            <label htmlFor="category-update-modal" id='category-update-modal-open-btn' className="hide__element"></label>
            <input type="checkbox" id="category-update-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    <label htmlFor="category-update-modal" id='category-update-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3  mb-5'>Update / Delete : {updateCategory?.name}</h2>
                    <form onSubmit={submitHandler}>
                        <div className="mb-4 flex justify-center">
                            <div className="w-40 ">
                                <img className='rounded-md' src={utils.baseUrl + updateCategory?.image} alt={updateCategory?.name}/>
                            </div>
                        </div>
                        {error && (
                            <FormError error={error}/>
                        )}
                        <div className="form-control mb-3">
                            <label className="label">
                                <span className="label-text">Name</span>
                            </label>
                            <input required ref={nameRef} name="name" className="input input-bordered w-full input-success text-gray-600" />
                        </div>
                        <div className="form-control mb-3">
                            <label className="label">
                                <span className="label-text">Image</span>
                            </label>
                            <input type="file" ref={imageRef} id='file-input' onChange={imageChanged} className="file-input text-gray-600 file-input-bordered file-input-success w-full max-w-xs" />
                        </div>
                        <div className="form-control mb-5">
                            <label className="label">
                                <span className="label-text">Description</span>
                            </label>
                            <textarea cols={4} required ref={descriptionRef} name="name" className="input text-gray-600 input-bordered w-full h-20 input-success" />
                        </div>
                        <div className="flex justify-center mb-7">
                            {loading ? (
                                <Loading/>
                            ):(
                                <button className='btn btn-secondary' type='submit'>Submit</button>
                            )}
                        </div>
                    </form>
                    <div className="alert alert-warning rounded-md mb-5">
                        <div className="w-full">
                            <div className="flex space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                                <span>Warning: Deleting is irreversable!</span>
                            </div>
                            {show ? (
                                <div className="mt-4">
                                    {deleteLoading ? (
                                        <Loading/>
                                    ):(
                                        <div className="">
                                            {error ? (
                                                <FormError error={error}/>
                                            ):(
                                                <button 
                                                    onClick={() => deleteCategory(updateCategory)}
                                                    className='btn btn-error'><HiTrash/> Delete Parmanently</button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            ):(
                                <div className="mt-4">
                                    <button 
                                        onClick={() => setShow(!show)}
                                        className='btn btn-neutral'><HiTrash/>  Delete {updateCategory?.name}</button>
                                </div>
                            )}
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CategoryModal
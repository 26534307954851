import React, { useEffect } from 'react'
import utils from '../../utils'
import {FaXmark, FaCheck} from 'react-icons/fa6'
import { Link } from 'react-router-dom'

function BlogSammary(props) {
    const blogInfo = props.blogInfo
    useEffect(() => {}, [blogInfo])
    return (
        <div className='mb-4'>
            <h2 className="text-1xl mb-2">Blog Summary</h2>
            <div className="flex flex-col md:flex-row gap-2 mb-3">
                <div className="stats rounded-md bg-primary text-primary-content">
                    <div className="stat">
                        <div className="stat-title text-black">Total Blog Posts</div>
                        <div className="stat-value">{utils.toCommas(blogInfo?.blog_count)}</div>
                        <div className="stat-actions">
                            <Link to='/blogs/add' className='btn btn-sm'>Create New</Link>
                        </div>
                    </div>
                </div>
                <div className="stats rounded-md bg-secondary text-primary-content">
                    <div className="stat">
                        <div className="stat-title text-black">Published Posts</div>
                        <div className="stat-value">
                            {utils.toCommas(blogInfo?.published_count)}
                        </div>
                        <div className="stat-actions">
                            <FaCheck className='h-9 w-9'/>
                        </div>
                    </div>
                </div>
                <div className="stats rounded-md bg-secondary text-primary-content">
                    <div className="stat">
                        <div className="stat-title text-black">Un-Published Posts</div>
                        <div className="stat-value">
                            {utils.toCommas(blogInfo?.un_published_count)}
                        </div>
                        <div className="stat-actions">
                            <FaXmark className='h-9 w-9'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogSammary
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useContext} from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import AdminUsersTab from '../../components/admin/AdminUsersTab'
import AdminHousestab from '../../components/admin/AdminHousestab'
import AdminOrdersTab from '../../components/admin/AdminOrdersTab'
import AuthBreadCrumbs from '../../components/nav/AuthBreadCrumbs'
import { useSearchParams } from 'react-router-dom'
import SessionContext from '../../context/SessionContex'
import authServices from '../../services/UsersService'
import AdminUserQuotesTab from '../../components/admin/AdminUserQuotesTab'
import AdminBlogTab from '../../components/admin/AdminBlogTab'

function AdminPage(props) {
    const sessionCtx = useContext(SessionContext)
    const hasSetCountData = sessionCtx.hasSetCountData
    const [searchParams, setSearchParams] = useSearchParams()
    const [activeTab, setActiveTab] = useState(0)
    let tabs = [
        "Users",
        "Houses",
        "Orders",
        "Quotes",
        "Blog",
    ]
    
    useEffect(() => {}, [activeTab, searchParams])
    useEffect(() => {
        if (!hasSetCountData) {
            authServices.adminGetBaseStatistics().then(
                res => {
                    sessionCtx.setCountData(res.data)
                },
                err => {
                    console.log(err);
                }
            )
        }
    }, [hasSetCountData, sessionCtx])

    function changeActiveTab(val){
        setActiveTab(val)
        if (searchParams.toString().length) {
            for(let [key, ] of searchParams.entries()) {
                searchParams.delete(key)
            }
            setSearchParams(searchParams)
        }
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Admin - {utils.siteName}</title>
            </Helmet>
            <div className='px-1 md:px-4 lg:px-12'>
                <AuthBreadCrumbs currentPage={0}/>
                <div className="p-2">
                    <h1 className="text-center text-3xl mb-6">Administrator</h1>
                    <div className="flex justify-center mb-4">
                        <div className="tabs">
                            {tabs.map((tab, idx) => (
                                <a 
                                    key={idx}
                                    className={activeTab === idx ? 'tab tab-bordered tab-active' : 'tab tab-bordered'}
                                    onClick={() => changeActiveTab(idx)}
                                >{tab}</a>
                            ))}
                        </div>
                    </div>
                    <div className={activeTab !== 0 ? 'hide__element' : ''}>
                        <AdminUsersTab
                            activeTab={activeTab}
                        />
                    </div>
                    <div className={activeTab !== 1 ? 'hide__element' : ''}>
                        <AdminHousestab
                            activeTab={activeTab}
                        />
                    </div>
                    <div className={activeTab !== 2 ? 'hide__element' : ''}>
                        <AdminOrdersTab
                            activeTab={activeTab}
                        />
                    </div>
                    <div className={activeTab !== 3 ? 'hide__element' : ''}>
                        <AdminUserQuotesTab
                            activeTab={activeTab}
                        />
                    </div>
                    <div className={activeTab !== 4 ? 'hide__element' : ''}>
                        <AdminBlogTab
                            activeTab={activeTab}
                        />
                    </div>
                </div>
            </div>
        </HelmetProvider>
    )
}

export default AdminPage
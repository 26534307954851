import React, { useContext, useEffect, useState } from 'react'
import FilterContext from '../../context/FilterContext'
import {FaFilter} from 'react-icons/fa'
import SessionContext from '../../context/SessionContex'
import utils from '../../utils'

function MobileSideBar(props) {
    const [showFilter, setShowFilter] = useState(false)
    const [showBtn, setShowBtn] = useState(true)
    const sessionCtx = useContext(SessionContext)
    const categories = sessionCtx.categories
    const filterCtx = useContext(FilterContext)
    const houseCategory = filterCtx.houseCategory
    const houseRooms = filterCtx.houseRooms
    const storiesParam = props.storiesParam
    const bathroomsParam = props.bathroomsParam
    const roomNames = utils.roomNames
    const stories = utils.stories
    const bathrooms = utils.bathrooms

    useEffect(() => {}, [showBtn, showFilter])
    useEffect(() => {}, [categories])
    useEffect(() => {}, [houseCategory])
    useEffect(() => {}, [houseRooms])
    useEffect(() => {}, [storiesParam])
    useEffect(() => {}, [bathroomsParam])
    
    function showFilterDialogue(){
        setShowBtn(false)
        setShowFilter(true)
    }
    function hideFilterDialogue(){
        setShowFilter(false)
        setShowBtn(true)
    }
    return (
        <div>
            {showFilter && (
                <div className=''>
                    <div className='flex flex-row-reverse mr-3'>
                        <div className="w-10/12 md:w-6/12 lg:w-1/3 mobile__filter fixed">
                            <div className="bg-base-300 rounded-t-md rounded-b-md text-sm">
                                <div className="card__white py-4 px-2 ">
                                    <div className="flex justify-between mb-3">
                                        <div className='flex space-x-2'>
                                            <div className="inline-block py-2.5 px-2.5 leading-none font-bold text-white rounded-full bg-secondary text-xl">
                                                <FaFilter/>
                                            </div>
                                            <p className="xs:text-md md:text-lg font-bold mt-2">Products Filter</p>
                                        </div>
                                        <div>
                                            <button 
                                                onClick={() => hideFilterDialogue()}
                                                className="btn btn-sm btn-circle btn-neutral right"
                                            >✕</button>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="py-4 card__white">
                                        <div className="mobile__filter__box">
                                            <div className="collapse collapse-arrow">
                                                {houseCategory ? (
                                                    <input type="checkbox" defaultChecked/>
                                                ):(
                                                    <input type="checkbox" />
                                                )}
                                                <div className="collapse-title font-medium">
                                                    Building Style:
                                                </div>
                                                <div className="collapse-content">
                                                    <div className="flex flex-col space-y-2">
                                                        {categories.map((category, idx) => (
                                                            <div 
                                                                key={idx}
                                                                className="text-gray-800 hover:text-primary"
                                                            >
                                                                <span className={houseCategory === category?.slug ? 'badge badge-lg badge-neutral gap-2' : ''}>
                                                                    <span onClick={() => props.changeCategoryParam(category?.slug)} className='hover:cursor-pointer'>{category.name}</span>
                                                                    {houseCategory === category?.slug && (
                                                                        <span className='hover:cursor-pointer'>
                                                                            <svg onClick={() => props.changeCategoryParam()} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-4 h-4 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="collapse collapse-arrow">
                                                {houseRooms ? (
                                                    <input type="checkbox" defaultChecked/>
                                                ):(
                                                    <input type="checkbox" />
                                                )}
                                                <div className="collapse-title font-medium">
                                                    Rooms:
                                                </div>
                                                <div className="collapse-content">
                                                    <div className="flex flex-col space-y-2">
                                                        {roomNames.map((room, idx) => (
                                                            <div 
                                                                key={idx}
                                                                className="text-gray-800 hover:text-primary"
                                                            >
                                                                <span className={houseRooms === room?.value ? 'badge badge-lg badge-neutral gap-2' : ''}>
                                                                    <span 
                                                                        onClick={() => props.changeRoomsParam(room?.value)} 
                                                                        className='hover:cursor-pointer'>{room.name}</span>
                                                                    {houseRooms === room?.value && (
                                                                        <span className='hover:cursor-pointer'>
                                                                            <svg onClick={() => props.changeRoomsParam(0)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-4 h-4 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="collapse collapse-arrow">
                                                {storiesParam ? (
                                                    <input type="checkbox" defaultChecked/>
                                                ):(
                                                    <input type="checkbox" />
                                                )}
                                                <div className="collapse-title font-medium">
                                                    Stories:
                                                </div>
                                                <div className="collapse-content">
                                                    <div className="flex flex-col space-y-2">
                                                        {stories.map((story, idx) => (
                                                            <div 
                                                                key={idx}
                                                                className="text-gray-800 hover:text-primary"
                                                            >
                                                                <span className={parseInt(storiesParam) === story?.value ? 'badge badge-lg badge-neutral gap-2' : ''}>
                                                                    <span 
                                                                        onClick={() => props.changeStoriesParam(story?.value)} 
                                                                        className='hover:cursor-pointer'>{story.name}</span>
                                                                    {parseInt(storiesParam) === story?.value && (
                                                                        <span className='hover:cursor-pointer'>
                                                                            <svg onClick={() => props.changeStoriesParam(0)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-4 h-4 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="collapse collapse-arrow">
                                                {bathroomsParam ? (
                                                    <input type="checkbox" defaultChecked/>
                                                ):(
                                                    <input type="checkbox" />
                                                )}
                                                <div className="collapse-title font-medium">
                                                    Bathrooms:
                                                </div>
                                                <div className="collapse-content">
                                                    <div className="flex flex-col space-y-2">
                                                        {bathrooms.map((bathroom, idx) => (
                                                            <div 
                                                                key={idx}
                                                                className="text-gray-800 hover:text-primary"
                                                            >
                                                                <span className={parseInt(bathroomsParam) === bathroom?.value ? 'badge badge-lg badge-neutral gap-2' : ''}>
                                                                    <span 
                                                                        onClick={() => props.changeBathroomsParam(bathroom?.value)} 
                                                                        className='hover:cursor-pointer'>{bathroom.name}</span>
                                                                    {parseInt(bathroomsParam) === bathroom?.value && (
                                                                        <span className='hover:cursor-pointer'>
                                                                            <svg onClick={() => props.changeBathroomsParam(0)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-4 h-4 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showBtn && (
                <button
                    onClick={() => showFilterDialogue()}
                    className='btn btn-lg btn-circle btn-secondary filter__btn'
                >
                    <FaFilter className='h-6 w-6'/>
                </button>
            )}
        </div>
    )
}

export default MobileSideBar
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { UserContextProvider } from './context/UserContext';
import { SessionContextProvider } from './context/SessionContex';
import { CartContextProvider } from './context/CartContext';
import { FilterContextProvider } from './context/FilterContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserContextProvider>
      <SessionContextProvider>
        <CartContextProvider>
          <FilterContextProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </FilterContextProvider>
        </CartContextProvider>
      </SessionContextProvider>
    </UserContextProvider>
  </React.StrictMode>
);

import React, { useEffect } from 'react'
import moment from 'moment'

function PaymentModeTableRow(props) {
    const paymentMode = props.paymentMode

    useEffect(() => {}, [paymentMode])
    return (
        <tr>
            <td className='font-bold'>{paymentMode?.name}</td>
            <td>{moment(paymentMode?.last_updated_time).format("MMMM Do YYYY")}</td>
            <td className='font-bold'>{moment(paymentMode?.creation_time).format("MMMM Do YYYY")}</td>
            <td>
                <button
                    onClick={() => props.modifyPaymentMode(paymentMode)}
                    className='btn btn-xs btn-secondary'
                >Modify</button>
            </td>
        </tr>
    )
}

export default PaymentModeTableRow
import React, { useEffect } from 'react'
import {HiHomeModern} from 'react-icons/hi2'
import HouseTableRow from './HouseTableRow'
import Pagination from '../nav/Pagination'

function HousesTable(props) {
    const houses = props.houses

    useEffect(() => {}, [houses])

    function changePageParam(page){
        props.filterPage(page)
    }
    return (
        <div className='p-3 border rounded-md mt-5 bg-base-300'>
            <div className="flex space-x-3 items-center font-bold">
                <HiHomeModern className='h-5 w-5'/>
                <span>Houses Table</span>
            </div>
            <div className="mt-2">
                <div className="">
                    <table className="table table-zebra table-sm overflow-x-scroll">
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Owner</th>
                                <th>Category</th>
                                <th>Price</th>
                                <th>Created</th>
                                <th>View</th>
                            </tr>
                        </thead>
                        <tbody>
                            {houses?.results.map((house, idx) => (
                                <HouseTableRow
                                    key={idx}
                                    house={house}
                                />
                            ))}
                        </tbody>
                    </table>
                    {houses && (
                        <div>
                            {((houses?.next !== null) || (houses?.previous !== null)) && (
                                <Pagination
                                    next={houses?.next}
                                    previous={houses?.previous}
                                    changePageParam={changePageParam}
                                    isAdmin={true}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default HousesTable
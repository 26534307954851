import { createContext, useState } from "react";

const FilterContext = createContext({
    houseCategory: null,
    setHouseCategory: (data) => {},
    houseRooms: null,
    setHouseRooms: (data) => {},
    houseStories: null,
    setHouseStories: (data) => {},
    houseBathrooms: null,
    setHouseBathrooms: (data) => {},
})

export function FilterContextProvider(props){
    const [houseCategory, setHouseCategory] = useState()

    function setHouseCategoryHandler(data){
        return (
            setHouseCategory(data)
        )
    }

    const [houseRooms, setHouseRooms] = useState()

    function setHouseRoomsHandler(data){
        return (
            setHouseRooms(data)
        )
    }

    const [houseStories, setHouseStories] = useState()

    function setHouseStoriesHandler(data){
        return (
            setHouseStories(data)
        )
    }

    const [houseBathrooms, setHouseBathrooms] = useState()

    function setHouseBathroomsHandler(data){
        return (
            setHouseBathrooms(data)
        )
    }

    const context = {
        houseCategory: houseCategory,
        setHouseCategory: setHouseCategoryHandler,
        houseRooms: houseRooms,
        setHouseRooms: setHouseRoomsHandler,
        houseStories: houseStories,
        setHouseStories: setHouseStoriesHandler,
        houseBathrooms: houseBathrooms,
        setHouseBathrooms: setHouseBathroomsHandler,
    }
    return (
        <FilterContext.Provider value={context}>
            {props.children}
        </FilterContext.Provider>
    )
}

export default FilterContext
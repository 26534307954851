import React, { useEffect, useState } from 'react'
import {BsFillGrid3X3GapFill} from 'react-icons/bs'
import CategoryTableRow from './CategoryTableRow'
import CategoryModal from './CategoryModal'

function CategoriesTable(props) {
    const categories = props.categories
    const [updateCategory, setUpdateCategory] = useState()

    useEffect(() => {}, [categories])
    useEffect(() => {}, [updateCategory])

    function openModal(category){
        setUpdateCategory(category)
        const openBtn = document.getElementById("category-update-modal-open-btn")
        openBtn.click()
    }
    function clearUpdate(){
        setUpdateCategory()
        props.getCategories()
    }
    return (
        <div className='mt-5'>
            <div className="collapse collapse-arrow border rounded-md bg-base-300">
                <CategoryModal
                    updateCategory={updateCategory}
                    clearUpdate={clearUpdate}
                />
                <input type="checkbox" /> 
                <div className="flex items-center space-x-3 collapse-title text-1xl font-medium">
                    <BsFillGrid3X3GapFill className='h-5 w-5'/>
                    <span>Categories Table</span>
                </div>
                <div className="collapse-content"> 
                    <div className="mt-2">
                        <div className="overflow-x-auto">
                            <table className="table table-zebra table-sm">
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Name</th>
                                        <th>Updated</th>
                                        <th>Created</th>
                                        <th>Update</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {categories.map((category, idx) => (
                                        <CategoryTableRow
                                            key={idx}
                                            category={category}
                                            openModal={openModal}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>     
    )
}

export default CategoriesTable
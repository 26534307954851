/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react'
import {AiFillHome, AiFillDashboard} from 'react-icons/ai'
import {BiPowerOff} from 'react-icons/bi'
import {BsFillPersonFill, BsFillPersonPlusFill, BsFillInfoCircleFill} from 'react-icons/bs'
import {RiLoginBoxFill, RiMoneyDollarBoxFill} from 'react-icons/ri'
import {MdLiveHelp} from 'react-icons/md'
import {HiHomeModern} from 'react-icons/hi2'
import {FaCartShopping} from 'react-icons/fa6'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import UserContext from '../../context/UserContext'
import CartContext from '../../context/CartContext'
import { FaSquarePen } from "react-icons/fa6"

function Drawer(props) {
    const userCtx = useContext(UserContext)
    const cartCtx = useContext(CartContext)
    const numItems = cartCtx.numCartItems
    const isAuth = userCtx.isAuth
    const user = userCtx.user
    const location = useLocation()
    const navigate = useNavigate()

    function logout(){
        userCtx.logout()
        cartCtx.resetCart()
        navigate('/')
    }
    return (
        <div className="drawer-side z-50">
            <label htmlFor="nav-drawer" className="drawer-overlay"></label>
            <ul className="menu p-4 w-64 bg-base-100 h-full text-base-content">
                <li>
                    <Link
                        to='/' 
                        className={location.pathname === '/' ? 'active': ''}>
                            <AiFillHome/> Home</Link>
                </li>
                <li>
                    <Link
                        to='/about' 
                        className={location.pathname === '/about' ? 'active': ''}>
                            <BsFillInfoCircleFill/> About</Link>
                </li>
                <li>
                    <Link
                        to='/help' 
                        className={location.pathname === '/help' ? 'active': ''}>
                            <MdLiveHelp/> Help</Link>
                </li>
                <li>
                    <Link 
                        to='/cart' 
                        className={location.pathname === '/cart' ? 'active': ''}>
                            <FaCartShopping/> 
                            Cart
                        <div className='whitespace-nowrap align-baseline font-bold bg-primary text-white rounded-full px-1.5 align-center'>
                            {numItems}
                        </div>
                    </Link>
                </li>
                <li>
                    <Link
                        to='/houses' 
                        className={location.pathname === '/houses' ? 'active': ''}>
                            <HiHomeModern/> Houses</Link>
                </li>
                <li>
                    <Link
                        to='/blog' 
                        className={location.pathname === '/blog' ? 'active': ''}>
                            <FaSquarePen/> Blog</Link>
                </li>
                <li className="menu-title">
                    <span>Account</span>
                </li>
                {!isAuth ? (
                    <>
                        <li>
                            <Link
                                to='/auth/login' 
                                className={location.pathname === '/auth/login' ? 'active': ''}>
                                    <RiLoginBoxFill/> Login</Link>
                        </li>
                        <li>
                            <Link
                                to='/auth/register' 
                                className={location.pathname === '/auth/register' ? 'active': ''}>
                                    <BsFillPersonPlusFill/> Register</Link>
                        </li>
                    </>
                ):(
                    <>
                        <li>
                            <Link
                                to='/auth/profile' 
                                className={location.pathname === '/auth/profile' ? 'active': ''}>
                                    <BsFillPersonFill/> Profile</Link>
                        </li>
                        {user && (
                            <>
                                {user?.is_superuser && (
                                    <>
                                        <li>
                                            <Link
                                                to='/auth/admin' 
                                                className={location.pathname === '/auth/admin' ? 'active': ''}>
                                                    <AiFillDashboard/> Admin</Link>
                                        </li>
                                    </>
                                )}
                            </>
                        )}
                        <li>
                            <Link 
                                to='/auth/payments' 
                                className={location.pathname === '/auth/payments' ? 'active': ''}>
                                    <RiMoneyDollarBoxFill/> Payments</Link>
                        </li>
                        <li>
                            <a onClick={() => logout()}><BiPowerOff /> Logout</a>
                        </li>
                    </>
                )}
            </ul>
        </div>
    )
}

export default Drawer
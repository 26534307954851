import React, {useEffect, useRef, useState} from 'react'
import FormError from '../feedback/FormError'
import Loading from '../feedback/Loading'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'

function RegisterForm(props) {
    const [passwordType, setPasswordType] = useState("password")
    const [passMatch, setPassMatch] = useState(true)
    const emailRef = useRef()
    const pass1Ref = useRef()
    const pass2Ref = useRef()
    const error = props.error
    const loading = props.loading

    useEffect(() => {}, [passwordType])

    function handleSubmit(e){
        e.preventDefault()
        var pass1 = pass1Ref.current.value
        var pass2 = pass2Ref.current.value
        
        if(pass1 !== pass2){
            setPassMatch(false)
            return 
        } else{
            setPassMatch(true)
        }

        if (pass1.length < 8) {
            toast.error("Password length should be above 8 characters")
            return
        }

        const formData = new FormData()
        formData.append("email", emailRef.current.value)
        formData.append("password1", pass1)
        formData.append("password2", pass1)
        props.submitHandler(formData)
    }
    function togglePassword(){
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
    }
    return (
        <div className='flex justify-center mt-12'>
            <div className='w-3/4 md:w-2/4 lg:w-1/3'>
                <form onSubmit={handleSubmit} className='rounded-md border p-5 md:p-9 hover:shadow-lg bg-base-300'>
                    <div className="h1 text-center text-3xl mb-9">Register</div>
                    {error && (
                        <FormError error={error}/>
                    )}
                    <div className="form-control mb-3">
                        <label className="label">
                            <span className="label-text">Email:*</span>
                        </label>
                        <input type="email" required ref={emailRef} name="email" className="input input-bordered w-full input-success " />
                    </div>

                    <div className="form-control mb-3">
                        <label className="label">
                            <span className="label-text">Password:*</span>
                        </label>
                        <input type={passwordType} required ref={pass1Ref} name="password" className="input input-bordered w-full input-success" />
                    </div>
                    <div className="form-control mb-3">
                        <label className="label">
                            <span className="label-text">Confirm Password:*</span>
                        </label>
                        <input type={passwordType} name="password2" required ref={pass2Ref} className="input input-bordered w-full input-success" />
                        { passMatch === false && (
                            <div className="label">
                                <span className="label-text text-secondary">Passwords do not match</span>
                            </div>
                        )}
                    </div>
                    <div>
                        <label className="label">
                                <span className="label-text">Show Password</span>
                        </label>
                        <input 
                            type="checkbox" 
                            className="checkbox"
                            onClick={togglePassword} />
                    </div>
                    <div className='flex justify-center mt-5'>
                        {loading ? (
                            <Loading/>
                        ):(
                            <button type="submit" className='btn btn-secondary'>Submit</button>
                        )}
                    </div>
                    <div className="mt-7 flex justify-between">
                        <Link to="/auth/login">Login</Link>
                    </div> 
                </form>
                
            </div>
        </div>
    )
}

export default RegisterForm
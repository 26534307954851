import React, { useEffect, useState } from 'react'
import utils from '../../utils'
import Loading from '../feedback/Loading'
import paymentServices from '../../services/PaymentsService'
// var fileDownload = require('js-file-download')

function PaymentCartItem(props) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const item = props.item

    useEffect(() => {}, [item])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])

    function downloadItemMedia(){
        setLoading(true)
        setError()
        paymentServices.downloadItemMedia(item?.id).then(
            res => {
                utils.downloadStream(res.data, item?.house?.slug)
                setLoading(false)
            },
            err => {
                setError(err)
                setLoading(false)
            }
        )
    }
    return (
        <div className='grid grid-cols-3 rounded-md hover:shadow-md border gap-2 bg-base-200 mb-4 p-3'>
            <div className="flex gap-3 items-center">
                <div>
                    {props.idx + 1} .
                </div>
                <div>
                    <div className="avatar">
                        <div className="">
                            <a href={'/house/' + item?.house?.slug}>
                                <div className="avatar">
                                    <div className="mask rounded-md w-12 h-12 lg:w-14 lg:h-16">
                                        <img src={utils.baseUrl + item?.house?.image} alt={item?.house?.name}/>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="place-self-center text-gray-500 font-bold text-sm md:text-base">
                {item?.house?.name}
            </div>
            <div className="font-bold place-self-center text-sm md:text-base">
                {item?.total && (
                    <span>
                        KSHS: {utils.toCommas(item?.total)}
                    </span>
                )}
            </div>
            <div>
                {loading ? (
                    <Loading/>
                ):(
                    <div>
                        <button
                            onClick={() => downloadItemMedia()}
                            className='btn btn-secondary'>Download Media</button>
                    </div>
                )}
                
            </div>
        </div>
    )
}

export default PaymentCartItem
import React, { useEffect } from 'react'
import utils from '../../utils'
import { Link } from 'react-router-dom'

function HouseItem(props) {
    const house = props.house
    useEffect(() => {}, [house])
    return (
        <div className='card-compact rounded-md border card hover:shadow-lg bg-base-200 hover:bg-base-300'>
            <Link to={`/house/${house?.slug}`}>
                <img className='rounded-t-md' src={house?.image} alt={house?.name} />
            </Link> 
            <div className="card-body">
                <Link to={`/house/${house?.slug}`}>
                    <p className="text-secondary-focused font-bold">{house?.name}</p>
                </Link>
                <p className="text-black font-bold">KSHS {utils.toCommas(house?.price)}</p>
            </div>
        </div>
    )
}

export default HouseItem
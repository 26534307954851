import React, { useContext, useEffect, useState } from 'react'
import {FaCheck} from 'react-icons/fa6'
import UserContext from '../../context/UserContext'
import { toast } from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import SubscriptionPaymentModal from '../payments/SubscriptionPaymentModal'

function Pricing(props) {
    const origin = props.origin
    const [subscriptionType, setSubscriptionType] = useState()
    const userCtx = useContext(UserContext)
    const isAuth = userCtx.isAuth
    const navigate = useNavigate()
    const location = useLocation()
    const userSubscriptionType = userCtx.subscriptionType

    useEffect(() => {}, [subscriptionType])
    useEffect(() => {}, [userSubscriptionType])
    useEffect(() => {}, [origin])

    function makeSubscription(val){
        if (userSubscriptionType === "Active") {
            toast.success("You already have an active subscription")
            return
        }
        const closeBtn =  document.getElementById(props.closeBtn)
        if (!isAuth) {            
            toast.error("Please login first to do this.")
            closeBtn.click()
            setTimeout(() => {
                navigate('/auth/login')
            }, 900)
            return
        }
        if (location.pathname !== '/subscribe') {
            return navigate('/subscribe')
        } else{
            setSubscriptionType(val)
            const openBtn = document.getElementById(`${origin}-subscribe-payment-modal-open-btn`)
            openBtn.click()
        }
    }
    function updateUI(){
        userCtx.setSubscrptionType("Active")
        navigate('/auth/payments')
    }
    return (
        <div>
            <SubscriptionPaymentModal 
                updateUI={updateUI}
                origin={origin} 
                subscriptionType={subscriptionType}/>
            <h2 className="text-center font-bold text-2xl mb-4">Our Pricing:</h2>
            <div className="md:flex justify-center gap-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="card bg-secondary-focus mx-auto max-w-lg">
                        <div className="card-body">
                            <p className='text-center text-lg font-bold text-white mb-4'>Monthly Subscription</p>
                            <p className="text-gray-300 text-center mb-4">Best price for monthly subscription payments.</p>
                            <p className="text-center text-white text-2xl font-bold mb-4">250 KSHS</p>
                            <div className="flex gap-3 text-white items-center justify-center">
                                <FaCheck/>
                                <p>Download all house media and files.</p>
                            </div>
                            <div className="flex gap-3 text-white items-center justify-center">
                                <FaCheck/>
                                <p>Free project cost estimation.</p>
                            </div>
                            <div className="flex gap-3 text-white items-center justify-center">
                                <FaCheck/>
                                <p>Discounted overall prices.</p>
                            </div>
                            <div className="flex gap-3 text-white items-center justify-center mb-4">
                                <FaCheck/>
                                <p>Professional consultations.</p>
                            </div>
                            <div className="flex justify-center">
                                <button 
                                    onClick={() => makeSubscription(1)}
                                    className="btn btn-primary">
                                        {location.pathname === '/subscribe' ? 'Get Monthy Subscription':'Go to Subscription Page'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card bg-secondary-focus mx-auto max-w-lg">
                        <div className="card-body">
                            <p className='text-center text-lg font-bold text-white mb-4'>Annual Subscription</p>
                            <p className="text-gray-300 text-center mb-4">Best deal for annual subscription payments.</p>
                            <p className="text-center text-white text-2xl font-bold mb-4">1700 KSHS</p>
                            <div className="flex gap-3 text-white items-center justify-center">
                                <FaCheck/>
                                <p>Download all house media and files.</p>
                            </div>
                            <div className="flex gap-3 text-white items-center justify-center">
                                <FaCheck/>
                                <p>Free project cost estimation.</p>
                            </div>
                            <div className="flex gap-3 text-white items-center justify-center">
                                <FaCheck/>
                                <p>Discounted overall prices.</p>
                            </div>
                            <div className="flex gap-3 text-white items-center justify-center">
                                <FaCheck/>
                                <p>Professional consultations.</p>
                            </div>
                            <div className="flex gap-3 text-white items-center justify-center mb-4">
                                <FaCheck/>
                                <p>24/7 customer service.</p>
                            </div>
                            <div className="flex justify-center">
                                <button 
                                    onClick={() => makeSubscription(2)}
                                    className="btn btn-primary">
                                        {location.pathname === '/subscribe' ? 'Get Annual Subscription':'Go to Subscription Page'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pricing
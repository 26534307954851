import React,  { useEffect, useState } from 'react'
import {BiSolidBarChartSquare} from 'react-icons/bi'
import Loading from '../feedback/Loading'
import FetchError from '../feedback/FetchError'
import HouseCreationChart from '../charts/HouseCreationChart'
import houseServices from '../../services/HousesService'
import HouseCategoryChart from '../charts/HouseCategoryChart'

function HousesCharts(props) {
    const [chartData, setChatData] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()

    useEffect(() => {}, [chartData])
    useEffect(() => {}, [error])
    useEffect(() => {
        houseServices.adminGetHousesChartData().then(
            res => {
                setChatData(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setLoading(false)
                setError(err)
            }
        )
    }, [])
    return (
        <div>
            <div className="collapse collapse-arrow border rounded-md bg-base-200">
                <input type="checkbox" /> 
                <div className="flex items-center space-x-3 collapse-title text-1xl font-medium">
                    <BiSolidBarChartSquare className='h-5 w-5'/>
                    <span>Houses Charts</span>
                </div>
                <div className="collapse-content"> 
                    {loading ? (
                        <Loading/>
                    ):(
                        <div>
                            {error ? (
                                <FetchError error={error}/>
                            ):(
                                <div>
                                    <div className="mb-5">
                                        <HouseCreationChart
                                            chartData={chartData}
                                        />
                                    </div>
                                    <div className="mb-5 flex">
                                        <div className="md:w-1/2">
                                            <HouseCategoryChart
                                                chartData={chartData}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default HousesCharts
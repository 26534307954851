import React, { useEffect } from 'react'
import moment from 'moment'
import utils from '../../utils';
import { useNavigate } from 'react-router-dom';

function HouseTableRow(props) {
    const house = props.house
    const navigate = useNavigate()

    useEffect(() => {}, [house])

    function updateHouse(house){
        navigate(`/houses/update/${house?.slug}`)
    }
    return (
        <tr>
            <td>
                <div className="avatar placeholder">
                    <div className="h-10 mask rounded">
                        <img src={house?.image} alt={house?.name} />
                    </div>
                </div>
            </td>
            <td className='font-bold'>{house?.name}</td>
            <td>{house?.owner?.email}</td>
            <td className='font-bold'>{house?.category?.name}</td>
            <td>{utils.toCommas(house?.price)} KSHS</td>
            <td className='font-bold'>{moment(house?.creation_time).format("MMMM Do YYYY")}</td>
            <td>
                <button
                    onClick={() => updateHouse(house)}
                    className='btn btn-xs btn-secondary'
                >View</button>
            </td>
        </tr> 
    )
}

export default HouseTableRow
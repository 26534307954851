import React, { useContext, useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../../utils'
import PaymentInformation from '../../components/cart/PaymentInformation'
import PaymentComplete from '../../components/cart/PaymentComplete'
import UserContext from '../../context/UserContext'
import cartServices from '../../services/CartService'
import EmptyList from '../../components/feedback/EmptyList'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'
import paymentServices from '../../services/PaymentsService'

function CheckoutPage(props) {
    const [activeTab, setActiveTab] = useState(0)
    const [progress, setProgress] = useState(67)
    const [baseRates, setBaseRates] = useState([])
    const [cartId, setCartId] = useState()
    const userCtx = useContext(UserContext)
    const isAuth = userCtx.isAuth
    const [loading, setLoading] = useState(true)
    const [cartItems, setCartItems] = useState([])
    const [error, setError] = useState()
    const totalPrice = cartItems.reduce((a, p) => a + parseFloat(p?.total), 0)

    useEffect(() => {
        if (isAuth) {
            paymentServices.getBaseRates().then(
                res => {
                    setBaseRates(res.data)
                },
                err => {}
            )
            cartServices.getCart().then(
                res => {
                    const data = res.data
                    setCartId(data?.cart?.id)
                    setCartItems(data?.cart_items)
                    setLoading(false)
                },
                err => {
                    console.log(err);
                    setError(err)
                    setLoading(false)
                }
            )
        }
    }, [isAuth])
    useEffect(() => {}, [baseRates])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [cartItems])
    useEffect(() => {}, [cartId])
    useEffect(() => {}, [error])
    useEffect(() => {}, [activeTab])
    useEffect(() => {}, [progress])
    useEffect(() => {}, [totalPrice])

    function changeActiveTab(idx){
        setActiveTab(idx)
    }
    function changeProgress(val){
        setProgress(val)
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Checkout - {utils.siteName}</title>
            </Helmet>
            <div className='px-2 md:px-4 lg:px-12'>
                <h1 className="text-3xl text-center mb-6">Checkout</h1>
                {!isAuth ? (
                    <div className='flex space-x-2 items-center justify-center'>
                        <p className='text-lg'>Please Login to use Cart</p>
                        <a href="/auth/login" className='btn btn-secondary'>Login</a>
                    </div>
                ):(
                    <div>
                        {loading ? (
                            <Loading/>
                        ):(
                            <div>
                                {error ? (
                                    <FetchError error={error}/>
                                ):(
                                    <div>
                                        {cartItems.length ? (
                                            <div>
                                                <div className="flex justify-center mb-9">
                                                    <ul className="steps z-0">
                                                        <li className="step step-secondary">Cart</li>
                                                        <li className="step step-secondary">Make Payment</li>
                                                        <li className={progress === 100 ? "step step-secondary" : "step"}>Complete</li>
                                                    </ul>
                                                </div>
                                                <div className={activeTab !== 0 ? 'hide__element': ''}>
                                                    <PaymentInformation
                                                        changeActiveTab={changeActiveTab}
                                                        changeProgress={changeProgress}
                                                        totalPrice={totalPrice}
                                                        cartItems={cartItems}
                                                        baseRates={baseRates}
                                                        cartId={cartId}
                                                    />
                                                </div>
                                                <div className={activeTab !== 1 ? 'hide__element': ''}>
                                                    <PaymentComplete/>
                                                </div>
                                            </div>
                                        ):(
                                            <EmptyList item={"Cart Items"}/>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </HelmetProvider>
    )
}

export default CheckoutPage
import React from 'react'
import plann from '../../assets/plan1.png'
import utils from '../../utils'

function Hero(props) {
    return (
        <div className='mx-1 md:mx-4 lg:mx-7 mt-7 '>
            <div className="hero min-h-screen rounded-md" style={{ backgroundImage: `url(${plann})` }}>
                <div className=""></div>
                <div className="hero-content justify-center">
                    <div className='card rounded-md bg-base-300 p-4 md:py-7'>
                        <h1 className="text-secondary text-3xl text-center uppercase mb-4 font-bold">{utils.siteName}</h1>
                        <p className="text-center mb-5 capitalize">Construction Consultants Based in Nairobi, KENYA.</p>
                        <div className="flex justify-center">
                            <button onClick={() => props.openModal()} className='btn btn-primary btn-md'>Get Quote</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero
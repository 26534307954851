import React, { useEffect, useState } from 'react'
import {BiSolidBarChartSquare} from 'react-icons/bi'
import Loading from '../feedback/Loading'
import FetchError from '../feedback/FetchError'
import authServices from '../../services/UsersService'
import UsersJoinChart from '../charts/UsersJoinChart'

function UsersCharts(props) {
    const [chartData, setChatData] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()

    useEffect(() => {}, [chartData])
    useEffect(() => {}, [error])
    useEffect(() => {
        authServices.adminGetUsersChartData().then(
            res => {
                setChatData(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setLoading(false)
                setError(err)
            }
        )
    }, [])
    return (
        <div>
            <div className="collapse collapse-arrow rounded-md border bg-base-200">
                <input type="checkbox" /> 
                <div className="flex items-center space-x-3 collapse-title text-1xl font-medium">
                    <BiSolidBarChartSquare className='h-5 w-5'/> 
                    <span>Users Charts</span>
                </div>
                <div className="collapse-content"> 
                    {loading ? (
                        <Loading/>
                    ):(
                        <div>
                            {error ? (
                                <FetchError error={error}/>
                            ):(
                                <div>
                                    <div className="mb-5">
                                        <UsersJoinChart chartData={chartData}/>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default UsersCharts
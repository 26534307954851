import React, {useRef, useState} from 'react'
import Loading from '../feedback/Loading'
import authServices from '../../services/UsersService'
import toast from 'react-hot-toast'
import utils from '../../utils'

function ChangePasswordForm(props) {
    const [passMatch, setPassMatch] = useState(true)
    const [passwordType, setPasswordType] = useState("password")
    let oldPassRef = useRef()
    let newPassRef = useRef()
    let confirmRef = useRef()
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    
    useRef(() => {}, [error])
    useRef(() => {}, [loading])

    function submitHandler(event){
        event.preventDefault()
        const formData = new FormData()
        var pass1 = newPassRef.current.value
        var pass2 = confirmRef.current.value

        if(pass1 !== pass2){
            setPassMatch(false)
            return 
        } else{
            setPassMatch(true)
        }

        formData.append("old_password", oldPassRef.current.value)
        formData.append("new_password1", pass1)
        formData.append("new_password2", pass1)
        setLoading(true)
        setError()
        authServices.changePassword(formData).then(
            res => {
                setLoading(false)
                oldPassRef.current.value = null
                newPassRef.current.value = null
                confirmRef.current.value = null
                toast.success("Password changed successfully")
            }, 
            err => {
                console.log(err);
                setLoading(false)
                setError(err)
                toast.error(utils.toasterError)
            }
        )
    }

    function togglePassword(){
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
    }
    return (
        <div className='flex justify-center mt-8 '>
            <div className='w-full md:w-10/12 lg:w-6/12'>
                <form onSubmit={submitHandler} className='border rounded-md hover:shadow-lg p-4 md:p-7 lg:p-12 bg-base-300'>
                <p className="text-center text-2xl  mb-7">Change Password:*</p>
                    <div className="form-control mb-3">
                        <label className="label">
                            <span className="label-text">Old Password:*</span>
                        </label>
                        <input type={passwordType} required ref={oldPassRef} name="password" className="input input-bordered w-full input-success" />
                    </div>
                    <div className="form-control mb-3">
                        <label className="label">
                            <span className="label-text">New Password:*</span>
                        </label>
                        <input type={passwordType} required ref={newPassRef} name="password" className="input input-bordered w-full input-success" />
                    </div>
                    <div className="form-control mb-3">
                        <label className="label">
                            <span className="label-text">Confirm:*</span>
                        </label>
                        <input type={passwordType} required ref={confirmRef} name="password" className="input input-bordered w-full input-success" />
                        { passMatch === false && (
                            <div className="label">
                                <span className="label-text">Passwords do not match</span>
                            </div>
                        )}
                    </div>
                    <div>
                        <label className="label">
                                <span className="label-text">Show Password</span>
                        </label>
                        <input 
                            type="checkbox" 
                            className="checkbox"
                            onClick={togglePassword} />
                    </div>
                    {loading ? (
                        <div className="py-9">
                            <Loading/>
                        </div>
                    ):(
                        <div className='flex justify-center mt-5'>
                            <button type="submit" className='btn btn-secondary'>Submit</button>
                        </div>
                    )}
                </form>
            </div>
        </div>
    )
}

export default ChangePasswordForm
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Truncate from 'react-truncate-html'
import moment from 'moment'
import utils from '../../utils'

function BlogItem(props) {
    const post = props.post
    useEffect(() => {}, [post])
    return (
        <div className='flex flex-col md:flex-row p-3 lg:p-4 border rounded-md hover:shadow gap-3'>
            <div className='md:w-1/4 flex justify-center'>
                <Link to={`/blog/${post?.slug}`}>
                    <span className='avater'><img className='h-36 rounded' src={post?.image} alt={post?.name}/></span>
                </Link>
            </div>
            <div className='md:w-3/4 card justify-between'>
                <div>
                    <Link to={`/blog/${post?.slug}`}>
                        <h3 className="font-bold text-2xl text-secondary mb-3">{post?.name}</h3>
                    </Link>
                    <div>
                       <Truncate
                            className='text-grey-800 text-md'
                            lines={2}
                            debounce={36}
                            dangerouslySetInnerHTML={{
                            __html: post?.text
                            }}
                        />
                    </div>
                </div>
                <div className='flex items-center justify-between'>
                    <p className="font-bold text-gray-500">Created: {moment(post?.creation_time).fromNow()}</p>
                    <div className='flex gap-2 items-center'>
                        <p>{post?.owner?.email}</p>
                        <div className="avatar placeholder">
                            <div className="bg-neutral-focus text-neutral-content rounded-full w-7 h-7">
                                <span className='text-lg uppercase'>{utils.createProfileLetter(post?.owner)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogItem
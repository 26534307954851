import React, { useContext, useEffect, useRef, useState } from 'react'
import {FaMoneyBillAlt, FaMobileAlt} from 'react-icons/fa'
import {AiFillCreditCard} from 'react-icons/ai'
import utils from '../../utils'
import UserContext from '../../context/UserContext'
import Loading from '../feedback/Loading'
import paymentServices from '../../services/PaymentsService'
import FormError from '../feedback/FormError'
import cartServices from '../../services/CartService'
import CartContext from '../../context/CartContext'
import UnderConstruction from '../feedback/UnderConstruction'

function PaymentInformation(props) {
    const [mpesaBaseRate, setMpesaBaseRate] = useState()
    const [creditCardBaseRate, setCreditCardBaseRate] = useState()
    const [paymentMethod, setPaymentMethod] = useState()
    const [loading, setLoading] = useState(false)
    const [mpesaError, setMpesaError] = useState()
    const [creditError, setCreditError] = useState()
    const userCtx = useContext(UserContext)
    const cartCtx = useContext(CartContext)
    const paymentTarget = "Purchase"
    const cartItems = props.cartItems
    const totalPrice = props.totalPrice
    const baseRates = props.baseRates
    const cartId = props.cartId
    const user = userCtx.user  
    const numberRef = useRef()
    const cardNumberRef = useRef()
    const expiryRef = useRef()  
    const cardCodeRef = useRef()

    useEffect(() => {}, [cartItems])
    useEffect(() => {}, [mpesaError])
    useEffect(() => {}, [creditError])
    useEffect(() => {
        if (baseRates) {
            const noneFilter = baseRates.filter((rate) => rate?.subscription_type === "None")
            const mpesaFilter = noneFilter.filter((rate) => rate?.payment_mode?.name.toLowerCase() === "mpesa")
            const creditFilter = noneFilter.filter((rate) => rate?.payment_mode?.name.toLowerCase() === "credit card")
            setMpesaBaseRate(mpesaFilter)
            setCreditCardBaseRate(creditFilter)
        }
    }, [baseRates])
    useEffect(() => {}, [mpesaBaseRate])
    useEffect(() => {}, [creditCardBaseRate])
    useEffect(() => {}, [paymentMethod])
    useEffect(() => {}, [cartId])
    useEffect(() => {}, [loading])


    function updatePaymentUI(){
        props.changeActiveTab(1)
        props.changeProgress(100)
    }
    function updateCart(){
        cartServices.checkCart().then(
            res => {
                const data = res.data
                console.log(data);
                cartCtx.setCart(data?.cart)
                cartCtx.setItemSlugs(data?.slugs)
                cartCtx.setNumItems(data?.num_items)
            },
            err => {
                console.log(err);
            }
        )
    }
    function makePayment(e){

        e.preventDefault()
        const formData = new FormData()
        formData.append("owner", user?.id)
        formData.append("cart", cartId)
        formData.append("base_rate", paymentMethod === 1 ? mpesaBaseRate[0]?.id : creditCardBaseRate[0]?.id)
        formData.append("amount", totalPrice)
        formData.append("payment_target", paymentTarget)
        setLoading(true)
        setMpesaError()
        setCreditError()
        if (paymentMethod === 1) {
            const closeBtn = document.getElementById("mpesa-modal-close-btn")
            formData.append("phonenumber", numberRef.current.value)
            paymentServices.makeMpesaPayment(formData).then(
                res => {
                    updateCart()
                    setLoading(false)
                    closeBtn.click()
                    updatePaymentUI()
                },
                err => {
                    console.log(err);
                    setMpesaError(err)
                    setLoading(false)
                }
            )
        } else {
            const closeBtn = document.getElementById("card-modal-close-btn")
            formData.append("card_number", cardNumberRef.current.value)
            formData.append("expiration_date", expiryRef.current.value)
            formData.append("card_code", cardCodeRef.current.value)
            paymentServices.makeCreditCardPayment(formData).then(
                res => {
                    updateCart()
                    setLoading(false)
                    closeBtn.click()
                    updatePaymentUI()
                },
                err => {
                    console.log(err);
                    setCreditError(err)
                    setLoading(false)
                }
            )
        }
        
    }
    return (
        <div>
            <div className="mb-9 flex justify-center">
                <h2 className="text-2xl flex items-center"><FaMoneyBillAlt className='h-9 w-9 text-secondary mr-3'/> Payment Information</h2>
            </div>
            <div className="flex justify-center">
                <div className="w-11/12 md:w-8/12 lg:w-7/12">
                    <div className="hover:shadow rounded-md border p-3 md:p-5 py-5 mb-7">
                        <p className="text-1xl font-bold text-center mb-5">Purchase Summary</p>
                        <table className="table table-compact table-zebra w-full mb-4">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Product</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cartItems.map((item, idx) => (
                                    <tr
                                        key={idx}
                                    >
                                        <td>{idx + 1}</td>
                                        <td>{item?.house?.name}</td>
                                        <td>{utils.toCommas(item?.total)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className='mb-6'>
                            <p className="font-bold mt-4 text-lg">Grand total: {utils.toCommas(totalPrice)}  KSHS.</p>
                        </div>
                    </div>
                    <div className="bg-base-200 p-3 md:p-5 py-5 rounded-md border">
                        <h3 className="text-lg font-bold mb-6">Payment Method</h3>
                        <div className="flex space-x-3 mb-6">
                            <div className="">
                                <label onClick={() => setPaymentMethod(1)} htmlFor="mpesa-modal" id='mpesa-modal-open-btn' className="btn btn-primary"><FaMobileAlt/> MPESA</label>
                                <input type="checkbox" id="mpesa-modal" className="modal-toggle" />
                                <div className="modal">
                                    <div className="modal-box relative">
                                        <label htmlFor="mpesa-modal" id='mpesa-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                                        <h2 className='text-2xl text-center font-bold mt-3 mb-3'>Pay with MPESA</h2>
                                        <p className="text-lg text-center">Amount: {utils.toCommas(totalPrice)}</p>
                                        <div className="modal-action">
                                            <form className='flex w-full flex-col' onSubmit={makePayment}>
                                                {mpesaError && (
                                                    <FormError error={mpesaError}/>
                                                )}
                                                <div className="form-control mb-5">
                                                    <label className="label">
                                                        <span className="label-text">MPESA Phonenumber</span>
                                                    </label>
                                                    <input type='tel' required ref={numberRef} name="phonenumber" className="input input-bordered w-full input-success" />
                                                </div>
                                                <div className="flex justify-center">
                                                    {!loading && paymentMethod !== 2 ? (
                                                        <UnderConstruction/>
                                                    ):(
                                                        <Loading/>
                                                    )}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <label onClick={() => setPaymentMethod(2)} htmlFor="card-modal" id='card-modal-open-btn' className="btn btn-secondary"><AiFillCreditCard/> CARD</label>
                                <input type="checkbox" id="card-modal" className="modal-toggle" />
                                <div className="modal">
                                    <div className="modal-box relative">
                                        <label htmlFor="card-modal" id='card-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                                        <h2 className='text-2xl text-center font-bold mt-3 mb-3'>Pay with Dedit Card</h2>
                                        <p className="text-lg text-center">Amount: {utils.toCommas(totalPrice)}</p>
                                        <form className='flex w-full flex-col' onSubmit={makePayment}>
                                            {creditError && (
                                                <FormError error={creditError}/>
                                            )}
                                            <div className="form-control mb-3">
                                                <label className="label">
                                                    <span className="label-text">Card Number</span>
                                                </label>
                                                <input type='number' required ref={cardNumberRef} name="phonenumber" className="input input-bordered w-full input-success" />
                                            </div>
                                            <div className="flex space-x-2 mb-5">
                                                <div className="form-control mb-3">
                                                    <label className="label">
                                                        <span className="label-text">Expiration Date</span>
                                                    </label>
                                                    <input type='text' required ref={expiryRef} name="card number" className="input input-bordered w-full input-success" />
                                                </div>
                                                <div className="form-control mb-3">
                                                    <label className="label">
                                                        <span className="label-text">Card Code</span>
                                                    </label>
                                                    <input type='text' required ref={cardCodeRef} name="card code" className="input input-bordered w-full input-success" />
                                                </div>
                                            </div>
                                            <div className="flex justify-center">
                                                {!loading && paymentMethod !== 1 ? (
                                                    <UnderConstruction/>
                                                ):(
                                                    <Loading/>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentInformation
import { createContext, useState } from "react";

const SessionContext = createContext({
    categories: [],
    setCategories: (data) => {},
    hasCategories: false,
    updateHouse: null,
    setUpdateHouse: (data) => {},
    usersThisMonth: 0,
    paymentsThisMonth: 0,
    salesThisMonth: 0,
    quotesThisMonth: 0,
    setCountData: (data) => {},
    hasSetCountData: false,
    redirect: null,
    setRedirect: (data) => {},
    checkedSubscription: false,
    setCheckedSubscription: (data) => {},
})

export function SessionContextProvider(props){
    const [categories, setCategories] = useState(() => {
        const cat = sessionStorage.getItem("categories")
        if (cat !== null) {
            return JSON.parse(cat)
        } else{
            return []
        }
    })

    const [hasCategories, setHasCategories] = useState(() => {
        const cat = sessionStorage.getItem("categories")
        if (cat !== null) {
            return true
        }
        return false
    })

    function setCategoriesHandler(data){
        return (
            setCategories(data),
            setHasCategories(true),
            sessionStorage.setItem("categories", JSON.stringify(data))
        )
    }

    const [updateHouse, setUpdateHouse] = useState(() => {
        const house = sessionStorage.getItem("houseUpdate")
        if (house !== null) {
            return JSON.parse(house)
        } else{
            return null
        }
    })

    function setUpdateHouseHandler(data){
        return (
            sessionStorage.setItem("houseUpdate", JSON.stringify(data)),
            setUpdateHouse(data)
        )
    }

    const [usersThisMonth, setUsersThisMonth] = useState(0)

    const [paymentsThisMonth, setPaymentsThisMonth] = useState(0)

    const [salesThisMonth, setSalesThisMonth] = useState(0)

    const [quotesThisMonth, setQuotesThisMonth] = useState(0)

    const [hasSetCountData, setHasSetCountData] = useState(false)

    function setCountDataHandler(data){
        return (
            setUsersThisMonth(data?.users_this_month),
            setPaymentsThisMonth(data?.total_payments),
            setSalesThisMonth(data?.total_sales),
            setQuotesThisMonth(data?.quote_request),
            setHasSetCountData(true)
        )
    }

    const [checkedSubscription, setCheckedSubscription] = useState(() => {
        const hasChecked = localStorage.getItem("checkedSubscription")
        if (hasChecked !== null) {
            return hasChecked
        } else {
            return false
        }
    })

    function setCheckedSubscriptionHandler(data){
        return (
            setCheckedSubscription(data),
            localStorage.setItem("checkedSubscription", data)
        )
    }

    const context = {
        categories: categories,
        setCategories: setCategoriesHandler,
        hasCategories: hasCategories,
        updateHouse: updateHouse,
        setUpdateHouse: setUpdateHouseHandler,
        usersThisMonth: usersThisMonth,
        paymentsThisMonth: paymentsThisMonth,
        salesThisMonth: salesThisMonth,
        setCountData: setCountDataHandler,
        hasSetCountData: hasSetCountData,
        quotesThisMonth: quotesThisMonth,
        checkedSubscription: checkedSubscription,
        setCheckedSubscription: setCheckedSubscriptionHandler,
    }

    return (
        <SessionContext.Provider value={context}>
            {props.children}
        </SessionContext.Provider>
    )
}


export default SessionContext
import React, { useEffect, useState } from 'react'
import HousesSummary from './HousesSummary'
import HousesCharts from './HousesCharts'
import HousesTable from './HousesTable'
import houseServices from '../../services/HousesService'
import Loading from '../feedback/Loading'
import FetchError from '../feedback/FetchError'
import authServices from '../../services/UsersService'
import CategoriesTable from './CategoriesTable'
import { useSearchParams } from 'react-router-dom';

function AdminHousestab(props) {
    const [categories, setCategories] = useState([])
    const [houses, setHouses] = useState()
    const [loading, setLoading] = useState(false)
    const [houseError, setHouseError] = useState()
    const [statistics, setStatistics] = useState()
    const [categoryError, setCategoryError] = useState()
    const [searchParams, setSearchParams] = useSearchParams()
    const activeTab = props.activeTab

    const getCategories = () => {
        houseServices.getCategories().then(
            res => {
                setCategories(res.data)
            },
            err => {
                setCategoryError(err)
            }
        )
    }

    const getHouses = (params) => {
        setLoading(true)
        houseServices.getHouses(params ? `?${params}` : '').then(
            res => {
                setHouses(res.data)
                setLoading(false)
            },
            err => {
                setHouseError(err)
                setLoading(false)   
            }
        )
    }

    const getHouseStatistics = () => {
        authServices.adminGetHouseStatistics().then(
            res => {
                setStatistics(res.data)
            },
            err => {
            }
        )
    }
    useEffect(() => {
        if (activeTab === 1) {
            const params = searchParams.toString()
            getHouses(params)    
        }
    }, [searchParams, activeTab])
    useEffect(() => {
        getHouseStatistics()
        getCategories()
    }, [])
    useEffect(() => {}, [categories])
    useEffect(() => {}, [houses])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [houseError])
    useEffect(() => {}, [categoryError])
    useEffect(() => {}, [statistics])
    useEffect(() => {}, [activeTab])

    function filterPage(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            if (searchParams.has("page")) {
                setSearchParams(
                    searchParams.delete("page")
                )
            }
        }
    }
    return (
        <div>
            {loading ? (
                <div className="flex justify-center py-12">
                    <Loading/>
                </div>
            ):(
                <div>
                    {categoryError && (
                        <FetchError error={categoryError}/>
                    )}
                    {houseError && (
                        <FetchError error={houseError}/>
                    )}
                    <HousesSummary
                        houses={houses}
                        categories={categories}
                        getCategories={getCategories}
                        statistics={statistics}
                    />
                    <HousesCharts/>
                    <CategoriesTable
                        categories={categories}
                        getCategories={getCategories}
                    />
                    <HousesTable
                        houses={houses}
                        filterPage={filterPage}
                    />
                </div>
            )}
        </div>
    )
}

export default AdminHousestab
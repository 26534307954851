import React, { useEffect, useState } from 'react'
import houseServices from '../../services/HousesService'
import Loading from '../feedback/Loading'
import FetchError from '../feedback/FetchError'
import HouseMediaItem from './HoueMediaItem'
import EmptyList from '../feedback/EmptyList'
import HouseMediaCreateModal from './HouseMediaCreateModal'

function HouseMediaTabs(props) {
    const [houseMedia, setHouseMedia] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const action = props.action
    const updateHouse = props.updateHouse
    const house = props.house

    useEffect(() => {
        if (action === 2) {
            if (updateHouse !== undefined) {
                setLoading(true)
                houseServices.getHouseMedia(updateHouse?.slug).then(
                    res => {
                        console.log(res.data);
                        setHouseMedia(res.data)
                        setLoading(false)
                    },
                    err => {
                        console.log(err);
                        setLoading(false)
                        setError(err)
                    }
                )
            }
        }
    }, [action, updateHouse])
    useEffect(() => {}, [houseMedia])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])

    function addHouseMedia(media){
        setHouseMedia([media, ...houseMedia])
    }
    function deleteHouseMedia(media){
        delete houseMedia[media]
    }
    return (
        <div>
            <h2 className="text-2xl text-center mb-5">House Media</h2>
            <div className="mb-4">
                {loading ? (
                    <Loading/>
                ):(
                    <div>
                        {error && (
                            <FetchError error={error}/>
                        )}
                        <div className="mb-4">
                            {houseMedia.map((media, idx) => (
                                <HouseMediaItem
                                    key={idx}
                                    media={media}
                                    deleteHouseMedia={deleteHouseMedia}
                                />
                            ))}
                        </div>
                        {houseMedia.length === 0 && (
                            <EmptyList item={"House Media"}/>
                        )}
                        <hr className='mb-6'/>
                        <HouseMediaCreateModal
                            house={house}
                            action={action}
                            updateHouse={updateHouse}
                            addHouseMedia={addHouseMedia}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default HouseMediaTabs
import React, { useContext, useEffect } from 'react'
import {FaFilter} from 'react-icons/fa'
import SessionContext from '../../context/SessionContex'
import FilterContext from '../../context/FilterContext'
import utils from '../../utils'

function SideBar(props) {
    const sessionCtx = useContext(SessionContext)
    const categories = sessionCtx.categories
    const filterCtx = useContext(FilterContext)
    const houseCategory = filterCtx.houseCategory
    const houseRooms = filterCtx.houseRooms
    const storiesParam = props.storiesParam
    const bathroomsParam = props.bathroomsParam
    const roomNames = utils.roomNames
    const stories = utils.stories
    const bathrooms = utils.bathrooms
    
    useEffect(() => {}, [categories])
    useEffect(() => {}, [storiesParam])
    useEffect(() => {}, [bathroomsParam])
    useEffect(() => {}, [houseRooms])
    return (
        <div className='hidden md:flex md:w-4/12 lg:w-1/4 card'>
            <div className="bg-base-300 rounded-md p-4 hover:shadow-xl border text-sm sticky top-0">
                <div className="flex space-x-3 mb-1 font-bold items-center">
                    <div>
                        <div className="inline-block py-2 px-2 leading-none font-bold text-white rounded-full bg-secondary text-xl">
                            <FaFilter/>
                        </div>
                    </div>
                    <div>
                        Filter By:
                    </div>
                </div>
                <div className="collapse collapse-arrow">
                    {houseCategory ? (
                        <input type="checkbox" defaultChecked/>
                    ):(
                        <input type="checkbox" />
                    )}
                    <div className="collapse-title font-medium">
                        Building Style:
                    </div>
                    <div className="collapse-content">
                        <div className="flex flex-col space-y-1 ml-2">
                            {categories.map((category, idx) => (
                                <div
                                    key={idx} 
                                    className={houseCategory === category?.slug ? 'badge badge-neutral badge-lg gap-2 py-2' : ''}>
                                    <p
                                        onClick={() => props.changeCategoryParam(category?.slug)}
                                        className='hover:cursor-pointer hover:text-primary'>
                                            {category?.name}
                                    </p>
                                    {houseCategory === category?.slug && (
                                        <span className='hover:cursor-pointer'>
                                            <svg onClick={() => props.changeCategoryParam()} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-4 h-4 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                        </span>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="collapse collapse-arrow">
                    {houseRooms ? (
                        <input type="checkbox" defaultChecked/>
                    ):(
                        <input type="checkbox" />
                    )}
                    <div className="collapse-title font-medium">
                        Rooms:
                    </div>
                    <div className="collapse-content">
                        <div className="flex flex-col space-y-1 ml-2">
                            {roomNames.map((room, idx) => (
                                <div
                                    key={idx}
                                    className={houseRooms === room?.value ? 'badge badge-neutral badge-lg gap-2 py-2' : ''}>
                                    <p
                                        onClick={() => props.changeRoomsParam(room?.value)}
                                        className='hover:cursor-pointer'>{room?.name}</p>
                                    {houseRooms === room?.value && (
                                        <span className='hover:cursor-pointer'>
                                            <svg onClick={() => props.changeRoomsParam(0)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-4 h-4 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                        </span>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="collapse collapse-arrow">
                    {storiesParam ? (
                        <input type="checkbox" defaultChecked/>
                    ):(
                        <input type="checkbox" />
                    )}
                    <div className="collapse-title font-medium">
                        Stories:
                    </div>
                    <div className="collapse-content">
                        <div className="flex flex-col space-y-1 ml-2">
                            {stories.map((story, idx) => (
                                <div
                                    key={idx}
                                    className={parseInt(storiesParam) === story?.value ? 'badge badge-neutral badge-lg gap-2 py-2' : ''}>
                                    <p
                                        onClick={() => props.changeStoriesParam(story?.value)}
                                        className='hover:cursor-pointer'>{story?.name}</p>
                                    {parseInt(storiesParam) === story?.value && (
                                        <span className='hover:cursor-pointer'>
                                            <svg onClick={() => props.changeStoriesParam(0)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-4 h-4 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                        </span>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="collapse collapse-arrow">
                    {bathroomsParam ? (
                        <input type="checkbox" defaultChecked/>
                    ):(
                        <input type="checkbox" />
                    )}
                    <div className="collapse-title font-medium">
                        Bathrooms:
                    </div>
                    <div className="collapse-content">
                        <div className="flex flex-col space-y-1 ml-2">
                        {bathrooms.map((bathroom, idx) => (
                            <div
                                key={idx}
                                className={parseInt(bathroomsParam) === bathroom?.value ? 'badge badge-neutral badge-lg gap-2 py-2' : ''}>
                                <p
                                    onClick={() => props.changeBathroomsParam(bathroom?.value)}
                                    className='hover:cursor-pointer'>{bathroom?.name}</p>
                                {parseInt(bathroomsParam) === bathroom?.value && (
                                    <span className='hover:cursor-pointer'>
                                        <svg onClick={() => props.changeBathroomsParam(0)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-4 h-4 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                                    </span>
                                )}
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SideBar
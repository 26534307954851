import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import HelpPage from './pages/HelpPage';
import NavBar from './components/nav/NavBar';
import Drawer from './components/nav/Drawer';
import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import ProfilePage from './pages/auth/ProfilePage';
import HousesListPage from './pages/houses/HousesListPage';
import HouseViewPage from './pages/houses/HouseViewPage';
import HousesFormPage from './pages/houses/HousesFormPage';
import PageNotFound from './pages/PageNotFound';
import AdminPage from './pages/admin/AdminPage';
import PaymentsPage from './pages/auth/PaymentsPage';
import Footer from './components/nav/Footer';
import CartPage from './pages/cart/CartPage';
import CheckoutPage from './pages/cart/CheckoutPage';
import { useContext, useEffect, useRef } from 'react';
import UserContext from './context/UserContext';
import authServices from './services/UsersService';
import SessionContext from './context/SessionContex';
import houseServices from './services/HousesService';
import CartContext from './context/CartContext';
import cartServices from './services/CartService';
import paymentServices from './services/PaymentsService';
import utils from './utils';
import { Toaster } from 'react-hot-toast';
import SubscriptionPage from './pages/SubscriptionPage';
import SubscribeModal from './components/payments/SubscribeModal';
import ProtectedRoutes from './ProtectedRoutes';
import LoggedInReroutes from './LoggedInReroutes';
import BlogListPage from './pages/blog/BlogListPage';
import BlogViewPage from './pages/blog/BlogViewPage';
import BlogFormPage from './pages/blog/BlogFormPage';

function App(props) {
  const location = useLocation()
  const hasChecked = useRef(false)
  const userCtx = useContext(UserContext)
  const isAuth = userCtx.isAuth
  const user = userCtx.user
  const sessionCtx = useContext(SessionContext)
  const hasCategories = sessionCtx.hasCategories
  const cartCtx = useContext(CartContext)
  const cartIsSet = cartCtx.cartIsSet
  const checkedPurchases = cartCtx.checkedPurchases
  const checkedSubscription = sessionCtx.checkedSubscription
  const userChkdSubscription = userCtx.checkedSubscription

  useEffect(() => {
    if (isAuth) {
      if (user === null) {
        authServices.getUser().then(
          res => {
            userCtx.setUser(res.data)
          }, 
          err => {

          }
        )
      }
    }
  }, [isAuth, user, userCtx])
  useEffect(() => {
    if (!hasCategories) {
      houseServices.getCategories().then(
        res => {
          sessionCtx.setCategories(res.data)
        }, 
        err => {
          
        }
      )
    }
  }, [hasCategories, sessionCtx])
  useEffect(() => {
    if (!cartIsSet && isAuth) {
        cartServices.checkCart().then(
          res => {
            const data = res.data
            cartCtx.setCart(data?.cart)
            cartCtx.setItemSlugs(data?.slugs)
            cartCtx.setNumItems(data?.num_items)
          },
          err => {
            console.log(err);
          }
        ) 
    }
  }, [isAuth, cartIsSet, cartCtx])
  useEffect(() => {
    if (isAuth && !checkedPurchases) {
      paymentServices.getUserPurchasedItemSlugs().then(
        res => {
          const data = res.data
          cartCtx.setPurchasedItems(data?.purchased_item_slugs)
        },
        err => {
          console.log(err);
        }
      )
    }
  }, [isAuth, checkedPurchases, cartCtx])
  useEffect(() => {
    if (!checkedSubscription && !hasChecked.current) {
      setTimeout(() => {
        const openBtn = document.getElementById("subscribe-modal-open-btn")
        openBtn.click()
        sessionCtx.setCheckedSubscription(true)
      }, [2700])
    }
    return () => hasChecked.current = true
  }, [checkedSubscription, hasChecked, sessionCtx])
  useEffect(() => {
    if (isAuth && !userChkdSubscription) {
      authServices.getUserSubscrptionStatus().then(
        res => {
          userCtx.setSubscrptionType(res.data?.subscription_status)
        },
        err => {
          console.log(err);
        }
      )
    }
  }, [isAuth, userChkdSubscription, userCtx])
  useEffect(() => {
    const top = document.getElementById("nav-top")
    top.scrollIntoView()
  }, [location.pathname])
  return (
    <div className="App">
      <div className="drawer drawer-end">
        <input id="nav-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content">
          <div id='nav-top' className='mb-1'></div>
          <NavBar/>
          <SubscribeModal origin={"pop-up"}/>
          <div className='page'>
            <Routes>
              <Route path='/' element={<HomePage/>}/>
              <Route path='/about' element={<AboutPage/>}/>
              <Route path='/help' element={<HelpPage/>}/>

              <Route 
                path='/auth/login' 
                element={<LoggedInReroutes><LoginPage/></LoggedInReroutes>}/>
              <Route 
                path='/auth/register' 
                element={<LoggedInReroutes><RegisterPage/></LoggedInReroutes>}/>
              <Route 
                path='/auth/forgot/password' 
                element={<LoggedInReroutes><ForgotPasswordPage/></LoggedInReroutes>}/>

              <Route 
                path='/auth/admin' 
                element={<ProtectedRoutes><AdminPage/></ProtectedRoutes>}/>
              <Route 
                path='/auth/profile' 
                element={<ProtectedRoutes><ProfilePage/></ProtectedRoutes>}/>
              <Route 
                path='/auth/payments' 
                element={<ProtectedRoutes><PaymentsPage/></ProtectedRoutes>}/>

              <Route path='/cart' element={<CartPage/>}/>
              <Route 
                path='/cart/checkout' 
                element={<ProtectedRoutes><CheckoutPage/></ProtectedRoutes>}/>

              <Route path='/houses' element={<HousesListPage/>}/>
              <Route path='/house/:slug' element={<HouseViewPage/>}/>
              <Route 
                path='/houses/add' 
                element={<ProtectedRoutes><HousesFormPage action={1}/></ProtectedRoutes>}/>
              <Route 
                path='/houses/update/:slug' 
                element={<ProtectedRoutes><HousesFormPage action={2}/></ProtectedRoutes>}/>

              <Route
                path='/blog'
                element={<BlogListPage/>}/>

              <Route
                path='/blog/:slug'
                element={<BlogViewPage/>}/>

              <Route
                path='/blogs/add'
                element={<ProtectedRoutes><BlogFormPage action={1}/></ProtectedRoutes>}></Route>

              <Route
                path='/blogs/update/:slug'
                element={<ProtectedRoutes><BlogFormPage action={2}/></ProtectedRoutes>}></Route>

              <Route path='/subscribe' element={<SubscriptionPage/>} />

              <Route path='*' element={<PageNotFound/>}/>
            </Routes>
          </div>
          
          <Footer/>
          <Toaster 
            position={utils.toasterPoisition}
            toastOptions={utils.toasterOptions}
          />
        </div>
        <Drawer/>
      </div>
    </div>
  );
}

export default App;
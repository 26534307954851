import React, { useEffect, useState } from 'react'
import UsersSummary from './UsersSummary'
import UsersCharts from './UsersCharts'
import UsersTables from './UsersTables'
import authServices from '../../services/UsersService'
import Loading from '../feedback/Loading'
import FetchError from '../feedback/FetchError'
import { useSearchParams } from 'react-router-dom'

function AdminUsersTab(props) {
    const [users, setUsers] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState()
    const activeTab = props.activeTab
    const [searchParams, setSearchParams] = useSearchParams()
    
    useEffect(() => {}, [users])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])
    useEffect(() => {
        if (activeTab === 0) {
            const params = searchParams.toString()
            getUsers(params)    
        }
    }, [searchParams, activeTab])

    const getUsers = (params) => {
        setLoading(true)
        authServices.adminAllUsers(params ? `?${params}` : '').then(
            res => {
                setUsers(res.data)
                setLoading(false)
            }, 
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }

    function filterPage(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            if (searchParams.has("page")) {
                setSearchParams(
                    searchParams.delete("page")
                )
            }
        }
    }
    return (
        <div>
            {loading ? (
                <>
                    <div className="flex justify-center py-12">
                        <Loading/>
                    </div>
                </>
            ):(
                <>
                    {error ? (
                        <div>
                            <FetchError error={error}/>
                        </div>
                    ):(
                        <div>
                            <UsersSummary 
                                getUsers={getUsers}
                                users={users}/>
                            <UsersCharts users={users}/>
                            <UsersTables 
                                filterPage={filterPage}
                                users={users}
                                getUsers={getUsers}/>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default AdminUsersTab
import React from 'react'
import Pricing from '../home/Pricing'

function SubscribeModal(props) {
    return (
        <div>
            <label htmlFor="subscribe-modal" id='subscribe-modal-open-btn' className="hide__element"></label>
            <input type="checkbox" id="subscribe-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-11/12 max-w-5xl">
                    <label htmlFor="subscribe-modal" id='subscribe-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-4  mb-5'>Suscribe to our Services</h2>
                    <Pricing
                        origin={props.origin}
                        closeBtn={"subscribe-modal-close-btn"}/>
                </div>                
            </div>
        </div>
    )
}

export default SubscribeModal
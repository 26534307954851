import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Loading from '../feedback/Loading'
import {HiTrash} from 'react-icons/hi'
import authServices from '../../services/UsersService'
import { toast } from 'react-hot-toast'
import utils from '../../utils'

function QuoteViewModal(props) {
    const viewRequest = props.viewRequest
    const [show, setShow] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)

    useEffect(() => {}, [viewRequest])

    function deleteRequest(){
        const closeBtn = document.getElementById("quote-request-modal-close-btn")
        setDeleteLoading(true)
        authServices.adminDeleteQuoteRequest(viewRequest?.id).then(
            res => {
                console.log(res.data);
                setDeleteLoading(false)
                closeBtn.click()
                props.getUserQuotes('')
                toast.success("User Quote Request deleted succesfully.")
            },
            err => {
                setDeleteLoading(false)
                toast.error(utils.toasterError)
            }
        )
    }
    return (
        <div>
            <label htmlFor="quote-request-modal" id='quote-request-modal-open-btn' className="hide__element"></label>
            <input type="checkbox" id="quote-request-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    <label htmlFor="quote-request-modal" id='quote-request-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3  mb-5'>View {viewRequest?.name} Quote Request</h2>
                    <p>Name:<span className='ml-2 text-gray-600'>{viewRequest?.name}</span></p>
                    <p>Email:<span className='ml-2 text-gray-600'>{viewRequest?.email}</span></p>
                    <p>Created:<span className='ml-2 text-gray-600'>{moment(viewRequest?.creation_time).format("MMMM Do YYYY")}</span></p>
                    <p className='mb-6'>Description:<span className='ml-2 text-gray-600'>{viewRequest?.description}</span></p>
                    <div className="alert alert-warning rounded-md mb-5">
                        <div className="w-full">
                            <div className="flex space-x-2">
                                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                                <span>Warning: Deleting is irreversable!</span>
                            </div>
                            {show ? (
                                <div className="mt-4">
                                    {deleteLoading ? (
                                        <Loading/>
                                    ):(
                                        <div className="">
                                            <button 
                                                onClick={() => deleteRequest(viewRequest)}
                                                className='btn btn-error'><HiTrash/> Delete Parmanently</button>
                                        </div>
                                    )}
                                </div>
                            ):(
                                <div className="mt-4">
                                    <button 
                                        onClick={() => setShow(!show)}
                                        className='btn btn-neutral'><HiTrash/>  Delete {viewRequest?.name} Quote Rquest</button>
                                </div>
                            )}
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuoteViewModal
import React, { useEffect } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../utils'
import Pricing from '../components/home/Pricing'

function SubscriptionPage(props) {
    useEffect(() => {
        gotToTop()
    }, [])
    function gotToTop(){
        const top = document.getElementById("subscription-top")
        top.scrollIntoView()
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Subscribe - {utils.siteName}</title>
            </Helmet>
            <div id="subscription-top">
                <h1 className="text-center text-3xl mb-6">Subscribe to our Services</h1>
                <Pricing origin={"subscribe"}/>
            </div>
        </HelmetProvider>
    )
}

export default SubscriptionPage
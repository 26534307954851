import React, { useContext, useEffect, useRef, useState } from 'react'
import Loading from '../feedback/Loading'
import toast from 'react-hot-toast'
import paymentServices from '../../services/PaymentsService'
import UserContext from '../../context/UserContext'
import UnderConstruction from '../feedback/UnderConstruction'

function SubscriptionPaymentModal(props) {
    const [mpesaBaseRate, setMpesaBaseRate] = useState([])
    const [baseRates, setBaseRates] = useState([])
    const origin = props.origin
    const [valid, setValid] = useState(true)
    const [loading, setLoading] = useState(false)
    const numberRef = useRef()
    const [amount, setAmount] = useState(0)
    const subscriptionType = props.subscriptionType
    const userCtx = useContext(UserContext)
    const user = userCtx.user
    const isAuth = userCtx.isAuth
    const [activeTab, setActiveTab] = useState()

    useEffect(() => {
        if (subscriptionType) {
            getBaseRates()
        }
        setLoading(false)
    }, [subscriptionType])
    useEffect(() => {}, [amount])
    useEffect(() => {}, [activeTab])
    useEffect(() => {
        if (baseRates.length) {
            const mpesaFilter = baseRates.filter((rate) => rate?.payment_mode?.name.toLowerCase() === "mpesa")
            setMpesaBaseRate(mpesaFilter)
        }
    }, [baseRates])

    function getBaseRates(){
        paymentServices.getBaseRates().then(
            res => {
                setBaseRates(res.data)
            }, err => {console.log(err);}
        )
    }
    function onCloseClick(){
        setActiveTab()
        setAmount(0)
    }
    function submitHandler(e){
        e.preventDefault()
        setLoading(true)
        setValid(true)
        if (!isAuth) {
            toast.error("Please login first")
        }
        const phoneRe = /^(\+){0,1}(254){0,1}(70|71|72|79)(\d{7})|(254){0,3}(740|741|742|743|745|746|748|757|758|759|768|769|110|111)(\d{6})$/;
        const re = new RegExp(phoneRe)
        const phonenumber = 254 + numberRef.current.value
        const isValid = re.test(phonenumber)
        if (!isValid) {
            setValid(false)
            toast.error("Invalid Safaricom Subscriber number.")
            setLoading(false)
            return
        }
        const closeBtn = document.getElementById(`${origin}-subscribe-payment-modal-close-btn`)
        let subscriptionTypeID = (subscriptionType === 1) ?
                mpesaBaseRate.filter(
                    (rate) => {
                        return rate?.subscription_type === "Basic"
                    }
                )
                :
                mpesaBaseRate.filter(
                    (rate) => {
                        return rate?.subscription_type === "Premium"
                    }
                )
        if (!subscriptionTypeID.length) {
            toast.error("Subscription Type not yet Allowed.")
        }
        const paymentToast = toast.loading("Making Payment")
        const formData = new FormData()
        formData.append("phonenumber", phonenumber)
        formData.append("owner", parseInt(user?.id))
        formData.append("base_rate", subscriptionTypeID[0]?.id)
        formData.append("amount", amount)
        formData.append("payment_target", "Subscription")
        paymentServices.makeMpesaSubscriptionPayment(formData).then(
            res => {
                console.log(res.data);
                setLoading(false)
                toast.success("Payment successfull", {
                    "id": paymentToast
                })
                closeBtn.click()
                props.updateUI()
            }, err => {
                console.log(err);
                setLoading(false)
                toast.error("Something went wrong", {
                    "id": paymentToast
                })
            }
        )        
    }
    return (
        <div>
            <label htmlFor={`${origin}-subscribe-payment-modal`} id={`${origin}-subscribe-payment-modal-open-btn`} className="hide__element">Subscription Payment</label>
            <input type="checkbox" id={`${origin}-subscribe-payment-modal`} className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative">
                    <label 
                        onClick={() => onCloseClick()}
                        htmlFor={`${origin}-subscribe-payment-modal`} 
                        id={`${origin}-subscribe-payment-modal-close-btn`} 
                        className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                    <h2 className='text-2xl text-center mt-3 text-gray-700  mb-5'>Subscription Type: {subscriptionType === 1 ? 'Monthly':'Annual'}</h2>
                    {!activeTab  && (
                        <div>
                            <p className="text-lg text-center mb-3">Choose Payment Method</p>
                            <div className="flex gap-3 mb-5">
                                {/* <button
                                    onClick={() => {setActiveTab(1)}} 
                                    className="btn btn-success">Mpesa</button>
                                <button
                                    onClick={() => {setActiveTab(2)}} 
                                    className="btn btn-neutral">PayPal</button> */}
                                <UnderConstruction/>
                            </div>
                        </div>
                    )}
                    {activeTab && (
                        <p className="text-center mb-3">Amount: {amount} KSHS.</p>
                    )} 
                    {activeTab === 1 && (
                        <form className='flex w-full flex-col' onSubmit={submitHandler}>
                            <div className="form-control mb-5">
                                <label className="label">
                                    <span className="label-text">MPESA Phonenumber:</span>
                                </label>
                                <label className="input-group">
                                    <span>+254</span>
                                    <input 
                                        placeholder='(7/1)123456789'
                                        type='tel' 
                                        required 
                                        ref={numberRef} 
                                        name="phonenumber" 
                                        className="input input-bordered w-full input-success" />
                                </label>
                                {!valid && (
                                    <label className='label'>
                                        <span className='label-text text-error'>Invalid phonenumber, please enter 7******** or 1********</span>
                                    </label>
                                )}
                            </div>
                            <div className="flex justify-center">
                                {loading ? (<Loading/>):(
                                    <button type='submit' className="btn-success btn">Checkout</button>
                                )}                                
                            </div>
                        </form>
                    )}                   
                    
                </div>                
            </div>
        </div>
    )
}

export default SubscriptionPaymentModal
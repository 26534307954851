import React, { useEffect } from 'react'
import { FaSquarePen } from "react-icons/fa6"
import EmptyList from '../feedback/EmptyList'
import {FaXmark, FaCheck} from 'react-icons/fa6'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import Pagination from '../nav/Pagination'

function BlogTable(props) {
    const blogs = props.blogs
    const navigate = useNavigate()

    useEffect(() => {}, [blogs])
    return (
        <div className='p-3 bg-base-300 border rounded-md'>
            <div className="flex space-x-3 items-center font-bold">
                <FaSquarePen className='h-5 w-5'/>
                <span>Blog Posts Table</span>
            </div>
            <div className="mt-2">
                <div className="overflow-x-auto">
                    <table className="table table-zebra table-sm">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Published</th>
                                <th>Views</th>
                                <th>Created</th>
                                <th>View</th>
                            </tr>
                        </thead>
                        <tbody>
                            {blogs?.results?.map((post, idx) => (
                                <tr key={idx}>
                                    <td className='font-bold'>{post?.name}</td>
                                    <td>
                                        {post?.is_published ? (<FaCheck/>):(<FaXmark/>)}
                                    </td>
                                    <td className='font-bold'>
                                        {post?.views}
                                    </td>
                                    <td>
                                        {moment(post?.creation_time).format("MMMM Do YYYY")}
                                    </td>
                                    <td>
                                        <button
                                            onClick={() => navigate(`/blogs/update/${post?.slug}`)}
                                            className='btn btn-secondary btn-xs'
                                        >Modify</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {blogs && (
                    <div>
                        {((blogs?.next !== null) || (blogs?.previous !== null)) && (
                            <Pagination
                                next={blogs?.next}
                                previous={blogs?.previous}
                                changePageParam={props.filterPage}
                                isAdmin={true}
                            />
                        )}
                    </div>
                )}
                {blogs?.count < 1 && (
                    <EmptyList item={"Blog Post"}/>
                )}
            </div>
        </div>
    )
}

export default BlogTable
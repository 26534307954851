import React, { useEffect, useState } from 'react'
import OrdersSummary from './OrdersSummary'
import OrdersCharts from './OrdersCharts'
import OrdersTable from './OrdersTable'
import Loading from '../feedback/Loading'
import paymentServices from '../../services/PaymentsService'
import PaymentModeTable from './PaymentModeTable'
import BaseRatesTable from './BaseRatesTable'
import { useSearchParams } from 'react-router-dom'

function AdminOrdersTab(props) {
    const [loading, setLoading] = useState(true)
    const [baseRates, setBaseRates] = useState([])
    const [paymentModes, setPaymentModes] = useState([])
    const [payments, setPayments] = useState()
    const [error, setError] = useState()
    const [searchParams, setSearchParams] = useSearchParams()
    const activeTab = props.activeTab

    const fetchBaseRates = () => {
        paymentServices.getBaseRates().then(
            res => {
                setBaseRates(res.data)
            },
            err => {console.log(err);}
        )
    }

    const fetchPaymentModes = () => {
        paymentServices.getPaymentModes().then(
            res => {
                setPaymentModes(res.data)
            },
            err => {console.log(err);}
        )
    }

    const fetchPayments = (params) => {
        setLoading(true)
        paymentServices.adminGetPayments(params ? `?${params}` : '').then(
            res => {
                setPayments(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setLoading(false)
                setError(err)
            }
        )
    }
        
    useEffect(() => {
        setLoading(true)
        fetchBaseRates()
        fetchPaymentModes()
    }, [])
    useEffect(() => {
        if (activeTab === 2) {
            const params = searchParams.toString()
            fetchPayments(params)   
        }
    }, [searchParams, activeTab])
    useEffect(() => {}, [baseRates])
    useEffect(() => {}, [paymentModes])
    useEffect(() => {}, [payments])
    useEffect(() => {}, [error])

    function resetOrders(){
        setLoading(true)
        fetchBaseRates()
        fetchPaymentModes()
        fetchPayments()
    }
    function filterPage(page){
        if (page) {
            setSearchParams((params) => {
                params.set("page", page)
                return params
            })
        } else{
            if (searchParams.has("page")) {
                setSearchParams(
                    searchParams.delete("page")
                )
            }
        }
    }
    return (
        <div>
            {loading ? (
                <Loading/>
            ):(
                <div className="">
                    <OrdersSummary
                        baseRates={baseRates}
                        paymentModes={paymentModes}
                        payments={payments}
                        setBaseRates={setBaseRates}
                        setPaymentModes={setPaymentModes}
                    />
                    <OrdersCharts/>
                    <PaymentModeTable
                        paymentModes={paymentModes}
                        resetOrders={resetOrders}
                    />
                    <BaseRatesTable
                        baseRates={baseRates}
                        resetOrders={resetOrders}
                    />
                    <OrdersTable
                        payments={payments}
                        filterPage={filterPage}
                    />
                </div>
            )}
        </div>
    )
}

export default AdminOrdersTab
import React, { useContext, useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import toast from 'react-hot-toast';
import utils from '../../utils'
import CartItem from '../../components/cart/CartItem'
import UserContext from '../../context/UserContext'
import cartServices from '../../services/CartService'
import Loading from '../../components/feedback/Loading'
import FetchError from '../../components/feedback/FetchError'
import EmptyList from '../../components/feedback/EmptyList'
import CartContext from '../../context/CartContext'
import { Link } from 'react-router-dom';

function CartPage(props) {
    const userCtx = useContext(UserContext)
    const isAuth = userCtx.isAuth
    const [loading, setLoading] = useState(true)
    const [cartItems, setCartItems] = useState([])
    const [error, setError] = useState()
    const cartCtx = useContext(CartContext)
    const totalPrice = cartItems.reduce((a, p) => a + parseFloat(p?.total), 0);

    useEffect(() => {
        if (isAuth) {
            cartServices.getCart().then(
                res => {
                    const data = res.data
                    setCartItems(data?.cart_items)
                    setLoading(false)
                },
                err => {
                    console.log(err);
                    setError(err)
                    setLoading(false)
                }
            )
        }
    }, [isAuth])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [cartItems])
    useEffect(() => {}, [error])

    function deleteCartItem(itemId){
        const formData = {
            "id": itemId
        }
        setLoading(true)
        cartServices.deleteCartItem(formData).then(
            res => {
                setLoading(false)
                toast.success("Item deleted successfully")
                const data = res.data
                setCartItems(data?.cart_items)
                cartCtx.setItemSlugs(data?.slugs)
                cartCtx.setNumItems(data?.num_items)
            },
            err => {
                setLoading(false)
            }
        )
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Cart - {utils.siteName}</title>
            </Helmet>
            <div className='px-2 md:px-4 lg:px-12'>
                <h1 className="text-3xl text-center mb-6">Cart</h1>
                <hr className='mb-6'/>
                {!isAuth ? (
                    <div className='flex space-x-2 items-center justify-center'>
                        <p className='text-lg'>Please Login to use Cart</p>
                        <Link to="/auth/login" className='btn btn-secondary'>Login</Link>
                    </div>
                ):(
                    <div>
                        {loading ? (
                            <Loading/>
                        ):(
                            <div>
                                {error ? (
                                    <FetchError error={error}/>
                                ):(
                                    <div>
                                        {cartItems.length ? (
                                            <div>
                                                <div className="mb-12">
                                                    {cartItems.map((item, idx) => (
                                                        <CartItem 
                                                            key={idx}
                                                            idx={idx}
                                                            itemId={item?.id}
                                                            house={item?.house}
                                                            deleteCartItem={deleteCartItem}/>
                                                    ))}
                                                    <div className="mt-9">
                                                        <h3 className="text-1xl font-bold">Total: <span>{utils.toCommas(totalPrice)}  KSHS.</span></h3>
                                                    </div>
                                                </div>
                                                <div className="flex justify-end">
                                                    <Link to="/cart/checkout" className='btn btn-secondary'>Checkout</Link>
                                                </div>
                                            </div>
                                        ):(
                                            <EmptyList item={"Cart Items"}/>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </HelmetProvider>
    )
}

export default CartPage
import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast';
import Loading from '../feedback/Loading'
import FormError from '../feedback/FormError'
import paymentServices from '../../services/PaymentsService'
import utils from '../../utils';

function CreateBaseRateModal(props) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const paymentModes = props.paymentModes
    const paymentModeRef = useRef()
    const amountRef = useRef()
    const subscriptionTypeRef = useRef()
    const subscriptions = [
        {
            "name": "None"
        },
        {
            "name": "Basic",
        },
        {
            "name": "Premium",
        }
    ]

    useEffect(() => {}, [paymentModes])
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])

    function submitHandler(e){
        const closeBtn = document.getElementById("base-rate-create-modal-close-btn")
        e.preventDefault()
        setError()
        setLoading(true)
        const formData = new FormData()
        formData.append("payment_mode", paymentModeRef.current.value)
        formData.append("subscription_type", subscriptionTypeRef.current.value)
        formData.append("amount", amountRef.current.value)
        paymentServices.adminCreateBaseRate(formData).then(
            res => {
                props.updateBaseRate(res.data)
                setLoading(false)
                closeBtn.click()
                toast.success("Base Rate created successfully")
            },
            err => {
                setError(err)
                setLoading(false)
                toast.error(utils.toasterError)
            }
        )
    }
    return (
        <div>
            <div>
                <label htmlFor="base-rate-create-modal" id='base-rate-create-open-btn' className="btn btn-sm">Add Base Rate</label>
                <input type="checkbox" id="base-rate-create-modal" className="modal-toggle" />
                <div className="modal">
                    <div className="modal-box relative">
                        <label htmlFor="base-rate-create-modal" id='base-rate-create-modal-close-btn' className="btn btn-sm btn-circle absolute btn-neutral right-2 top-2">✕</label>
                        <h2 className='text-2xl text-center mt-3 text-gray-700  mb-5'>Add Base Rate</h2>
                        <form onSubmit={submitHandler}>
                            {error && (
                                <FormError error={error}/>
                            )}
                            <div className="form-control mb-3 text-gray-700">
                                <label className="label">
                                    <span className="label-text">Price</span>
                                </label>
                                <input type="number" defaultValue={0} ref={amountRef} name="amount" className="input input-bordered w-full input-success" />
                            </div>
                            <div className="form-control mb-3 text-gray-700">
                                <label className="label">
                                    <span className="label-text">Payment Mode</span>
                                </label>
                                <select ref={paymentModeRef} className="input input-bordered w-full max-w-xs input-success">
                                    {paymentModes && paymentModes?.map && (
                                        <>
                                            {paymentModes?.map((mode, idx) => (
                                                <option key={idx} value={mode?.id}>{mode?.name}</option>
                                            ))}
                                        </>
                                    )}                                    
                                </select>
                            </div>
                            <div className="form-control mb-5 text-gray-700">
                                <label className="label">
                                    <span className="label-text">Subscription Type</span>
                                </label>
                                <select ref={subscriptionTypeRef} className="input input-bordered w-full max-w-xs input-success">
                                    {subscriptions.map((mode, idx) => (
                                        <option key={idx} value={mode?.name}>{mode?.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-5 flex justify-center">
                                {loading ? (
                                    <Loading/>
                                ):(
                                    <button type="submit" className='btn btn-secondary'>Submit</button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateBaseRateModal
import React, { useContext, useEffect, useState } from 'react'
import UpdateProfileForm from './UpdateProfileForm'
import authServices from '../../services/UsersService'
import UserContext from '../../context/UserContext'

function UpdateProfileTab(props) {
    const userCtx = useContext(UserContext)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    
    useEffect(() => {}, [loading])
    useEffect(() => {}, [error])

    function submitHandler(details){
        setError()
        setLoading()
        authServices.updateUser(details).then(
            res => {
                userCtx.setUser(res.data)
                setLoading(false)
            },
            err => {
                console.log(err);
                setError(err)
                setLoading(false)
            }
        )
    }
    return (
        <div>
            <UpdateProfileForm
                error={error}
                loading={loading}
                submitHandler={submitHandler}
            />
        </div>
    )
}

export default UpdateProfileTab
import React, { useContext, useEffect } from 'react'
import SessionContext from '../../context/SessionContex'
import CategoryItem from '../houses/CategoryItem'

function Categories() {
    const sessionCtx = useContext(SessionContext)
    const categories = sessionCtx.categories

    useEffect(() => {}, [categories])
    return (
        <div className='mx-4 md:mx-5 lg:mx-7'>
            <h2 className="text-center font-bold text-2xl mb-4">Available Categories</h2>
            <div className="flex justify-center">
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                    {categories.map((category, idx) => (
                        <CategoryItem
                            key={idx}
                            category={category}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Categories
import React from 'react'
import { TbNetworkOff } from 'react-icons/tb'

function FetchError(props) {
    const error = props.error
    return (
        <div className='alert alert-error rounded-md mt-4 mb-6'>
            <div className="flex space-x-3 items-center">
            <div className="">
                    <TbNetworkOff className='h-6 w-6'/>
                </div>
                <div className="">
                    {error?.message && (
                        <span>{error?.message}</span>
                    )}
                </div>
            </div>
        </div>
    )
}

export default FetchError
import React, { useContext } from 'react'
import utils from '../../utils'
import UserContext from '../../context/UserContext'
import {FaCartShopping} from 'react-icons/fa6'
import { FaSquarePen } from "react-icons/fa6"
import {HiHomeModern} from 'react-icons/hi2'
import {RiLoginBoxFill} from 'react-icons/ri'
import {BsFillPersonFill, BsFillPersonPlusFill} from 'react-icons/bs'
import CartContext from '../../context/CartContext'
import { Link } from 'react-router-dom'

function NavBar(props) {
    const userCtx = useContext(UserContext)
    const isAuth = userCtx.isAuth
    const cartCtx = useContext(CartContext)
    const numItems = cartCtx.numCartItems
    return (
        <div className='navbar shadow-md mb-5 lg:px-6 sticky__nav z-30'>
            <div className="md:navbar-start space-x-2">
                <label htmlFor="nav-drawer">
                    <div className="btn btn-ghost btn-circle mt-1">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
                    </div>
                </label>
                <div className=''>
                    <Link className="btn btn-ghost upper-case text-secondary" to='/'>{utils.siteName}</Link>
                </div>
            </div>
            <div className="md:navbar-end space-x-2">
                <div className="hidden md:flex">
                    <ul className="menu menu-horizontal">
                        <li>
                            <Link to="/cart" className="flex">
                                <div className='whitespace-nowrap align-baseline font-bold bg-primary text-white rounded-full px-1.5 align-center'>
                                    {numItems}
                                </div>
                                <FaCartShopping/>
                                <span>Cart</span>
                            </Link>
                        </li>
                        <li><Link to="/houses" className=""><HiHomeModern/> Houses</Link></li>
                        <li><Link to="/blog" className=""><FaSquarePen/> Blog</Link></li>
                        {!isAuth ? 
                            (
                                <>
                                    <li><Link to="/auth/login" className=""><RiLoginBoxFill/> Login</Link></li>
                                    <li><Link to="/auth/register" className=""><BsFillPersonPlusFill/> Register</Link></li>
                                </>
                            ) 
                            : 
                            (
                                <>
                                    <li><Link to="/auth/profile" className=""><BsFillPersonFill/> Profile</Link></li>
                                </>
                            )
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default NavBar
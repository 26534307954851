import React from 'react'
import CountUp from 'react-countup';
import {BiSolidHappyAlt} from 'react-icons/bi'
import {BsBuildingFillGear} from 'react-icons/bs'
import {FaCartShopping} from 'react-icons/fa6'

function Count() {
    return (
        <div className=''>
            <div className="flex justify-center">
                <div className='stats stats-vertical md:stats-horizontal border shadow hover:shadow-xl'>
                    <div className="stat">
                        <div className="stat-figure text-accent">
                            <BiSolidHappyAlt className='h-12 w-12'/>
                        </div>
                        <div className="stat-title">Happy Customers</div>
                        <div className="stat-value text-secondary" id="happy-customers">
                            <CountUp 
                                end={45} 
                                enableScrollSpy={true}
                                useEasing={true}
                                scrollSpyDelay={900}
                            />
                        </div>
                    </div>
                    <div className="stat">
                        <div className="stat-figure text-accent">
                            <BsBuildingFillGear className='h-12 w-12'/>
                        </div>
                        <div className="stat-title">Projects Completed</div>
                        <div className="stat-value text-secondary" id="cow-slaughters">
                            <CountUp 
                                end={16} 
                                enableScrollSpy={true}
                                useEasing={true}
                                scrollSpyDelay={2700}
                            />
                        </div>
                    </div>
                    <div className="stat">
                        <div className="stat-figure text-accent">
                            <FaCartShopping className='h-12 w-12'/>
                        </div>
                        <div className="stat-title">Plans Available</div>
                        <div className="stat-value text-secondary" id="cow-slaughters">
                            <CountUp 
                                end={32} 
                                enableScrollSpy={true}
                                useEasing={true}
                                scrollSpyDelay={2700}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Count
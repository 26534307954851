import { createContext, useState } from "react";

const UserContext = createContext({
    user: null,
    isAuth: false,
    authToken: null,
    setUser: (data) => {},
    setAuthToken: (token) => {},
    logout: () => {},
    subscriptionType: null,
    setSubscrptionType: (data) => {},
    checkedSubscription: false,
})

export function UserContextProvider(props){
    const [user, setUser] = useState(() => {
        const usr = localStorage.getItem("user")
        if (usr !== null) {
            return JSON.parse(usr)
        } else{
            return null
        }
    })

    function setUserHandler(data){
        return (
            setUser(data),
            localStorage.setItem("user", JSON.stringify(data))
        )
    }

    const [isAuth, setIsAuth] = useState(() => {
        const token = localStorage.getItem("authToken")
        if (token === null) {
            return false
        }
        return true
    })

    const [authToken, setAuthToken] = useState(() => {
        const token = localStorage.getItem("authToken")
        if (token === null) {
            return null
        }
        return token
    })

    function setAuthTokenHandler(data){
        return(
            localStorage.setItem("authToken", data),
            setAuthToken(data),
            setIsAuth(true)            
        )
    }

    function logoutHandler(){
        return (
            setAuthToken(null),
            setIsAuth(false),
            setUser(null),
            setSubscrptionType("Inactive"),
            localStorage.clear(),
            sessionStorage.clear()
        )
    }

    const [checkedSubscription, setCheckedSybscription] = useState(() => {
        const subType = sessionStorage.getItem("subType")
        if (subType !== null) {
            return true
        } else{
            return false
        }
    })

    const [subscriptionType, setSubscrptionType] = useState(() => {
        const subType = sessionStorage.getItem("subType")
        if (subType !== null) {
            return subType
        } else{
            return "Inactive"
        }
    })

    function setSubscrptionTypeHandler(data){
        return (
            setSubscrptionType(data),
            sessionStorage.setItem("subType", data),
            setCheckedSybscription(true)
        )
    }

    const context = {
        user: user,
        setUser: setUserHandler,
        authToken: authToken,
        isAuth: isAuth,
        setAuthToken: setAuthTokenHandler,
        logout: logoutHandler,
        subscriptionType: subscriptionType,
        setSubscrptionType: setSubscrptionTypeHandler,
        checkedSubscription:checkedSubscription,
    }

    return (
        <UserContext.Provider value={context}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserContext
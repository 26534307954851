import apiClient from './AxiosHttp'
import utils from '../utils'

const api = '/api/v1'

const getBlogs = (params) => {
    return apiClient.get(`${api}/blogs/${params}`)
}

const createBlog = (details) => {
    return apiClient.post(
        `${api}/blogs/create/`,
        details, 
        {headers:utils.multipartHeader}
    )
}

const updateBlog = (details, slug) => {
    return apiClient.patch(
        `${api}/blogs/get/${slug}/`,
        details, 
        {headers:utils.multipartHeader}
    )
}

const deleteBlog = (slug) => {
    return apiClient.delete(`${api}/blogs/get/${slug}/`)
}

const getSpecificBlog = (slug) => {
    return apiClient.get(`${api}/blogs/get/${slug}/`)
}

const adminGetSpecifBlog = (slug) => {
    return apiClient.get(`${api}/blogs/get/${slug}/?is_admin=true`)
}

const adminGetBlogs = (params) => {
    return apiClient.get(`${api}/admin/blogs/${params}`)
}

const adminBlogStatistics = () => {
    return apiClient.get(`${api}/admin/blogs/statistics/`)
}

const blogServices = {
    getBlogs,
    createBlog,
    updateBlog,
    deleteBlog,
    getSpecificBlog,
    adminGetSpecifBlog,
    adminBlogStatistics,
    adminGetBlogs,
}

export default blogServices
import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../utils'
import {FaCat} from 'react-icons/fa'

function PageNotFound() {
    return (
        <HelmetProvider>
            <Helmet>
                <title>Page Not Found - {utils.siteName}</title>
            </Helmet>
            <div className='mx-4'>
                <div className="alert alert-error items-center rounded-md">
                    <FaCat className='h-9 w-9'/>
                    <p>The Page / Resource you are looking for could not be found.</p>
                </div>
            </div>
        </HelmetProvider>
    )
}

export default PageNotFound
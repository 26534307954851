import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import utils from '../utils'
import Hero from '../components/home/Hero'
import About from '../components/home/About'
import Count from '../components/home/Count'
import Categories from '../components/home/Categories'
import Pricing from '../components/home/Pricing'
import QuoteForm from '../components/home/QuoteForm'
import BlogListHome from '../components/home/BlogList'
import Contacts from '../components/home/Contacts'

function HomePage(props) {
    function openModal(){
        const openBtn = document.getElementById("contact-modal-open-btn")
        openBtn.click()
    }
    return (
        <HelmetProvider>
            <Helmet>
                <title>Home - {utils.siteName}</title>
            </Helmet>
            <QuoteForm/>
            <div className='mb-16'>
                <Hero openModal={openModal}/>
            </div>
            <div className="mb-16 text-sm">
                <About/>
            </div>
            <div className="mb-16 text-sm">
                <BlogListHome/>
            </div>
            <div className="mb-16">
                <Contacts/>
            </div>
            <div className="mb-16">
                <Categories/>
            </div>
            <div className="mb-16 text-sm">
                <Pricing/>
            </div>
            <div className="mb-16">
                <Count/>
            </div>
        </HelmetProvider>
    )
}

export default HomePage
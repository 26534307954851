import React, { useEffect } from 'react'
import moment from 'moment'

function BaseRatesTableRow(props) {
    const baseRate = props.baseRate

    useEffect(() => {}, [baseRate])
    return (
        <tr>
            <td className='font-bold'>{baseRate?.payment_mode?.name}</td>
            <td>{baseRate?.amount}</td>
            <td className='font-bold'>{baseRate?.subscription_type}</td>
            <td>{moment(baseRate?.last_updated_time).format("MMMM Do YYYY")}</td>
            <td className='font-bold'>{moment(baseRate?.creation_time).format("MMMM Do YYYY")}</td>
            <td>
                <button
                    onClick={() => props.modifyBaseRate(baseRate)}
                    className='btn btn-xs btn-secondary'
                >Modify</button>
            </td>
        </tr>
    )
}

export default BaseRatesTableRow
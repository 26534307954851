import React, { useRef, useState } from 'react'
import Loading from '../feedback/Loading'
import FormError from '../feedback/FormError'
import authServices from '../../services/UsersService'
import toast from 'react-hot-toast'
import utils from '../../utils'

function ResetPasswordForm() {
    const emailRef = useRef()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()

    function handleSubmit(e){
        e.preventDefault()
        setLoading(true)
        setError()
        const formData = new FormData()
        formData.append("email", emailRef.current.value)
        authServices.resetPassword(formData).then(
            res => {
                emailRef.current.value = null
                toast.success("Reset insructions sent successfully")
                setLoading(false)
            },
            err => {
                setError(err)
                setLoading(false)
                toast.error(utils.toasterError)
            }
        )
    }
    return (
        <div className='flex justify-center mt-12'>
            <div className='w-3/4 md:w-2/4 lg:w-1/3'>
                <form onSubmit={handleSubmit} className='rounded-md border p-5 md:p-9 hover:shadow-lg bg-base-300'>
                <div className="h1 text-center text-3xl mb-4">Reset Password</div>
                    <p className="text-center text-sm mb-5">Enter the email you registered with to get password reset instructions.</p>
                    {error && (
                        <FormError error={error}/>
                    )}
                    <div className="form-control mb-3">
                        <label className="label">
                            <span className="label-text">Email:*</span>
                        </label>
                        <input type="email" required ref={emailRef} name="email" className="input input-bordered w-full input-success " />
                    </div>
                    <div className='flex justify-center mt-5'>
                        {loading ? (
                            <Loading/>
                        ):(
                            <button type="submit" className='btn btn-secondary'>Submit</button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ResetPasswordForm